import { IonCol, IonGrid, IonRow } from "@ionic/react";
import SingleRecommendProduct from "../Home/SingleRecommendProduct";

function ProductList({ productData, settings, link }) {
	return (
		<div className='related-product-card px-4 pb-7'>
			<IonGrid className='bg-white rounded-[10px]'>
				<IonRow>
					{productData?.length > 0 ? (
						productData.map((el, i) => {
							return (
								<IonCol size='6' sizeSm='4' className='mb-6' key={i}>
									<SingleRecommendProduct
										id={el.id ? el.id : el._id}
										name={el.brand_name ? el.brand_name : el.title}
										supplierName={el.business_name}
										qty={el.products_count}
										img={el.images}
										tag={el.tag}
										offer={el.offer}
										offerAmount={el.offerAmount}
										nearby={el.nearby}
										oldPrice={Number(el.consumer_price)}
										newPrice={el.offer_price}
										sellPrice={el.sellPrice}
										profit={
											Number(el.consumer_price) -
											Number(el.base_price)
										}
										offerPrice={el.base_price}
										nameShow={settings.nameShow}
										supplierNameShow={settings.supplierNameShow}
										skuShow={settings.skuShow}
										priceShow={settings.priceShow}
										oldPriceShow={settings.oldPriceShow}
										distShow={settings.distShow}
										profitShow={settings.profitShow}
										newPriceShow={settings.newPriceShow}
										sellPriceShow={settings.sellPriceShow}
										defaultImage={settings.defaultImage}
										offerPriceShow={settings.showOfferPrice}
										link={link}
										type={settings.type}
										currency={el.currency}
										productType={settings.productType}
									/>
								</IonCol>
							);
						})
					) : (
						<IonCol className='mb-6'>
							<div className='text-center'>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-14 font-semibold text-black-500 mb-[35px]'>
									{settings?.emptyText}
								</p>
							</div>
						</IonCol>
					)}
				</IonRow>
			</IonGrid>
		</div>
	);
}

export default ProductList;
