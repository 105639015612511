import React from "react";

export default function Home() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<path
				d='M7.63105 17.3095V14.7539C7.63104 14.1038 8.16097 13.5756 8.81755 13.5713H11.2226C11.8823 13.5713 12.4171 14.1007 12.4171 14.7539V14.7539V17.3174C12.417 17.8693 12.8619 18.3204 13.4192 18.3333H15.0226C16.621 18.3333 17.9167 17.0505 17.9167 15.4682V15.4682V8.19816C17.9082 7.57565 17.6129 6.99108 17.115 6.61082L11.6315 2.23771C10.6708 1.47626 9.30521 1.47626 8.34456 2.23771L2.88506 6.61876C2.38526 6.99748 2.08953 7.58301 2.08337 8.20609V15.4682C2.08337 17.0505 3.3791 18.3333 4.97747 18.3333H6.58084C7.152 18.3333 7.61502 17.8749 7.61502 17.3095V17.3095'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
