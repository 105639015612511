import { IonButton, IonCol, IonInput, IonItem, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "../Common/UploadImage";
import location from "assets/images/icon/location.png";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { toast } from "react-toastify";
import { getLanguage } from "config/language";
import { BUSINESS_TYPE } from "data/businessType";
import {
  getCreateUserBusiness,
  getUserBusiness,
  setCreateUserBusiness,
} from "config/user";
import { useNavigate } from "react-router-dom";
import {
  emptyBusiness,
  setNewBusiness,
} from "redux/container/businessSettingsSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import Business from "services/Business";
import { setProductSeller } from "config/user";
import { setCreateCustomerBS } from "redux/container/userSlice";
import User from "services/User";
import { ClipLoader } from "react-spinners";

export default function CustomerForm({
  customerName,
  setCustomerName,
  businessName,
  setBusinessName,
  cameraImage,
  setCameraImage,
  city,
  setCity,
  address,
  setAddress,
  postalCode,
  setPostalCode,
  to,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let newCustomerBusiness = useSelector(
    (state) => state?.businessSettings?.newBusiness
  );
  const orderType = useSelector((state) => state.seller);
  const sellerId = orderType?.multiSupplier
    ? orderType.data.id
    : getUserBusiness().id;
  const [text, setText] = useState(newCustomerBusiness?.businessType ?? "");
  const selectedLanguage = getLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);
  let [cameraImageURL, setCameraImageURL] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [relationList, setRelationList] = useState([]);

  const getBusinessType = async function () {
    let response = await Business.getBusinessType();
    if (response.status === 200) {
      setBusinessTypes(response.results);
    }
  };

  let getRelationList = async () => {
    let response = await Business.getRelationList();
    if (response.status === 200) {
      setRelationList(response.results);
    }
  };
  useEffect(() => {
    getBusinessType();
    getRelationList();
  }, []);

  function setBusinessType(business) {
    setText(business.id);
    dispatch(setNewBusiness({ businessType: business.id }));
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "300px",
      transition: ".4s all ease",
      borderRadius: 12,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };

  useEffect(() => {
    dispatch(setNewBusiness({ businessImage: cameraImage }));
  }, [cameraImage]);

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  const imageUrlCollect = async () => {
    setImageLoader(true);
    // const signatureFile = DataURIToBlob(signatureImage);
    // const signData = new FormData();
    // signData.append("key", signatureFile, "image.jpg");
    const cameraFile = DataURIToBlob(cameraImage);
    const cameraData = new FormData();
    cameraData.append("key", cameraFile, "image.jpg");

    // let signResponse = await User.toBucketImage(signData);
    let cameraResponse = await User.toBucketImage(cameraData);
    if (cameraResponse.status === 200 || cameraResponse.status === 201) {
      setCameraImageURL(cameraResponse?.results);
      setImageLoader(false);
      createBusiness(cameraResponse?.results);
    } else {
      setImageLoader(false);
    }
  };

  const createBusiness = async (image) => {
    if (newCustomerBusiness.name) {
      if (newCustomerBusiness.businessName) {
        if (newCustomerBusiness?.coordinates?.length) {
          var query = {
            relation_data: {
              relation_type: relationList.find(
                (el) => el.relation_name === "Business Customer"
              ).id,
              initiator_business: sellerId,
            },
            business_data: {
              business_type: newCustomerBusiness.businessType,
              business_name: newCustomerBusiness.businessName,
              address_line: newCustomerBusiness.address,
              long_lat: {
                type: "Point",
                coordinates: [
                  newCustomerBusiness.coordinates[1],
                  newCustomerBusiness.coordinates[0],
                ],
              },
              country: newCustomerBusiness.country,
              phone_number:
                newCustomerBusiness.countryCode + newCustomerBusiness.phoneNo,
              customer_name: newCustomerBusiness.name,
            },
          };
          let response = await Business.createNewBusiness(query);

          if (response.success) {
            let businessQuery = {
              address_line: newCustomerBusiness.address,
              id: response.results.related_business,
              business_name: newCustomerBusiness.businessName,
              related_business: response.results.related_business,
              related_business_address:
                response.results.related_business_address,
              related_business_id: response.results.related_business_id,
            };

            dispatch(emptyBusiness());
            if (to === "customers-list") {
              setProductSeller(businessQuery);
              toast.success("New Business Created Successfully");
              navigate(`/create-order`);
              dispatch(setCreateCustomerBS(false));
            } else {
              toast.success("New Business Created Successfully");
              navigate(`/${"my-customer"}`);
              dispatch(setCreateCustomerBS(false));
            }
          }
        } else {
          onOpenModal();
        }
      } else {
        toast.error("Please Add Business Name");
      }
    } else {
      toast.error("Please Add Customer Name");
    }
  };
  return (
    <>
      <div>
        <label
          className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
        >
          {ekkLanguage?.newCustomer.customerName}
        </label>

        <input
          required
          className="custom-input"
          value={customerName}
          placeholder={ekkLanguage.newCustomer.customerNameHolder}
          onChange={(e) => {
            dispatch(setNewBusiness({ name: e.target.value }));
            setCustomerName(e.target.value);
          }}
          clearInput
        ></input>
      </div>
      <UploadImage cameraImage={cameraImage} setCameraImage={setCameraImage} />
      <div className="pt-4">
        <label
          className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
        >
          {ekkLanguage?.newCustomer.businessName}
        </label>

        <input
          className="custom-input"
          value={businessName}
          placeholder={ekkLanguage.newCustomer.businessNameHolder}
          onChange={(e) => {
            dispatch(setNewBusiness({ businessName: e.target.value }));
            setBusinessName(e.target.value);
          }}
          clearInput
        ></input>
      </div>
      <div className="pt-4">
        <label
          className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
        >
          {ekkLanguage?.newCustomer.address}
        </label>
        <div className="flex items-center justify-between">
          <input
            className="custom-input"
            value={address}
            placeholder={ekkLanguage.newCustomer.addressHolder}
            onChange={(e) => {
              dispatch(setNewBusiness({ address: e.target.value }));
              setAddress(e.target.value);
            }}
            clearInput
          ></input>
          <span
            onClick={() => navigate(`/pick-location/${to}`)}
            className="flex h-[50px] w-[61px] bg-white ml-[10px] rounded-[5px] cursor-pointer"
            style={
              newCustomerBusiness?.coordinates?.length
                ? {
                    border: "2px solid rgba(52, 168, 83, 0.33)",
                    boxShadow: "0px 5px 20px rgb(0, 0, 0,.05)",
                  }
                : {
                    border: "2px solid rgba(249,75, 65, .33)",
                    boxShadow: "0px 5px 20px rgb(0, 0, 0,.05)",
                  }
            }
          >
            <img src={location} className="m-auto" alt="location" />
          </span>
        </div>

        <div className="pt-4">
          <IonRow>
            <IonCol size="6">
              <div>
                <label
                  className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
                >
                  {ekkLanguage?.newCustomer.city}
                </label>

                <input
                  className="custom-input"
                  value={city}
                  placeholder={ekkLanguage.newCustomer.cityHolder}
                  onChange={(e) => {
                    dispatch(setNewBusiness({ city: e.target.value }));
                    setCity(e.target.value);
                  }}
                  clearInput
                ></input>
              </div>
            </IonCol>
            <IonCol size="6">
              <div>
                <label
                  className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
                >
                  {ekkLanguage?.newCustomer.postalCode}
                </label>

                <input
                  className="custom-input"
                  value={postalCode}
                  placeholder={ekkLanguage.newCustomer.postalCodeHolder}
                  onChange={(e) => {
                    dispatch(setNewBusiness({ postalCode: e.target.value }));
                    setPostalCode(e.target.value);
                  }}
                  clearInput
                ></input>
              </div>
            </IonCol>
          </IonRow>
        </div>
        <div className="pt-4">
          <label
            className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
          >
            {ekkLanguage?.newCustomer.selectBusiness}
          </label>
          <div>
            <div className="text-left pb-6">
              <div>
                <IonRow>
                  {businessTypes.length &&
                    businessTypes.map((business, index) => {
                      return (
                        <IonCol
                          className="p-1.5"
                          size="4"
                          key={index}
                          onClick={() => {
                            setBusinessType(business);
                          }}
                        >
                          <div
                            className={
                              business.id === text
                                ? "border-2 border-primary rounded-xl overflow-hidden py-3 text-center mb-4"
                                : "border border-black-200 rounded-xl overflow-hidden py-3  text-center opacity-30 mb-4"
                            }
                            style={{ minHeight: 96 }}
                          >
                            {business.image ? (
                              <img
                                className="w-12 h-12 m-auto"
                                src={business.image}
                                alt="fmcg"
                              />
                            ) : (
                              <h1 className="text-32 font-bold">
                                {business.title
                                  .split(" ")
                                  .map((el) => el[0])
                                  .join("")}
                              </h1>
                            )}

                            <p className="text-10 text-black-1000 font-semibold pt-2">
                              {business?.title}
                            </p>
                          </div>
                        </IonCol>
                      );
                    })}
                </IonRow>
              </div>
            </div>

            <div className="pt-12 text-center">
              <IonButton
                color="success"
                className="font-bold h-10"
                onClick={() => {
                  cameraImage ? imageUrlCollect() : createBusiness();
                }}
              >
                {imageLoader ? (
                  <ClipLoader
                    sizeUnit={"px"}
                    size={25}
                    color={"white"}
                    loading={true}
                  />
                ) : (
                  ekkLanguage.newCustomer.completeBtn
                )}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
      {/* Less MOQ modal*/}
      <Modal
        style={customStyles}
        closeTimeoutMS={200}
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        classNames="bottom-sheet-modal"
      >
        <div className="p-5">
          <span className="mb-2 inline-block mx-2">
            <CancelPopUp />
          </span>
          <h6 className="text-16 font-bold pb-[20px]">
            {ekkLanguage.businessLocation.locationValidation}
          </h6>

          <div className="flex justify-end">
            <button
              color="primary"
              className="font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white"
              onClick={onCloseModal}
            >
              {ekkLanguage.createOrder.okBtnLabel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
