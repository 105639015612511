import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import { getUser } from "config/user";
import React from "react";
import { Link } from "react-router-dom";
import { authOtpSend } from "helpers/helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function VerifyNumberSend() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let user = getUser();

  //this function handle token resend
  const resendTokenHandler = async (phoneNumber, country, countryCode) => {
    let getToken = authOtpSend(phoneNumber, country, countryCode);
    getToken
      .then((res) => {
        if (res.status === 200) {
          // setBlockIndex(authBox_Block.TOKEN);
          toast.success(ekkLanguage.otpsend.successLabel);
        } else {
          // setBlockIndex(authBox_Block.TOKEN);
          toast.success(ekkLanguage.otpsend.errorLabel);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="px-2 pt-24 relative change-password-screen">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="mb-10 text-center">
              <img
                src="assets/images/email.png"
                alt=""
                className="m-auto mb-5"
              />
              <h6 className="text-16 color-black-1000 font-medium">
                {ekkLanguage.otpsend.message}
              </h6>
            </div>
          </IonCol>
        </IonRow>
        <div className="submit-button-wrapper mx-3">
          <IonRow>
            <IonCol size="12">
              <Link
                to="/otp-submit"
                className="text-center"
                onClick={() =>
                  resendTokenHandler(user.phone_number, user.Country, "")
                }
              >
                <IonButton
                  expand="full"
                  className="bg-primary rounded-[7px] font-extrabold text-12 h-10"
                >
                  {ekkLanguage.otpsend.btnLabel}
                </IonButton>
              </Link>
            </IonCol>
          </IonRow>
        </div>
      </IonGrid>
    </div>
  );
}
