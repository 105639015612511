const bangla = {
	login: {
		contryLabel: "দেশ",
		contryPlaceHolder: "",
		phoneLable: "ফোন নম্বর",
		phonePlaceHolder: "মোবাইল নম্বর লিখুন",
		validationLabel: "আপনার ১১ ডিজিটের ফোন নম্বরটি লিখুন।",
		btnLabel: "পরবর্তী",
		tokenSuccessLabel: " সফলভাবে টোকেন পাঠানো হয়েছে",
		tokenSuccessFailed:
			"টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		langChange: "ভাষা পরিবর্তন করুন",
		notFound:
			"আপনার অ্যাকাউন্ট ওপেন করতে আমাদের একজন প্রতিনিধির সাথে যোগাযোগ করুন৷",
	},
	password: {
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceHolder: "পাসওয়ার্ড লিখুন",
		forgetPass: "পাসওয়ার্ড ভুলে গিয়েছেন?",
		btnLabel: "প্রবেশ করুন",
		otpSendLabel: "আপনার মোবাইলে কোড পাঠানো হয়েছে,",
		backWord: "পিছনে যান",
		passwordNotMatch: "পাসওয়ার্ড মেলেনি"
	},
	changePass: {
		passwordLabel: "নতুন পাসওয়ার্ড",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "পাসওয়ার্ড নিশ্চিত করুন",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "পাসওয়ার্ড মেলে না",
		btnLabel: "পরবর্তী",
		successLabel: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে",
		errorLabel: "আপনার পাসওয়ার্ড পরিবর্তন ব্যর্থ হয়েছে",
		lengthErrorLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
		notMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
	},
	token: {
		tokenLabel: "টোকেন",
		tokenPlaceHolder: "টোকেন লিখুন",
		tokenResend: "পুনরায় টোকেন পাঠাও",
		tokenSuccessLabel:
			" আপনার মোবাইলে কোড পাঠানো হয়েছে, পরবর্তী ধাপে যেতে এটি লিখুন",
		tokenErrorLabel: "টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		btnLabel: "পরবর্তী",
		tokenNotMatch: "মেয়াদোত্তীর্ণ টোকেন",
		tokenVarified: "আপনার টোকেন যাচাই করা হয়েছে",
		tokenNotWrite: "আপনার টোকেন লিখুন",
	},
	registration: {
		nameLabel: "পূর্ণ নাম",
		namePlaceholder: "পূূর্ণ নাম লিখুন",
		emailLabel: "ইমেইল ",
		emailPlaceholder: "ইমেইল লিখুন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		validationLabel: "ইনপুট ক্ষেত্র ৪ অক্ষরের হতে হবে",
		passNotMatch: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		btnLabel: "পরবর্তী",
		successMessage: "প্রোফাইল সফলভাবে তৈরি করা হয়েছে",
		errorMessage: "বৈধ ইনপুট চেক করুন",
		passLengthValidLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
	},
	letNav: {
		homeMenuLabel: "হোম",
		orderMenuLabel: "আমার অর্ডার",
		mySupplierMenuLabel: "আমার সরবরাহকারী",
		supplierMenuLabel: "সরবরাহকারী",
		businessProfileMenuLabel: "ব্যবসায়িক প্রোফাইল সেটিংস",
		phoneCall: "কল টু অর্ডার",
		myProducts: "আমার পণ্য",
		myCustomer: "আমার কাস্টমার",
	},
	home: {
		showMoreLabel: "আরো দেখুন",
		brandLabel: "ব্র্যান্ড",
		offerLabel: "অফার পণ্য",
		mySuppliersLabel: "আমার সরবরাহকারী",
		nearSuppliersLabel: "কাছাকাছি সরবরাহকারী",
		recommendentLabel: "শুধু আপনার জন্য",
		searchLabel: "পণ্য সার্চ করুন",
		todayPurchaseLabel: "আজকের কেনাকাটা",
		totalOrderLabel: "ধরণের পণ্য কিনেছেন",
		totalOrder: "টি অর্ডারে",
		skusLabel: "এসকেইউ",
		tillNow: "এখন পর্যন্ত",
		orderDue: "অর্ডার বাকি",
		brandProductPageTitle: "ব্র্যান্ডের সকল পণ্য",
		offerProductPageTitle: "মূল্যছাড় সকল পণ্য",
		offerProductNotFound: "দুঃখিত, আপনার জন্য কোন অফার পাওয়া যায়নি.",
		mySupplierNotFound:
			"আপনার কোনো সরবরাহকারী নেই। এখানে আপনার সরবরাহকারীদের যোগ করতে পণ্য কিনুন।",
		brandSeller: "সরবরাহকারী",
	},
	profile: {
		pageTitle: "প্রোফাইল এবং সেটিংস",
		contactMenuLable: "ব্যবহারকারীর তথ্য",
		passwordMenuLable: "পাসওয়ার্ড পরিবর্তন করুন",
		logoutMenuLable: "লগআউট",
		changeLanguageMenuLable: "ভাষা পরিবর্তন করুন",
	},
	contact: {
		pageTitle: "ব্যবহারকারীর তথ্য",
		nameLabel: "নাম",
		namePlaceholder: "আপনার নাম লিখুন",
		emailLabel: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		nidLabel: "এনআইডি",
		nidPlaceholder: "এনআইডি লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	otpsend: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "পাঠান",
		message:
			"খুদেবার্তা এর মাধ্যমে আপনার মোবাইলে প্রমাণীকরণ কোড পাঠাতে ট্যাপ করুন।",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
	},
	otpsubmit: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "জমা দিন",
		title: "যাচাইকরণ কোড",
		paragraph: "আমরা আপনার মোবাইল নম্বরে যাচাইকরণ কোড পাঠিয়েছি",
		otpLabel: "ওটিপি",
		otpPlaceholder: "আপনার ওটিপি কোড লিখুন",
		receiveYetLabel: "কোড পাননি?",
		againRequestLabel: "আবার অনুরোধ করুন",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
		tokenVarifiedMessage: "আপনার টোকেন যাচাই করা হয়েছে",
		validationLabel: "আপনার ওটিপি কোড জমা দিন",
	},
	changePassword: {
		pageTitle: "পাসওয়ার্ড পরিবর্তন",
		btnLabel: "জমা দিন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		successLabel: "আপনার পাসওয়ার্ড আপডেট করা হয়েছে.",
		errorLabel: "আপনার পাসওয়ার্ড আপডেট ব্যর্থ হয়েছে",
		passNotMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		changePassSuccessPara: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন করা হয়েছে.",
		changePassContinueBtn: "পরবর্তী",
	},
	changeLanguages: {
		pageTitle: "ভাষা নির্বাচন করুন",
		btnLabel: "সংরক্ষণ করুন",
	},
	brandDetails: {
		skusLabel: "এসকেইউ",
		descriptionLabel: "বর্ণনা",
		productTitleLabel: "এই ব্র্যান্ড পণ্য",
	},
	mysuppliers: {
		skusLabel: "এসকেইউ",
		productTitleLabel: "এই সরবরাহকারী পণ্য",
	},
	productDetails: {
		offerStart: "অফার শুরুর তারিখ",
		offerEnd: "অফার শেষ হওয়ার তারিখ",
		moqLabel: "সর্বনিম্ন অর্ডার",
		consumerLabel: "ভোক্তা মূল্য (এমআরপি)",
		profitLabel: "লাভ",
		productDetailTitle: "পণ্যের বিবরণ",
		expireData: "মেয়াদ উত্তীর্ণের তারিখ",
		manufactureData: "উত্পাদন তারিখ",
		productWeight: "পণ্যের ওজন",
		packingType: "প্যাকিং টাইপ",
		importedBy: "দ্বারা আমদানীকৃত",
		manufacturedBy: "দ্বারা নির্মিত",
		country: "দেশ",
		descriptionLabel: "বর্ণনা",
		btnLabel: "অর্ডার করুন",
		inStock: "ইন স্টক",
		outStock: "আউট স্টক",
		skuId: "এসকেইউ আইডি",
	},
	createOrder: {
		pageTitle: "অর্ডার তৈরী করুন",
		buyerDetailsTitle: "ক্রেতার বিবরণ",
		sellerDetailsTitle: "বিক্রেতার বিবরণ",
		deliveryDetailsTitle: "পণ্য সরবরাহ",
		productItemTitle: "পণ্য আইটেম",
		costSummaryTitle: "খরচ সারসংক্ষেপ",
		paymentDetailsTitle: "অর্থ প্রদানের বিবরণ",
		paymentTermsTitle: "অর্থ প্রদানের শর্তাবলী",
		paymentMethodeTitle: "অর্থ প্রদানের পদ্ধতি",
		moqLabel: "সর্বনিম্ন অর্ডার",
		pcsLabel: "পিছ",
		subtotal: "মোট",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "মূল্যছাড়",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "সার্ভিস চার্জ ",
		total: " মোট",
		immediatePayment: "তাৎক্ষণিক পেমেন্ট",
		pickAndPay: "পিক অ্যান্ড পে",
		today: "ডেলিভারি দিনের রাতে",
		threeDays: "৩ দিনের মধ্যে অর্থ প্রদান করুন",
		sevenDays: "৭ দিনের মধ্যে অর্থ প্রদান করুন",
		immediatePaymentText: "এই অর্ডারের জন্য কোনও সার্ভিস চার্জ  প্রযোজ্য নয়",
		todayPaymentText1: "আজকের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		todayPaymentText2: "এই অর্ডারে যোগ করা হবে",
		threePaymentText1: "৩ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ ",
		threePaymentText2: "এই অর্ডারে যোগ করা হবে.",
		sevenPaymentText1: "৭ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		sevenPaymentText2: "এই অর্ডারে যোগ করা হবে",
		cashOnDelivery: "ক্যাশ অন ডেলিভারি",
		cashOnDeliveryText1: "নগদ অর্থ প্রদান করুন",
		cashOnDeliveryText2: "যখন পণ্য বিতরণ করা হয়",
		cash: "ক্যাশ",
		bkash: "বিকাশ",
		nagad: "নগদ",
		cashText: "নগদ অর্থ প্রদান করুন",
		bkashText: "বিকাশের মাধ্যমে অর্থ প্রদান করুন",
		nagadText: "নগদের মাধ্যমে অর্থ প্রদান করুন",
		bankTransfer: "ব্যাংক ট্রান্সফার",
		bankTransferText1: "ব্যাংক ট্রান্সফার - পরিশোধ করুন",
		bankTransferText2: "রেফারেন্স হিসাবে 'অর্ডার নম্বর' সহ ব্যাংক অ্যাকাউন্ট",
		bankTransferText3: "ব্যাংকের নাম",
		bankTransferText4: "অ্যাকাউন্টের নাম",
		bankTransferText5: "অ্যাকাউন্টের নম্বর",
		bankTransferText6: "রাউট নম্বর",
		bankTransferText7: "শাখা",
		btnLabel: "নিশ্চিত",
		addMoreProductLabel: "আরও পণ্য যোগ করুন",
		addMoreProduct: "পণ্য যোগ করুন",
		selectProductLabel: "জমা দিন",
		cosgressTitle: "অভিনন্দন!",
		cosgressPara: "আপনার অর্ডার সম্পূর্ণ হয়েছে।",
		congressCancelText:
			"অর্ডার নিয়ে কোনো সমস্যা হলে রাত ১২ টার আগে বাতিল করুন। আপনি ১২ টার পরে অর্ডার বাতিল করতে পারবেন না।",
		congressProductLabel: "পরবর্তী",
		orderNow: "অর্ডার করুন",
		alert: "সতর্কতা",
		importentMessage: "গুরুত্বপূর্ণ বার্তা",
		deliveryDateMessage: "অনুগ্রহ করে ডেলিভারির তারিখ নির্বাচন করুন",
		paymentTermsMessage: "অনুগ্রহ করে অর্থপ্রদানের মেয়াদ নির্বাচন করুন",
		paymentMethodMessage: "অনুগ্রহ করে পেমেন্ট পদ্ধতি নির্বাচন করুন",
		backButtonMessage:
			"আপনার কার্টে কিছু আইটেম আছে, আপনি যদি এই পৃষ্ঠাটি ছেড়ে যান তবে আপনার কার্ট খালি হয়ে যাবে।",
		okBtnLabel: "হ্যাঁ",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		placeConformationMessage: "আপনি কি এই অর্ডার দেওয়ার বিষয়ে নিশ্চিত?",
		lessThenMoq:
			"ইনপুট করা আইটেম পরিমাণ সর্বনিম্ন বা সর্বোচ্ছ অর্ডার পরিমাণের চেয়ে কম বা বেশি।",
		all: "সবগুলো",
		number: "নম্বর",
		numberWarning:
			"এই নাম্বার ছাড়া অন্য কোন নাম্বারে টাকা পাঠালে সেটি বাতিল বলে গণ্য হবে",
		islamiBank: "ইসলামী ব্যাংক",
		bankAsia: "ব্যাংক এশিয়া",
		payTo: "প্রদান করুন",
		merchantNumber: "মার্চেন্ট নাম্বার",
		outOffStock: "পণ্যটি ষ্টকে নেই",
		wrongProductCount: "পণ্য নির্বাচন নম্বর ভুল",
	},
	selectProduct: {
		pageTitle: "পণ্য নির্বাচন করুন",
		totalLabel: "মোট",
		btnLabel: "জমা দিন",
	},
	orderList: {
		pageTitle: "আমার অর্ডার তালিকা",
		tabMenuRecent: "সাম্প্রতিক",
		tabMenuPlaced: " স্থাপিত",
		tabMenuAccepted: "গৃহীত",
		tabMenuScDelivered: "ডেলিভারির জন্য নির্ধারিত",
		tabMenuPartialDelivered: "আংশিক সররাহকৃত",
		tabMenuDelivered: "সরবরাহকৃত",
		tabMenuReturn: "ফেরত পণ্য",
		tabMenuPartialPaid: "আংশিক পরিশোধকৃত",
		tabMenuPaid: "পরিশোধিত",
		tabMenuCancel: "বাতিল",
		tabMenuDeliveryFailed: "ডেলিভারি ব্যর্থ হয়েছে",
		orderItem: "অর্ডারকৃত পণ্য",
		seller: "বিক্রেতা",
		buyer: "ক্রেতা",
		createdBy: "তৈরি করেছেন",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		notFound: "কোন তালিকা পাওয়া যায়নি।",
		assistantOrder: "এসিষ্টেন্ড অর্ডার তৈরী করুন",
	},
	orderDetails: {
		createdBy: "তৈরি করেছেন",
		placedLabel: "অর্ডার হয়েছে ",
		deliveredLabel: "ডেলিভারী সম্পন্ন হয়েছে",
		paidLabel: "পরিশোধিত",
		orderItemLabel: "অর্ডারকৃত পণ্য",
		castItemLabel: "খরচের বিবরণ",
		timelineLabel: "সময়রেখা",
		subtotal: "মোট",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "ডিসকাউন্ট",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "সার্ভিস চার্জ ",
		total: "মোট",
		paymentSettlementLabel: "পেমেন্ট সেটেলমেন্ট",
		date: "তারিখ",
		amount: "পরিমাণ",
		dueDate: "নির্দিষ্ট তারিখ",
		remark: "মন্তব্য",
		collectedBy: "দ্বারা সংগৃহীত",
		paymentDetailsLabel: "অর্থ প্রদানের বিবরণ",
		paymentMethod: "অর্থ প্রদানের পদ্ধতি",
		paymentTerms: "অর্থ প্রদানের শর্তাবলী",
		paymentDueDate: "অর্থ প্রদানের বাকি তারিখ",
		totalDue: "মোট বকেয়া",
		totalPaid: "মোট পরিশোধ",
		remaining: "অবশিষ্ট",
		deliveryDetailsLabel: "ডেলিভারির বিস্তারিত",
		BuyerName: "ক্রেতার নাম",
		address: "ঠিকানা",
		phoneNumber: "মোবাইল নম্বর",
		email: "ইমেইল ",
		deliveryDate: "ডেলিভারি প্রদানের তারিখ",
		downloadInvoices: "চালান ডাউনলোড করুন",
		receiptDownloads: "রসিদ প্রিন্ট করুন",
		cancelOrder: "অর্ডার বাতিল করুন",
		cancelConformationAsk: "আপনি কি নিশ্চিত আপনি এই অর্ডার বাতিল করতে চান?",
		cancelConformationParagraph:
			"আপনি এই অর্ডারটি বাতিল করতে পারবেন না, আপনি শুধুমাত্র একই দিনে আপনার অর্ডার বাতিল করতে পারবেন।",
		yesBtn: "হ্যাঁ",
		noBtn: "না",
		withoutSettelment: "পেমেন্ট সম্পূর্ণ তথ্য এখানে দেখানো হবে।",
		bankInfoLabel: "পেমেন্ট ট্রান্সফারের জন্য ব্যাঙ্ক অ্যাকাউন্টের তথ্য",
		requestDeliveryDate: "ডেলিভারি অনুরোধের তারিখ",
		scheduleDeliveryDate: "সিডিউল ডেলিভারি তারিখ",
		scheduleDeliveryTime: "সিডিউল ডেলিভারি সময়",
		deliveryFailMessage: "ডেলিভারি ফেল মেসেজ",
		assignedDriver: "ড্রাইভার নিযুক্ত হয়েছেন",
	},
	businessCreateName: {
		pageTitle: "ব্যবসার নাম",
		businessName: "ব্যবসার নাম",
		businessNamePlaceholder: "ব্যবসার নাম দিন",
		paragraph:
			"আপনার ব্যবসা তৈরি করতে ৩ ধাপ দূরে আছি আমরা। আপনার ব্যবসার নাম দিয়ে শুরু করা যাক।",
		btnLabel: "পরবর্তী",
		submit: "জমা দিন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		errorLabel: "ব্যবসায়ের নাম লিখুন!",
	},
	businessType: {
		pageTitle: "ব্যবসার ধরণ",
		paragraph: "আপনার ব্যবসার ধরণ নির্বাচন করুন",
		fmcg: "এফএমসিজি",
		miniMart: "মিনি মার্ট",
		superMart: "সুপার মার্ট",
		pharmacy: "ফার্মেসি",
		cart: "কার্ট",
		bazaarStore: "বাজার দোকান",
		hawkerCenter: "হকার সেন্টার",
		restaurant: "রেস্টুরেন্ট",
		hotel: "হোটেল",
		cosmeticsStore: "প্রসাধনী দোকান",
		nonFmcg: "নন এফএমসিজি",
		office: "অফিস",
		NGO: "এনজিও",
		entertainmentPlace: "বিনোদনের স্থান",
		religiousOrganization: "ধর্মীয় সংগঠন",
		spa: "স্পা",
		salon: "সেলুন",
		gym: "জিম",
		electronicsStore: "ইলেক্ট্রনিক্স স্টোর",
		shoeStore: "জুতার দোকান",
		fashionStore: "ফ্যাশন স্টোর",
		laundryStore: "লন্ড্রি স্টোর",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "পরবর্তী",
		validationLabel: "একটি ব্যবসার ধরন নির্বাচন করুন",
	},
	businessLocation: {
		pageTitle: "ব্যবসার অবস্থান",
		paragraph: "আপনার ব্যবসার যোগাযোগের বিস্তারিত লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "আপনার ঠিকানা লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		email: "ইমেইল",
		emailPlaceholder: "আপনার ইমেল লিখুন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "সম্পন্ন করুন",
		successLabel: "ব্যবসা তৈরি করার জন্য অভিনন্দন",
		errorLabel: "ব্যবসা তৈরি ব্যর্থ হয়েছে",
		validationLabel: "অনুগ্রহ করে ঠিকানাটি পূরণ করুন",
		loader: "দয়া করে অপেক্ষা করুন...",
		validationCityLabel: "অনুগ্রহ করে শহর পূরণ করুন.",
		validationPostCodeLabel: "অনুগ্রহ করে পোস্টাল কোড পূরণ করুন.",
		locationValidation: "মানচিত্র থেকে অবস্থান চয়ন করুন",
	},
	locationPicker: {
		pageTitle: "দোকান অবস্থান অনুসন্ধান",
		btnLabel: "নিশ্চিত করুন",
		paragraph: `অনুগ্রহ করে, আপনার দোকান ঠিকানা নিশ্চিত করুন. আরেকটা থাকলে
আপনার দোকানের ঠিকানা খুঁজে পেতে সমস্যা, আপনার খুঁজুন
দোকানে ঠিকানা খুঁজছি।`,
	},
	location: {
		title: "আপনার অবস্থান সক্রিয় করুন",
		paragraph:
			"এই অ্যাপটির জন্য আপনার ডিভাইস এবং এই অ্যাপের জন্য লোকেশন পরিষেবা চালু থাকা প্রয়োজন। এই অ্যাপটি ব্যবহার করার আগে আপনাকে অবশ্যই সেগুলিকে সক্রিয় করতে হবে।",
		btnLabel: "শুধুমাত্র অ্যাপ ব্যবহার করার সময় অনুমতি দিন",
	},
	businessSelect: {
		pageTitle: "ব্যবসা নির্বাচন করুন",
	},
	pwaDownload: {
		header: "EkkHero Sales অ্যাপ ডাউনলোড করুন",
		paragraph:
			"অ্যাপটি ডাউনলোড করুন এবং এখন থেকে যেকোনো সময় আপনার সরবরাহকারীর কাছ থেকে বাল্ক অর্ডার করুন।",
		cancelBtn: "বাতিল করুন",
		downloadBtn: "ডাউনলোড করুন",
	},
	businessProfile: {
		pageTitle: "ব্যবসায়িক প্রোফাইল সেটিংস",
		businessDetailsLabel: "বিস্তারিত",
		registrationNo: "নিবন্ধনের নম্বর",
		taxNo: "টেক্স/ভ্যাট নম্বর",
		mobileNo: "মোবাইল নম্বর",
		officeNo: "অফিস নম্বর",
		email: "ইমেইল",
		website: "ওয়েবসাইট",
		branchLabel: "শাখা তালিকা",
		paymentDetailsLabel: "পেমেন্ট বিবরণ",
		bankInfoLabel: "ব্যাংক তথ্য",
		bankName: "ব্যাংকের নাম",
		branchName: "ব্যাংকের শাখা",
		branchAddress: "ব্যাংকের ঠিকানা",
		swiftCode: "ব্যাংক সুইফট কোড",
		acName: "ব্যাঙ্কের A/C নাম",
		acNumber: "ব্যাংক এ/সি নম্বর",
		bkashInfoLabel: "বিকাশ তথ্য",
		bkashAcName: "বিকাশ এ/সি নাম",
		bkashAcNo: "বিকাশ এ/সি নম্বর",
		nagadInfoLabel: "নগদ তথ্য",
		nagadAcName: "নাগদ এ/সি নাম",
		nagadAcNo: "নাগদ এ/সি নম্বর",
		upayInfoLabel: "উপাই তথ্য",
		upayAcName: "উপাই এ/সি নাম",
		upayAcNo: "উপাই এ/সি নম্বর",
		verified: "যাচাই",
		unVerified: "যাচাই করা হয়নি",
		editBusinessMenu: "ব্যবসা সম্পাদনা করুন",
		addPayMenu: "পেমেন্ট অপশন যোগ করুন ",
		addMenu: "শাখা যোগ করুন",
		verifyBusinessMenu: "ব্যবসা যাচাই করুন",
		bankInfoNotFound: "ব্যাংক তথ্য পাওয়া যায়নি",
	},
	businessEdit: {
		pageTitle: "ব্যবসা সম্পাদনা করুন",
		uploadPhoto: "ছবি আপলোড",
		email: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "আপনার ঠিকানা লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		resigter: "নিবন্ধনের নম্বর",
		resigterPlaceholder: "নিবন্ধনের নম্বর লিখুন",
		tex: "টেক্স/ভ্যাট নম্বর",
		texPlaceholder: "টেক্স/ভ্যাট নম্বর লিখুন",
		mobile: "মুঠোফোন নম্বর",
		mobilePlaceholder: "মুঠোফোন নম্বর লিখুন",
		office: "দপ্তর নম্বর",
		officePlaceholder: "দপ্তর নম্বর লিখুন",
		website: "ওয়েবসাইট",
		websitePlaceholder: "ওয়েবসাইট লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	bankDetails: {
		referenceNumber: "পরিচিত সংখ্যা",
		bankName: "ব্যাংকের নাম",
		accountName: "A/C নাম",
		accountNumber: "A/C নম্বর",
		routeNumber: "রুট নম্বর",
		branch: "শাখা",
	},
	invoice: {
		title: "চালান",
		receipt: "রসিদ",
		buyerDetails: "ক্রেতার বিবরণ",
		sellerDetails: "বিক্রেতার বিবরণ",
		description: "বর্ণনা",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "মোট",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "স্থাপিত",
		name: "নাম",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "সাবটোটাল",
		tax: "ট্যাক্স",
		discount: "ছাড়",
		creditFee: "সার্ভিস চার্জ ",
		deliveryFee: "ডেলিভারি করার টাকা",
		total: "মোট",
		paid: "পরিশোধিত",
		totalDue: "মোট বাকি",
		paymentTerms: "পরিশোধের শর্ত",
		days: "দিন",
		paymentMethod: "মূল্যপরিশোধ পদ্ধতি",
		referenceNumber: "ব্যাঙ্ক রেফারেন্স নম্বর",
		deliveryDate: "ডেলিভারির তারিখ ",
		dueDate: "পরিশোধযোগ্য তারিখ",
		printTime: "মুদ্রণের সময়",
		conditionsCreditFee: "অর্থপ্রদানের শর্তাবলী এবং সার্ভিস চার্জ ",
		immediatePayment: "জরুরী টাকা প্রদান",
		cashOnDelivery: "প্রদানোত্তর পরিশোধ",
		atNight: "ডেলিভারি দিবসের রাতে",
		payInthreeDay: "3 দিনের মধ্যে পেমেন্ট করুন",
		payInSevenDay: "7 দিনের মধ্যে পেমেন্ট করুন",
		afterSeven: "৭ দিন পর (প্রতিদিনের জন্য অতিরিক্ত)",
	},
	landing: {
		header1: "EkkHero এর জন্য আবেদন করুন",
		header2: "কাজ শুরু করুন",
		header3: "অর্থ উপার্জন করুন!",
		login: "প্রবেশ করুন",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia:"Malay",
		retail: "দোকানদার",
		manufacture: "সাপ্লায়ার",
		hotline: "হট লাইন",
		phoneNumber: "ফোন নম্বর",
		phoneNumberPlaceHolder: "ফোন নম্বর লিখুন",
		fAbout: "পাইকারি বাজার এখন হাতের মুঠোয়। বাকিতে কিনুন, পরে পরিশোধ করুন।",
		location: "অবস্থান",
		singapore: "সিঙ্গাপুর অফিস",
		bangladesh: "বাংলাদেশ অফিস",
		bOfficeAddress: "১৩ তলা, রাজ্জাক প্লাজা, মগ বাজার মোড়, ঢাকা-১২০৫",
		otherInfo: "অন্যান্য তথ্য",
		partners: "আমাদের অংশীদার",
		news: "খবর",
		support: "সাপোর্ট",
		corporate: "কর্পোরেট",
		refundPolicy: "রিফান্ড পোলেসি",
		termsCondition: "ট্রার্ম & কন্ডিশন",
		privacyPolicy: "প্রাইভেসি পোলেসি",
		contactUs: "যোগাযোগ করুন",
		messageUs: "মেসেজ করুন",
		facebook: "ফেসবুক",
		sales: "বিক্রয়",
		media: "মিডিয়া",
		downloadBtn: "ডাউনলোড করুন",
		backers: "আমাদের ব্যাকার্স",
		benefits: "আপনার সুবিধা",
		discover: "সর্বশেষ একবাজ খবর",
		benefitsOne: "২৪ ঘন্টা অন-টাইম ডেলিভারি",
		benefitsDisOne: "",
		benefitsTwo: "সেরা বাজার মূল্য",
		benefitsDisOneTwo: "",
		benefitsThree: "বৃহত্তম SKU ভাণ্ডার",
		benefitsDisOneThree: "",
		benefitsFore: "ক্রয়ের জন্য অর্থায়ন সমর্থন",
		benefitsDisOneFore: "",
		partners: "আমাদের অংশীদার",
		seeMore: "আরো দেখুন",
	},
	customerList: {
		pageTitle: "কাস্টমার তালিকা",
		myCustomer: "আমার কাস্টমার",
		allCustomer: "সকল কাস্টমার",
		searchPlaceHolder: "কাস্টমার সার্চ করুন",
	},
	myCustomer: {
		pageTitle: "আমার কাস্টমার তালিকা",
		searchLabel: "কাস্টমার সার্চ করুন",
		createCustomer: "নতুন কাস্টমার তৈরি করুন",
	},
	myProducts: {
		pageTitle: "আমার পণ্য তালিকা",
	},
	newCustomer: {
		pageTitle: "নতুন কাস্টমার তৈরি করুন",
		mobileNo: "মোবাইল নম্বর",
		customerNoHolder: "কাস্টমার মোবাইল নম্বর লিখুন",
		customerName: "কাস্টমার নাম",
		customerNameHolder: "গ্রাহকের পুরো নাম লিখুন",
		businessPhoto: "ব্যবসায়িক ছবি",
		uploadPhoto: "ব্যবসা/স্টোরের ছবি আপলোড করুন",
		uploadPhotoTitle: "ছবি আপলোড",
		uploadFromGallery: "গ্যালারি থেকে আপলোড করুন",
		takePhoto: "ছবি তোল",
		businessName: "ব্যবসার নাম",
		businessNameHolder: "ব্যবসা/স্টোরের নাম লিখুন",
		address: "ঠিকানা",
		addressHolder: "ব্যবসা/স্টোরের ঠিকানা লিখুন",
		city: "শহর",
		cityHolder: "শহরের নাম লিখুন",
		postalCode: "পোস্ট কোড",
		postalCodeHolder: "পোস্ট কোড লিখুন",
		selectBusiness: "ব্যবসার ধরন নির্বাচন করুন",
		fmcg: "এফএমসিজি",
		nonFmcg: "নন-এফএমসিজি",
		nextBtn: "পরবর্তী",
		completeBtn: "সম্পূর্ণ",
		customerFindText: `গ্রাহক ইতিমধ্যেই এখানে পাওয়া গেছে, আপনি চাইলে এটিকে "আমার গ্রাহক"-এ যোগ করতে পারেন। এবং যদি আপনি না চান, আপনি সবুজ বোতামে ক্লিক করে একটি নতুন গ্রাহক তৈরি করতে পারেন।`,
		addBusiness: "দোকান যোগ করুন",
		addBusinessInfo:
			"আপনি কি আমার কাষ্টমার হিসাবে এই দোকান টিকে যোগ করতে চান?",
		noBtn: "না",
	},
	error: {
		warning: `আপনি কোন ব্যবসার সাথে যুক্ত নন।
আপনাকে তাদের ব্যবসায়িক অ্যাকাউন্টে একজন কর্মচারী হিসেবে যোগ করতে অনুগ্রহ করে আপনার সংশ্লিষ্ট ব্যবসা প্রশাসকের সাথে যোগাযোগ করুন।`,
		notFound: "কোন বিষয়বস্তু পাওয়া যায়নি",
		btn: "হোম পেজে ফিরে যান",
		logout: "লগ আউট",
	},
	supplierSelect: {
		pageTitle: "অর্ডার সিস্টেম",
		multiple: "একাধিক সরবরাহকারীর জন্য অর্ডার তৈরি করুন",
		specific: "নির্দিষ্ট সরবরাহকারী জন্য অর্ডার তৈরি করুন",
		next: "পরবর্তী",
		chooseText: "নিচের যেকোনো একটি বেছে নিন, যেভাবে আপনি অর্ডার করতে চান",
	},
	supplierList: {
		pageTitle: "সরবরাহকারী তালিকা",
		searchPlaceHolder: "সরবরাহকারী সার্চ করুন",
		sellerChangeWarning:
			"আপনি যদি সরবরাহকারী পরিবর্তন করেন তবে আপনার পূর্বে নির্বাচিত সমস্ত পণ্য মুছে ফেলা হবে!",
		no: "না",
		yes: "হ্যাঁ",
	},
	autoLogOut: {
		pageTitle: "স্বয়ংক্রিয় লগআউট",
		warningMsg:
			"স্বয়ংক্রিয় লগআউট যেহেতু এই নম্বরটি অন্য লগইনে ব্যবহৃত হয়েছিল৷",
		instrMsg: "আবার লগইন করতে নিচের সবুজ বোতামে ক্লিক করুন",
		btnLabel: "লগইন",
	},
	blackListed: {
		pageTitle: "ব্ল্যাকলিস্টেড",
		warningMsg: "আপনার ব্যবসা ব্ল্যাকলিস্টে রয়েছে",
		instrMsg: "আপনার ব্যবসার মালিককে অনুরোধ করুন EkkBaz এ যোগাযোগ করে প্রয়োজনীয় ব্যবস্থা নিয়ে পুনরায় আপনার ব্যবসা সক্রিয় করুন।",
		btnLabel: "লগ আউট",
	}
};
export default bangla;
