import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import Menu from "assets/images/icon/svg/Menu";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import SearchIcon from "assets/images/icon/svg/SearchIcon";
import Cross from "assets/images/icon/svg/Cross";

export default function NavigationBar({
	menuIconClick,
	sticky,
	searchQ,
	setSearchQ,
	fetchProduct,
	singleProductSearch,
	tempTerm,
	setTempTerm,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const deleteQueryHandler = async (e) => {
		setSearchQ("");
		setTempTerm("");
	};
	return (
		<div
			className={`home-nav absolute left-0 top-0 w-full z-10 px-3 pt-2 ${
				sticky ? sticky : ""
			}`}>
			<IonGrid>
				<IonRow class='items-center'>
					<IonCol size='1' class='p-0'>
						<div className='text-center' onClick={menuIconClick}>
							<Menu color={sticky !== "" ? "white" : "red"} />
						</div>
					</IonCol>
					<IonCol size='11'>
						{/* <div>
							<IonSearchbar
								className='font-sans text-12 '
								placeholder={ekkLanguage.home.searchLabel}
								value={searchQ}
								onIonChange={(e) => {
									setSearchQ(e.target.value);
									if (!e.target.value) {
										fetchProduct();
									}
								}}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										singleProductSearch();
									}
								}}></IonSearchbar>
						</div> */}
						<div className='w-full'>
							<div className='relative'>
								<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
									<SearchIcon color='rgba(0,0,0,.6)' />
								</span>
								<input
									className='bg-white w-full h-11 rounded-[7px] pl-12 search-input border'
									placeholder={ekkLanguage.home.searchLabel}
									style={{
										boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) ",
									}}
									value={searchQ}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											setTempTerm(e.target.value);
											singleProductSearch();
										}
									}}
									onChange={(e) => {
										setSearchQ(e.target.value);
									}}></input>
								{searchQ && (
									<span
										className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
										onClick={deleteQueryHandler}>
										<Cross />
									</span>
								)}
							</div>
						</div>
					</IonCol>
				</IonRow>

				<div className='ml-10'>
					<p className='px-4 pb-4'>
						{tempTerm && (
							<span>
								{" "}
								search: <span className='font-bold'>{tempTerm}</span>
							</span>
						)}
					</p>
				</div>
			</IonGrid>
		</div>
	);
}
