import { combineReducers } from "redux";
import auth from "redux/container/authSlice";
import businessSettingsSlice from "redux/container/businessSettingsSlice";
import user from "redux/container/userSlice";
import sellerSlice from "redux/container/sellerSlice";

import { counterSlice } from "redux/container/counter/counterSlice";
import orderSlice from "redux/container/orderSlice";
import customer from "redux/container/customerSlice";
import paymentMethodSlice from "redux/container/paymentMethodSlice";
import shoppingCartSlice from "redux/container/shoppingCartSlice";
import languageSlice from "redux/container/languageSlice";

const appReducer = combineReducers({
	auth,
	user,
	customer,
	cart: shoppingCartSlice,
	counter: counterSlice,
	order: orderSlice,
	payment: paymentMethodSlice,
	lan: languageSlice,
	businessSettings: businessSettingsSlice,
	seller: sellerSlice,
});

const rootReducer = (state, action) => {
	if (action.type === "auth/logout") state = undefined;
	return appReducer(state, action);
};
export default rootReducer;
