import React from "react";

export default function Customer() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<g clipPath='url(#clip0_1_524)'>
				<path
					d='M20 8.33333L18.1667 0H1.83333L0.02 8.1525L0 9.16667C0.00302225 9.97107 0.299312 10.7468 0.833333 11.3483V17.5C0.833333 18.163 1.09673 18.7989 1.56557 19.2678C2.03441 19.7366 2.67029 20 3.33333 20H16.6667C17.3297 20 17.9656 19.7366 18.4344 19.2678C18.9033 18.7989 19.1667 18.163 19.1667 17.5V11.3483C19.7007 10.7468 19.997 9.97107 20 9.16667V8.33333ZM1.66667 8.42417L3.16667 1.66667H5.83333V5H7.5V1.66667H12.5V5H14.1667V1.66667H16.8333L18.3333 8.42417V9.16667C18.3333 9.60869 18.1577 10.0326 17.8452 10.3452C17.5326 10.6577 17.1087 10.8333 16.6667 10.8333H15.8333C15.3913 10.8333 14.9674 10.6577 14.6548 10.3452C14.3423 10.0326 14.1667 9.60869 14.1667 9.16667H12.5C12.5 9.60869 12.3244 10.0326 12.0118 10.3452C11.6993 10.6577 11.2754 10.8333 10.8333 10.8333H9.16667C8.72464 10.8333 8.30072 10.6577 7.98816 10.3452C7.67559 10.0326 7.5 9.60869 7.5 9.16667H5.83333C5.83333 9.60869 5.65774 10.0326 5.34518 10.3452C5.03262 10.6577 4.60869 10.8333 4.16667 10.8333H3.33333C2.89131 10.8333 2.46738 10.6577 2.15482 10.3452C1.84226 10.0326 1.66667 9.60869 1.66667 9.16667V8.42417ZM16.6667 18.3333H3.33333C3.11232 18.3333 2.90036 18.2455 2.74408 18.0893C2.5878 17.933 2.5 17.721 2.5 17.5V12.3817C2.77155 12.4567 3.05163 12.4965 3.33333 12.5H4.16667C4.64158 12.4985 5.11061 12.3948 5.54195 12.1961C5.9733 11.9974 6.35687 11.7083 6.66667 11.3483C6.97646 11.7083 7.36004 11.9974 7.79138 12.1961C8.22273 12.3948 8.69176 12.4985 9.16667 12.5H10.8333C11.3065 12.5002 11.7743 12.3995 12.2054 12.2045C12.6365 12.0095 13.0211 11.7247 13.3333 11.3692C13.6456 11.7247 14.0301 12.0095 14.4612 12.2045C14.8924 12.3995 15.3602 12.5002 15.8333 12.5H16.6667C16.9484 12.4965 17.2285 12.4567 17.5 12.3817V17.5C17.5 17.721 17.4122 17.933 17.2559 18.0893C17.0996 18.2455 16.8877 18.3333 16.6667 18.3333Z'
					fill='black'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1_524'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
