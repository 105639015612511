import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import MyProduct from "components/mobile-components/Home/MyProduct";
import NavigationBar from "components/mobile-components/NavigationBar";
import { getUserBusiness } from "config/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Product from "services/Product";

function MyProductsPage() {
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("");
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [products, setProduct] = useState([]);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(false);
	const userBusiness = getUserBusiness();
	const [searchQ, setSearchQ] = useState("");
	const [pageCount, setPageCount] = useState(2);
	const [productCount, setProductCount] = useState();
	let [tempTerm, setTempTerm] = useState("");

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	//get product data
	let getProductList = async () => {
		setLoading(true);
		let response = await Product.buyerSellerProductList(userBusiness.id);

		if (response.status === 200) {
			setLoading(false);
			setProduct(response.results);
			setProductCount(response.count);
		} else {
			setLoading(false);
		}
	};
	// useEffect(() => {
	// 	getProductList();
	// }, []);
	let settings = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: true,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};

	async function singleProductSearch() {
		try {
			let response = await Product.sellerProductSearch(
				userBusiness.id,
				searchQ
			);
			if (response.status === 200) {
				setProduct(response.results);
			}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		if (searchQ === "") {
			setTempTerm("");
			getProductList();
		}
	}, [searchQ]);

	let lodeMoreData = async () => {
		let response = await Product.buyerSellerProductList(
			userBusiness.id,
			pageCount
		);
		if (response.status === 200) {
			if (response.results.length > 0) {
				setPageCount((prev) => prev + 1);
				setProduct([...products, ...response.results]);
			}
		}
	};
	async function searchNext(event) {
		let timeout = setTimeout(() => {
			lodeMoreData();
			// event.target.complete();
			clearTimeout(timeout);
		}, 1000);
	}

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("");
						}
					}}
					// onIonScrollEnd={(ev) => {
					// 	if (productCount > products.length) {
					// 		searchNext(ev);
					// 	}
					// }}
				>
					<NavigationBar
						menuIconClick={menuIconClick}
						sticky={initStickyClass}
						setSearchQ={setSearchQ}
						searchQ={searchQ}
						fetchProduct={getProductList}
						singleProductSearch={singleProductSearch}
						tempTerm={tempTerm}
						setTempTerm={setTempTerm}
					/>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>

					<section className='px-2'>
						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.orderList.loader}
							/>
						) : (
							<MyProduct
								loading={loading}
								recommendData={products}
								title={ekkLanguage.letNav.myProducts}
								settinges={settings}
							/>
						)}
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							lodeMoreData();
							setTimeout(() => ev.target.complete(), 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner='bubbles'></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
// export default withLocation(Home);
export default MyProductsPage;
