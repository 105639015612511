import {
	IonApp,
	IonContent,
	IonHeader,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ProductFilter from "components/mobile-components/SelectProduct/ProductFilter";
import ProductList from "components/mobile-components/SelectProduct/ProductList";
import ProductSearch from "components/mobile-components/SelectProduct/ProductSearch";
import SelectProduct from "components/mobile-components/SelectProduct/SelectProduct";
import { getProductSeller, getUserBusiness } from "config/user";
import { shoppingListItem } from "helpers/shoppingCart";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Product from "services/Product";

export default function SelectProductPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [productList, setProductList] = useState([]);
	const [productCartList, setProductCartList] = useState(shoppingListItem());
	const [productBrands, setProductBrands] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedBrand, setSelectedBrand] = useState("");
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [metaCount, setMetaCount] = useState(30);
	const [hasMoreData, setHasMoreData] = useState(true);
  	const [reachedEnd, setReachedEnd] = useState(false);
	const orderType = useSelector((state) => state.seller);
	const userBusiness = orderType?.multiSupplier
		? orderType.data
		: getUserBusiness();

	async function getProductBrands() {
		try {
			const response = await Product.productBrands(userBusiness.id);
			if (response.status === 200) {
				setProductBrands(response?.results);
				setMetaCount(response.count);
				if (response?.results?.length > 0) {
					setHasMoreData(true);
					setReachedEnd(false);
				  } else {
					setHasMoreData(false);
				  }
			}else {
				setHasMoreData(false);
			}
		} catch (error) {
			console.log(error);
		}
	}
	async function getProductsFromBrands(id) {
		setLoading(true);
		setPageCount(2);
		setSelectedBrand(id);
		setMetaCount(1);
		setProductList([]);
		try {
			if (searchQ) {
				let response = await Product.singleProductSearch(
					id,
					userBusiness.id,
					searchQ
				);
				if (response.status === 200) {
					setProductList(response?.results);
					setMetaCount(response.count);
					if (response?.results?.length > 0) {
						setHasMoreData(true);
						setReachedEnd(false);
					  } else {
						setHasMoreData(false);
					  }
				}else {
					setHasMoreData(false);
				}
			} else {
				const response = await Product.singleBrandProducts(
					userBusiness.id,
					id
				);
				if (response.status === 200) {
					setProductList(response?.results);
					setMetaCount(response.count);
					if (response?.results?.length > 0) {
						setHasMoreData(true);
						setReachedEnd(false);
					  } else {
						setHasMoreData(false);
					  }
				}else {
					setHasMoreData(false);
				}
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	async function fetchProduct() {
		setLoading(true);
		try {
			if (searchQ) {
				let response = await Product.sellerProductSearch(
					userBusiness.id,
					searchQ
				);
				if (response.status === 200) {
					setLoading(false);
					setProductList(response?.results);
					setMetaCount(response.count);
					if (response?.results?.length > 0) {
						setHasMoreData(true);
						setReachedEnd(false);
					  } else {
						setHasMoreData(false);
					  }
				} else {
					setLoading(false);
					setHasMoreData(false)
				}
			} else {
				if (selectedBrand) {
					const response = await Product.singleBrandProducts(
						userBusiness.id,
						selectedBrand
					);
					if (response.status === 200) {
						setProductList(response?.results);
						setMetaCount(response.count);
						setLoading(false);
						if (response?.results?.length > 0) {
							setHasMoreData(true);
							setReachedEnd(false);
						  } else {
							setHasMoreData(false);
						  }
					} else {
						setLoading(false);
						setHasMoreData(false)
					}
				} else {
					const response = await Product.buyerSellerProductList(
						userBusiness.id
					);
					if (response.status === 200) {
						setLoading(false);
						setProductList(response?.results);
						setMetaCount(response.count);
						if (response?.results?.length > 0) {
							setHasMoreData(true);
							setReachedEnd(false);
						  } else {
							setHasMoreData(false);
						  }
					} else {
						setLoading(false);
						setHasMoreData(false);
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}
	let getAllProduct = async () => {
		setLoading(true);
		try {
			if (searchQ) {
				let response = await Product.sellerProductSearch(
					userBusiness.id,
					searchQ
				);
				if (response.status === 200) {
					setLoading(false);
					setProductList(response?.results);
					setMetaCount(response.count);
					if (response?.results?.length > 0) {
						setHasMoreData(true);
						setReachedEnd(false);
					  } else {
						setHasMoreData(false);
					  }
				} else {
					setLoading(false);
					setHasMoreData(false);
				}
			} else {
				const response = await Product.buyerSellerProductList(
					userBusiness.id
				);
				if (response.status === 200) {
					setLoading(false);
					setProductList(response?.results);
					setMetaCount(response.count);
					if (response?.results?.length > 0) {
						setHasMoreData(true);
						setReachedEnd(false);
					  } else {
						setHasMoreData(false);
					  }
				} else {
					setLoading(false);
					setHasMoreData(false)
				}
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		fetchProduct();
		getProductBrands();
	}, []);

	async function singleProductSearch() {
		try {
			let response;
			if (selectedBrand) {
				response = await Product.singleProductSearch(
					selectedBrand,
					userBusiness.id,
					searchQ
				);
			} else {
				response = await Product.sellerProductSearch(
					userBusiness.id,
					searchQ
				);
			}

			if (response.status === 200) {
				setProductList(response?.results);
				setMetaCount(response.count);
				if (response?.results?.length > 0) {
					setHasMoreData(true);
					setReachedEnd(false);
				  } else {
					setHasMoreData(false);
				  }
			}else{
				setHasMoreData(false)
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (searchQ === "") {
			fetchProduct();
		}
	}, [searchQ]);

	let lodeMoreData = async () => {
		if (searchQ) {
			if (selectedBrand) {
				let response = await Product.singleProductSearch(
					selectedBrand,
					userBusiness.id,
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					if (response.results.length > 0) {
						setPageCount((prev) => prev + 1);
						setProductList([...productList, ...response?.results]);
						setMetaCount(response.count);
					}
				}else{
					setHasMoreData(false)
				}
			} else {
				let response = await Product.sellerProductSearch(
					userBusiness.id,
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					if (response.results.length > 0) {
						setPageCount((prev) => prev + 1);
						setProductList([...productList, ...response?.results]);
						setMetaCount(response.count);
					}else{
						setHasMoreData(false)
					}
				}else{
					setHasMoreData(false)
				}
			}
		} else {
			if (selectedBrand) {
				const response = await Product.singleBrandProducts(
					userBusiness.id,
					selectedBrand,
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setProductList([...productList, ...response?.results]);
					setMetaCount(response.count);
					if (response?.results?.length > 0) {
						setHasMoreData(true);
						setReachedEnd(false);
					  } else {
						setHasMoreData(false);
					  }
				}else{
					setHasMoreData(false)
				}
			} else {
				let response = await Product.buyerSellerProductList(
					userBusiness.id,
					pageCount
				);
				if (response.status === 200) {
					if (response.results.length > 0) {
						setPageCount((prev) => prev + 1);
						setProductList([...productList, ...response?.results]);
						setMetaCount(response.count);
						setHasMoreData(false)
					}else{
						setHasMoreData(true)
					}
				}else{
					setHasMoreData(false)
				}
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonHeader>
					<BackButtonTitle
						title={ekkLanguage.selectProduct.pageTitle}
						seletAll='true'
					/>
				</IonHeader>
				<IonContent>
					<section className='pt-14'>
						<ProductSearch
							setSearchQ={setSearchQ}
							searchQ={searchQ}
							// stickySearch={true}
							fetchProduct={fetchProduct}
							singleProductSearch={singleProductSearch}
							setSelectedBrand={setSelectedBrand}
						/>
						<ProductFilter
							productBrands={productBrands}
							getProductsFromBrands={getProductsFromBrands}
							getProductBrands={getProductBrands}
							fetchProduct={fetchProduct}
							setSearchQ={setSearchQ}
							setSelectedBrand={setSelectedBrand}
							getAllProduct={getAllProduct}
						/>
						<ProductList
							loading={loading}
							productList={productList}
							setProductList={setProductList}
							productCartList={productCartList}
							setProductCartList={setProductCartList}
						/>
						<SelectProduct product={productList?.[0]} />
					</section>
					<div>
						<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							lodeMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 1000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
