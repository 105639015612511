import { IonApp, IonContent, IonPage } from "@ionic/react";
import BrandDetails from "components/mobile-components/BrandDetails/BrandDetails";
import BackButton from "components/mobile-components/Common/BackButton";
import Banner from "components/mobile-components/ProductDetails/Banner";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Brand from "services/Brand";
import Product from "services/Product";

export default function BrandDetailsPage() {
	let { id } = useParams();
	const [product, setProduct] = useState([]);
	const [brandInfo, setBrandInfo] = useState([]);
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("");
	const [pageCount, setPageCount] = useState(2);
	const [metaCount, setMetaCount] = useState(30);
	const [productBrands, setProductBrands] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedBrand, setSelectedBrand] = useState("");
	const [cardLoading, setCardLoading] = useState(false);
	const [sellerData, setSellerData] = useState([]);
	const [selectedSeller, setSelectedSeller] = useState("");
	const [sellerProductLoader, setSellerProductLoader] = useState(false);
	const [selectedSellerId, setSelectedSellerId] = useState("");

	//get brand data
	let getSellerData = async () => {
		setCardLoading(true);
		let response = await Brand.getSellerData(id);
		if (response.status === 200) {
			setCardLoading(false);
			setSellerData(response.data);
		} else {
			setCardLoading(false);
		}
	};
	useEffect(() => {
		getSellerData();
	}, []);

	let getSellerProduct = async (sellerId) => {
		setSellerProductLoader(true);
		setSelectedSeller(sellerId);
		let response = await Brand.getSellerProduct(id, sellerId);
		if (response.status === 200) {
			setProduct(response.data);
			setSellerProductLoader(false);
		} else {
			setSellerProductLoader(false);
		}
	};

	//get brand data
	let brandData = async () => {
		let response = await Brand.singleBrandDetails(id);
		if (response.status === 200) {
			setBrandInfo(response);
		} else {
		}
	};
	useEffect(() => {
		brandData();
	}, []);

	//get suppliers data
	let productData = async () => {
		setSellerProductLoader(true);
		let response = await Brand.brandProducts(id);
		if (response.status === 200) {
			setProduct(response.data);
			setSellerProductLoader(false);
		} else {
			setSellerProductLoader(false);
		}
	};
	useEffect(() => {
		productData();
	}, []);

	const contentRef = useRef();
	let settinges = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: true,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};

	let slideData = brandInfo?.data?.images?.length
		? [...brandInfo?.data?.images]
		: ["/assets/images/badge_big_default.png"];

	let lodeMoreData = async () => {
		let response = await Brand.brandProducts(id, pageCount);
		if (response.status === 200) {
			if (response.data.length > 0) {
				setPageCount((prev) => prev + 1);
				setProduct([...product, ...response.data]);
				setMetaCount(response.meta.product_count);
			}
		}
	};
	async function getProductsFromBrands(Bid) {
		setSellerProductLoader(true);
		setSelectedBrand(Bid);
		try {
			const response = await Product.singleBrandProducts(id, Bid);
			if (response.status === 200) {
				setProduct(response?.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setSellerProductLoader(false);
		}
	}
	//brandProductSearch;
	async function singleProductSearch() {
		try {
			let response;
			if (selectedSellerId) {
				response = await Product.singleProductSearch(
					id,
					selectedSellerId,
					searchQ
				);
			} else {
				response = await Product.brandProductSearch(id, searchQ);
			}

			if (response.status === 200) {
				setProduct(response.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setSellerProductLoader(false);
		}
	}

	// const delayedQuery = useCallback(debounce(singleProductSearch, 600), [
	// 	searchQ,
	// ]);

	useEffect(() => {
		if (searchQ === "") {
			productData();
		}
	}, [searchQ]);

	async function searchNext(event) {
		let timeout = setTimeout(() => {
			lodeMoreData();
			// event.target.complete();
			clearTimeout(timeout);
		}, 1000);
	}

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("");
						}
					}}
					onIonScrollEnd={(ev) => {
						if (Number(metaCount) / 10 > pageCount) {
							searchNext(ev);
						} else {
							document.querySelector(".infinite-loading").style.display =
								"none";
						}
					}}>
					<BackButton sticky={initStickyClass} />
					<Banner slideData={slideData} />
					<BrandDetails
						brandInfo={brandInfo}
						product={product}
						settinges={settinges}
						productBrands={productBrands}
						getProductBrands={productData}
						getProductsFromBrands={getProductsFromBrands}
						setSearchQ={setSearchQ}
						searchQ={searchQ}
						singleProductSearch={singleProductSearch}
						setSelectedBrand={setSelectedBrand}
						setCardLoading={setCardLoading}
						cardLoading={cardLoading}
						sellerData={sellerData}
						getSellerProduct={getSellerProduct}
						sellerId={id}
						selectedSeller={selectedSeller}
						sellerProductLoader={sellerProductLoader}
						setSelectedSellerId={setSelectedSellerId}
					/>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
