import { getLanguage } from "config/language";
import React from "react";
import MainRoutes from "routes/Routes";
import "./styles/index.scss";

function App() {
	// let language = getLanguage();
	// useEffect(() => {
	// 	if (language === "en") {
	// 		document.documentElement.style.setProperty(
	// 			"--ion-font-family",
	// 			'"Nunito", sans-serif'
	// 		);
	// 	} else {
	// 		document.documentElement.style.setProperty(
	// 			"--ion-font-family",
	// 			"hello"
	// 		);
	// 	}
	// }, [language]);

	return (
		<div>
			<MainRoutes />
		</div>
	);
}

export default App;
