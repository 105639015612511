import {
	IonApp,
	IonContent,
	IonHeader,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Customer from "components/mobile-components/CustomerList/Customer";
import CustomerSearch from "components/mobile-components/CustomerList/CustomerSearch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Business from "services/Business";
import BottomSheet from "components/mobile-components/MyCustomer/BottomSheet";
import SupplierList from "components/mobile-components/SupplierList";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";

export default function SelectSupplierPage() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [supplierList, setSupplierList] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedSupplier, setSelectedSupplier] = useState("");
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [tempSearchParams, setTempSearchParams] = useState("");
	const location = useSelector((state) => state.user.locationData);

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	async function allSupplierList() {
		setLoading(true);
		setPageCount(2);
		if (searchQ) {
			const response = await Business.getNearbySupplier(
				location.coordinates[1],
				location.coordinates[0],
				searchQ
			);
			if (response.status === 200) {
				setLoading(false);
				setSupplierList(response.results);
			} else {
				setLoading(false);
			}
		} else {
			try {
				const response = await Business.getNearbySuppliers(
					location.coordinates[1],
					location.coordinates[0]
				);
				if (response.status === 200) {
					setLoading(false);
					setSupplierList(response.results);
				} else {
					setLoading(false);
				}
			} catch (error) {
				console.log(error);
			}
		}
	}

	async function fetchSupplier(s) {
		setTempSearchParams(s);
		try {
			const response = await Business.getNearbySupplier(
				location.coordinates[1],
				location.coordinates[0],
				s
			);
			if (response.status === 200) {
				setLoading(false);
				setSupplierList(response.results);
			} else {
				setLoading(false);
				setSupplierList([]);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// useEffect(() => {
	// 	// allCustomerList()
	// 	myCustomerList();
	// }, []);

	useEffect(() => {
		if (searchQ === "") {
			setTempSearchParams("");
			allSupplierList();
		}
	}, [searchQ]);

	let lodeMoreData = async () => {
		if (searchQ) {
			const response = await Business.getNearbySupplier(
				location.coordinates[1],
				location.coordinates[0],
				searchQ,
				pageCount
			);
			if (response.status === 200) {
				setPageCount((prev) => prev + 1);
				setSupplierList([...supplierList, ...response.results]);
			} else {
				setLoading(false);
			}
		} else {
			const response = await Business.getNearbySuppliers(
				location.coordinates[1],
				location.coordinates[0],
				pageCount
			);
			if (response.status === 200) {
				setPageCount((prev) => prev + 1);
				setSupplierList([...supplierList, ...response.results]);
			} else {
				setLoading(false);
			}
		}
	};
	useEffect(() => {
		allSupplierList();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonHeader>
					<BackButtonTitle title={ekkLanguage.supplierList.pageTitle} />
				</IonHeader>
				<IonContent>
					<section className='pt-14'>
						<CustomerSearch
							setSearchQ={setSearchQ}
							searchQ={searchQ}
							fetchCustomer={fetchSupplier}
							placeholder={ekkLanguage.supplierList.searchPlaceHolder}
						/>
						<p className='px-4 pt-2'>
							{" "}
							{tempSearchParams && (
								<span>
									search:{" "}
									<span className='font-bold'>
										{" "}
										{tempSearchParams}
									</span>
								</span>
							)}
						</p>

						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.businessLocation.loader}
							/>
						) : (
							<>
								<SupplierList
									supplierList={supplierList}
									selectedSupplier={selectedSupplier}
									setSelectedSupplier={setSelectedSupplier}
								/>
							</>
						)}
					</section>

					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							lodeMoreData();
							setTimeout(() => ev.target.complete(), 500);
						}}>
						<IonInfiniteScrollContent></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
