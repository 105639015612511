import React from "react";
import Card from "../Common/Card";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import { shoppingListItem } from "helpers/shoppingCart";

export default function CostSummaryCard() {
	const productCart = useSelector((state) => state.cart);
	const creditfee = useSelector((state) => state.payment.creditFee);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const productList = shoppingListItem();

	return (
		<div className='px-2 pt-5 pb-24'>
			<Card title={ekkLanguage.createOrder.costSummaryTitle}>
				<div className='px-2'>
					<table className='table-fixed w-full'>
						<tbody>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.subtotal}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.country &&
										productList[0]?.country
											?.currency_symbol_native}{" "}
									{digitChanger(productCart?.subtotal?.toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.tax}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.country &&
										productList[0]?.country
											?.currency_symbol_native}{" "}
									{digitChanger(
										Number(productCart?.totalTax).toFixed(2)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.discount}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.country &&
										productList[0]?.country
											?.currency_symbol_native}{" "}
									{digitChanger(Number(0).toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.deliveryFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.country &&
										productList[0]?.country
											?.currency_symbol_native}{" "}
									{digitChanger(Number(0).toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.creditFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.country &&
										productList[0]?.country
											?.currency_symbol_native}{" "}
									{digitChanger(creditfee?.toFixed(2))}
								</td>
							</tr>
						</tbody>
						<tfoot className='border-t'>
							<tr>
								<td className='text-primary text-12 font-bold py-[7px] p-0'>
									{ekkLanguage.createOrder.total}
								</td>
								<td className='text-primary text-12 font-bold py-[7px] p-0 text-right'>
									{productList[0]?.country &&
										productList[0]?.country
											?.currency_symbol_native}{" "}
									{digitChanger(
										Number(
											productCart?.total + productCart?.totalTax
										).toFixed(2)
									)}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</Card>
		</div>
	);
}
