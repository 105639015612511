import { IonCheckbox, IonCol, IonGrid, IonRow } from "@ionic/react";
import { getProductSeller, setProductSeller } from "config/user";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Customer({ customerList, setSelectedCustomer }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let navigate = useNavigate();

	return (
		<IonGrid>
			<IonRow>
				{customerList.length ? (
					customerList.map((el, i) => {
						return (
							<IonCol
								size='6'
								key={i}
								onClick={() => {
									setSelectedCustomer(el);
									setProductSeller(el);
									navigate("/create-order");
								}}>
								<div
									className='bg-white rounded-[10px] px-[10px] py-[10px]'
									style={{
										boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)",
									}}>
									<span
										className='absolute'
										style={{ top: 5, right: 5 }}>
										{el?.id === getProductSeller()?.id && (
											<IonCheckbox
												color='success'
												className='rounded select-product'
												checked={true}
											/>
										)}
									</span>
									<h5 className='font-bold text-14 text-black-1000 pb-[5px] h-12'>
										{el?.related_business}
									</h5>
									<p className='font-normal text-12 text-black-500 h-10'>
										{el?.related_business_address}
									</p>
									<p className='flex '>
										<img src='assets/images/pin.png' alt='pin' />
										<span
											className='text-10 font-bold ml-1'
											style={{ color: "#FF261A" }}>
											{el?.distance} km
										</span>
									</p>
								</div>
							</IonCol>
						);
					})
				) : (
					<>
						<IonCol size='2'></IonCol>
						<IonCol size='8'>
							<div
								className='text-center flex items-center justify-center'
								style={{ height: "calc(100vh - 420px)" }}>
								<div>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-16 font-normal text-222222 mb-[35px]'>
										{ekkLanguage.orderList.notFound}
									</p>
								</div>
							</div>
						</IonCol>
					</>
				)}
			</IonRow>
		</IonGrid>
	);
}
