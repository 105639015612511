import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Banner({ slideData, serverImsge }) {
	return (
		<div className='banner-wrapper bg-white'>
			{serverImsge ? (
				<Swiper
					modules={[Pagination, Navigation]}
					pagination={{ clickable: true }}
					navigation={true}
					spaceBetween={0}
					slidesPerView={1}
					// onSlideChange={() => console.log("slide change")}
					// onSwiper={(swiper) => console.log(swiper)}
				>
					{slideData.map((el, i) => {
						return (
							<SwiperSlide key={i}>
								<img
									src={el}
									className='w-100 m-auto'
									alt='slideImage'
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			) : (
				<img src={slideData[0]} alt='bannerimage' />
			)}
		</div>
	);
}
