import Add from "assets/images/icon/svg/Add";
import Location from "assets/images/icon/svg/Location";
import Shop from "assets/images/icon/svg/Shop";
import { getProductSeller } from "config/user";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../Common/Card";

export default function CustomerDetailsCard() {
	const sellerInfo = useSelector((state) => state.seller?.data);
	const multiSupplier = useSelector((state) => state.seller?.multiSupplier);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let ps = getProductSeller();
	let navigate = useNavigate();

	return (
		<div className='px-2 pb-4'>
			{multiSupplier ? (
				<Card
					title={ekkLanguage.createOrder.buyerDetailsTitle}
					addSeller={ps ? true : false}
					to={Object.keys(sellerInfo).length ? "/customers-list" : "#"}>
					{ps ? (
						<div className='pt-4 px-2'>
							<h4 className='text-success text-14 font-bold pb-3'>
								{ps?.related_business}
							</h4>

							<div className='block rounded-md'>
								<div className='flex justify-between items-center'>
									<div className='flex'>
										<span>
											<Location />
										</span>
										<p className='text-12 text-000080 ml-2'>
											{ps?.related_business_address}
										</p>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div
							className={`bg-F3F3F3 p-3 block rounded-[10px] mt-4 border example-custom-input border-primary`}
							onClick={() =>
								navigate(
									Object.keys(sellerInfo).length
										? "/customers-list"
										: "#"
								)
							}
							style={
								Object.keys(sellerInfo).length
									? {
											opacity: 1,
											boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
									  }
									: {
											opacity: 0.3,
											boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
									  }
							}>
							<div className='flex justify-between items-center'>
								<div className='flex items-center'>
									<span>
										<Shop color={"#F94B41"} />
									</span>
									<p className={`text-12 text-primary ml-2`}>
										Select a Customer
									</p>
								</div>
								<div>
									<Add />
								</div>
							</div>
						</div>
					)}
				</Card>
			) : (
				<Card
					title={ekkLanguage.createOrder.buyerDetailsTitle}
					addSeller={ps ? true : false}
					to='/customers-list'>
					{ps ? (
						<div className='pt-4 px-2'>
							<h4 className='text-success text-14 font-bold pb-3'>
								{ps?.related_business}
							</h4>

							<div className='block rounded-md'>
								<div className='flex justify-between items-center'>
									<div className='flex'>
										<span>
											<Location />
										</span>
										<p className='text-12 text-000080 ml-2'>
											{ps?.related_business_address}
										</p>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div
							className={`bg-F3F3F3 p-3 block rounded-[10px] mt-4 border example-custom-input border-primary`}
							onClick={() => navigate("/customers-list")}
							style={{
								boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
							}}>
							<div className='flex justify-between items-center'>
								<div className='flex items-center'>
									<span>
										<Shop color={"#F94B41"} />
									</span>
									<p className={`text-12 text-primary ml-2`}>
										Select a Customer
									</p>
								</div>
								<div>
									<Add />
								</div>
							</div>
						</div>
					)}
				</Card>
			)}
		</div>
	);
}
