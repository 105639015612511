import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import { digitChanger, translator } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOrderSingle } from "redux/container/orderSlice";

export default function ReceiptPage() {
	let { id } = useParams();
	const dispatch = useDispatch();
	const printAriaRef = useRef();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const response = useSelector((state) => state.order?.singleOrder);
	useEffect(() => {
		dispatch(fetchOrderSingle(id));
	}, []);

	return (
		<IonApp>
			<IonPage
				className='business-name mx-auto'
				id='ok'
				style={{ maxWidth: 220, width: "100%" }}>
				<IonContent>
					<div className='bg-white'>
						<div className='intro-y flex flex-col sm:flex-row justify-end items-center mt-8'>
							<div className='w-full sm:w-auto flex mt-4 sm:mt-0 pr-3'>
								<IonButton
									className='bg-primary rounded-[7px] font-extrabold text-12 h-10'
									onClick={() => window.print()}>
									Print
								</IonButton>
							</div>
						</div>
						{/* BEGIN: Invoice */}
						<div
							className='intro-y box overflow-hidden text-center m-auto px-4 pt-10'
							ref={printAriaRef}
							style={{ width: 219 }}>
							<div className='text-center bottom-1 border-b border-dashed pb-[5px] border-black-500'>
								<p className='text-black-1000 text-10 font-bold pb-[2px]'>
									{response.seller.business_name}{" "}
									{digitChanger(response.seller.phone_number)}
								</p>

								<p className='text-black-1000 text-8 font-normal'>
									{response?.seller?.address_line}
								</p>
							</div>
							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between pb-[2px]'>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(response?.order_id)}
									</p>
									<p className='text-black-1000 text-8 font-bold'>
										{ekkLanguage.receipt.placed}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(
											moment(response?.createdAt).format(
												"DD-MM-YYYY"
											)
										)}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{response?.created_by_user?.DisplayName}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(
											moment(response?.createdAt).format("HH:MM:SS")
										)}
									</p>
								</div>
							</div>

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='text-center pb-[2px]'>
									<h6 className='text-black-1000 text-12 font-bold pb-[2px]'>
										{response?.buyer?.business_name}
										{digitChanger(response?.buyer?.phone_number)}
									</h6>
									<p className='text-black-1000 text-8 font-normal'>
										{response?.buyer?.address_line}
									</p>
								</div>
							</div>
							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='pb-[2px]'>
									<div className='flex justify-between pb-[2px]'>
										<p
											className='text-black-1000 text-10 font-bold text-left'
											style={{ width: 108 }}>
											{ekkLanguage.receipt.name}
										</p>
										<p
											className='text-black-1000 text-10 font-bold'
											style={{ width: 20 }}>
											{ekkLanguage.receipt.qty}
										</p>
										<p
											className='text-black-1000 text-10 font-bold text-right'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.price}
										</p>
									</div>
									{response?.order_items?.map((el, i) => {
										return (
											<div
												className='flex justify-between pb-[2px]'
												key={i}>
												<p
													className='text-black-1000 text-8 font-normal text-left'
													style={{ width: 108 }}>
													{el?.catalog?.product?.title}
												</p>
												<p
													className='text-black-1000 text-8 font-normal'
													style={{ width: 20 }}>
													{digitChanger(el?.quantity)}
												</p>
												<p
													className='text-black-1000 text-8 font-normal text-right'
													style={{ width: 50 }}>
													{digitChanger(
														Number(
															el?.quantity * el?.unit_price
														).toFixed(2)
													)}
												</p>
											</div>
										);
									})}
								</div>
							</div>
							<div className='bottom-1 border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='text-center pb-[2px]'>
									<div className='flex justify-between pb-[2px]'>
										<p className='text-black-1000 text-10 font-bold text-left'>
											{ekkLanguage.receipt.subtotal}
										</p>
										<p className='text-black-1000 text-10 font-bold text-right'>
											{digitChanger(
												Number(response?.sub_total).toFixed(2)
											)}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.tax}
										</p>
										<p className='text-black-1000 text-8 font-normal text-left'>
											{digitChanger(
												Number(response?.tax_total).toFixed(2)
											)}
										</p>
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(
												Number(response?.tax_total).toFixed(2)
											)}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.discount}
										</p>
										{/* <p className='text-black-1000 text-8 font-normal text-left'>
											05%
										</p> */}
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(Number(0).toFixed(2))}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.creditFee}
										</p>
										<p className='text-black-1000 text-8 font-normal text-left'>
											{digitChanger(
												Number(
													response?.payment_term?.creditFee
												).toFixed(2)
											)}
										</p>
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(
												Number(
													response?.payment_term?.creditFee
												).toFixed(2)
											)}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.deliveryFee}
										</p>
										{/* <p className='text-black-1000 text-8 font-normal text-left'>
											05%
										</p> */}
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(
												Number(response?.sub_total).toFixed(2)
											)}
										</p>
									</div>
								</div>
							</div>
							<hr className='border-b border-dashed  pt-[2px] border-black-500' />

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-bold'>
										{ekkLanguage.receipt.total}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(Number(response?.total).toFixed(2))}
									</p>
								</div>
							</div>

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.paid}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(
											Number(response?.total_paid).toFixed(2)
										)}
									</p>
								</div>
							</div>

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-bold'>
										{ekkLanguage.receipt.totalDue}
									</p>
									<p className='text-black-1000 text-10 font-bold'>
										{digitChanger(
											Number(
												response?.total - response?.total_paid
											).toFixed(2)
										)}
									</p>
								</div>
							</div>
							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.paymentTerms}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(response?.payment_term?.duration)}
										{ekkLanguage.receipt.days}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-bold'>
										{ekkLanguage.receipt.paymentMethod}
									</p>
									<p className='text-black-1000 text-10 font-bold'>
										{response?.payment_method}
									</p>
								</div>
								{response?.payment_method === "Bank Transfer" && (
									<div className='flex justify-between'>
										<p className='text-black-1000 text-8 font-bold'>
											{ekkLanguage.receipt.referenceNumber}
										</p>
										<p className='text-black-1000 text-10 font-bold'>
											{digitChanger(response?.order_id)}
										</p>
									</div>
								)}{" "}
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.deliveryDate}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(
											moment(
												response?.payment_term?.createdAt
											).format("DD-MM-YYYY")
										)}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.dueDate}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(
											moment(
												response?.payment_term?.paymentDueDate
											).format("DD-MM-YYYY")
										)}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.printTime}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(moment(Date.now()).format("HH:mm"))}
									</p>
								</div>
							</div>
							<div className='pt-[5px] border-black-500'>
								<div className='flex justify-center'>
									<p className='text-black-1000 text-8 font-bold'>
										{ekkLanguage.receipt.conditionsCreditFee}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.immediatePayment}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										No
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.cashOnDelivery}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										No
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.atNight}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										0.001%
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.payInthreeDay}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										0.005%
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.payInSevenDay}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										0.01%
									</p>
								</div>
								<div className='flex justify-between'>
									<p
										className='text-black-1000 text-10 font-normal text-left'
										style={{ width: "58%" }}>
										{ekkLanguage.receipt.afterSeven}
									</p>
									<p className='text-black-1000 text-10 font-normal text-right'>
										0.01% + 0.002%
									</p>
								</div>
							</div>
						</div>
						{/* END: Invoice */}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
