import React from "react";

export default function Shop() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<g clipPath='url(#clip0_1_266)'>
				<path
					d='M14.25 12.75C13.4599 12.7497 12.6838 12.5412 12 12.1455C11.3162 12.5412 10.5401 12.7497 9.75 12.75H8.25C7.45882 12.749 6.68219 12.5372 6 12.1365C5.31784 12.5373 4.54119 12.749 3.75 12.75H3C2.20922 12.7497 1.43271 12.5393 0.75 12.1402V15.75C0.75 16.3467 0.987053 16.919 1.40901 17.341C1.83097 17.7629 2.40326 18 3 18H15C15.5967 18 16.169 17.7629 16.591 17.341C17.0129 16.919 17.25 16.3467 17.25 15.75V12.141C16.5671 12.5395 15.7907 12.7496 15 12.75H14.25Z'
					fill='#F94B41'
				/>
				<path
					d='M12.75 0V4.5H11.25V0H6.75V4.5H5.25V0H1.65L0.018 7.33725L0 8.25C0 9.04565 0.316071 9.80871 0.87868 10.3713C1.44129 10.9339 2.20435 11.25 3 11.25H3.75C4.17742 11.2486 4.59955 11.1554 4.98776 10.9765C5.37597 10.7977 5.72118 10.5375 6 10.2135C6.27882 10.5375 6.62403 10.7977 7.01224 10.9765C7.40045 11.1554 7.82258 11.2486 8.25 11.25H9.75C10.1759 11.2502 10.5969 11.1596 10.9849 10.984C11.3729 10.8085 11.719 10.5522 12 10.2323C12.281 10.5522 12.6271 10.8085 13.0151 10.984C13.4031 11.1596 13.8241 11.2502 14.25 11.25H15C15.7956 11.25 16.5587 10.9339 17.1213 10.3713C17.6839 9.80871 18 9.04565 18 8.25V7.5L16.35 0H12.75Z'
					fill='#F94B41'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1_266'>
					<rect width='18' height='18' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
