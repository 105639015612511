import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ProductList from "components/mobile-components/products/ProductList";
import ProductSearch from "components/mobile-components/SelectProduct/ProductSearch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Brand from "services/Brand";
import Product from "services/Product";
import Supplier from "services/Supplier";

export default function SeeMoreProductPage() {
	let { category } = useParams();
	const [brandData, setBrandData] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(true);
	let [searchParams] = useSearchParams();

	let title;
	let settings;
	let link;

	//get offer productList
	let getOfferList = async () => {
		setLoading(true);
		let response = await Product.offerProduct();
		if (response.status === 200) {
			setLoading(false);
			setBrandData(response.results);
		} else {
			setLoading(false);
		}
	};

	//get brand productList
	let getBrandList = async () => {
		setLoading(true);
		let response = await Brand.getBrandList();
		if (response.status === 200) {
			setBrandData(response.results);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};
	//get suppliers data
	let getSupplierList = async () => {
		setLoading(true);
		let response = await Supplier.getSupplierList();
		if (response.status === 200) {
			setLoading(false);
			setBrandData(response.results);
		} else {
			setLoading(false);
		}
	};
	//get selected suppliers data
	let getSelectedSupplierList = async () => {
		setLoading(true);
		let response = await Supplier.getSelectedSupplierList(
			searchParams.get("sellerId")
		);
		if (response.status === 200) {
			setLoading(false);
			setBrandData(response.data);
		} else {
			setLoading(false);
		}
	};
	//get suppliers data
	let getMySupplierList = async () => {
		let response = await Supplier.getMySupplierList();

		if (response.status === 200) {
			const supplier = [];
			response.data.forEach((element) => {
				supplier.push(element.related_business);
			});
			setBrandData(supplier);
		} else {
		}
	};

	useEffect(() => {
		if (category === "brand") {
			getBrandList();
		} else if (category === "supplier" && searchParams.get("sellerId")) {
			getSelectedSupplierList();
		} else if (category === "supplier") {
			getSupplierList();
		} else if (category === "mysupplier") {
			getMySupplierList();
		} else if (category === "offer") {
			getOfferList();
		}
	}, []);

	if (category === "brand") {
		title = ekkLanguage.home.brandProductPageTitle;
		link = "brand-details";
		settings = {
			nameShow: true,
			skuShow: true,
			priceShow: false,
			oldPriceShow: false,
			distShow: false,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: false,
			newPriceShow: false,
			defaultImage: "/assets/images/badge_default.png",
		};
	} else if (category === "offer") {
		title = ekkLanguage.home.offerProductPageTitle;
		link = "product-details";
		settings = {
			nameShow: true,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: false,
			profitShow: true,
			sellPriceShow: false,
			supplierNameShow: false,
			newPriceShow: false,
			defaultImage: "/assets/images/offer_default.png",
			showOfferPrice: true,
			type: "offer",
			productType: "offers",
			emptyText: "Sorry, No offers were found for you.",
		};
	} else if (category === "featured") {
		title = "Featured Products";
	} else if (category === "supplier" && searchParams.get("sellerId")) {
		title = ekkLanguage.home.nearSuppliersLabel;
		link = "supplier-details";
		settings = {
			nameShow: false,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: true,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: true,
			newPriceShow: false,
			defaultImage: "/assets/images/supplier_default.png",
		};
	} else if (category === "supplier") {
		title = ekkLanguage.home.nearSuppliersLabel;
		link = "supplier-details";
		settings = {
			nameShow: false,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: true,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: true,
			newPriceShow: false,
			defaultImage: "/assets/images/supplier_default.png",
		};
	} else if (category === "mysupplier") {
		title = ekkLanguage.home.mySuppliersLabel;
		link = "mysupplier-details";
		settings = {
			nameShow: false,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: true,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: true,
			newPriceShow: false,
			defaultImage: "/assets/images/supplier_default.png",
			emptyText: "Sorry, No suppliers found nearby.",
		};
	} else if (category === "popular") {
		title = "Popular Products";
	}
	let lodeMoreData = async () => {
		if (category === "brand") {
			let response = await Brand.getBrandList(pageCount);
			if (response.status === 200) {
				if (response.results.length > 0) {
					setPageCount((prev) => prev + 1);
					setBrandData([...brandData, ...response.results]);
				}
			}
		} else if (category === "supplier") {
			let response = await Supplier.getSupplierList(pageCount);
			if (response.status === 200) {
				if (response.data.length > 0) {
					setPageCount((prev) => prev + 1);
					setBrandData([...brandData, ...response.data]);
				}
			}
		}
	};
	async function searchNext(event) {
		let timeout = setTimeout(() => {
			lodeMoreData();
			// event.target.complete();
			clearTimeout(timeout);
		}, 500);
	}
	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					onIonScrollEnd={(ev) => {
						searchNext(ev);
					}}>
					<BackButtonTitle title={title} />
					<section className='pt-14'>
						<ProductSearch />
						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.orderList.loader}
							/>
						) : (
							<ProductList
								productData={brandData}
								category={category}
								settings={settings}
								link={link}
							/>
						)}
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
