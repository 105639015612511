import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import SearchIcon from "assets/images/icon/svg/SearchIcon";
import React from "react";
import { useSelector } from "react-redux";

export default function CustomerSearch({
	searchQ,
	setSearchQ,
	fetchCustomer,
	placeHolder,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const deleteQueryHandler = () => {
		setSearchQ("");
	};
	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12' className='relative'>
					<div className='relative'>
						<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
							<SearchIcon color='rgba(0,0,0,.6)' />
						</span>
						<input
							className='bg-white w-full h-11 rounded-[7px] pl-12 search-input border'
							placeholder={placeHolder}
							style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
							value={searchQ}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									fetchCustomer();
								}
							}}
							onChange={(e) => {
								setSearchQ(e.target.value);
							}}></input>
						{searchQ && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
