import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import React from "react";
import { useDropzone } from "react-dropzone";

export default function ImageUploader({
	files,
	setFiles,
	bHeight,
	placeHolder,
}) {
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	return (
		<>
			{files?.length ? (
				<div
					style={{ width: "100%", height: bHeight }}
					className='flex justify-center items-center bg-E6E6E6 relative overflow-hidden'>
					<div
						style={{ height: bHeight }}
						className='text-center bg-white'>
						<img
							style={{ height: bHeight }}
							src={files[0].preview}
							alt='addImage'
							className='m-auto mb-2 object-contain'
						/>
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<div className='absolute z-10 bottom-4 right-4 cursor-pointer'>
								<EditBusinessProfile />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div
					style={{ width: "100%", height: bHeight }}
					className='flex justify-center items-center bg-E6E6E6'>
					<div
						className='text-center bg-white py-6 px-6 rounded-[5px] cursor-pointer'
						style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<img
								src='assets/images/add-image.png'
								alt='addImage'
								className='m-auto mb-2'
							/>
							<p className='text-16 font-normal text-black-500'>
								{placeHolder}
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
