import Add from "assets/images/icon/svg/Add";
import Close from "assets/images/icon/svg/Close";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Card({
	title,
	children,
	onHide,
	closeModal,
	addSeller,
	to,
}) {
	let navigate = useNavigate();

	return (
		<div
			className='bg-white rounded-[10px] py-5'
			style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)" }}>
			<div
				className='border-b-2 pb-3 flex justify-between items-center px-4'
				style={{ bordreColor: "#f2f3f7" }}>
				<h3
					className='text-222222 font-bold text-14 '
					// onClick={() => console.log("asof")}
				>
					{title}
				</h3>

				{onHide && (
					<span className='block' onClick={closeModal}>
						<Close />
					</span>
				)}
				{addSeller && (
					<span className='block' onClick={() => navigate(to)}>
						<Add />
					</span>
				)}
			</div>
			<div className='px-[10px]'>{children}</div>
		</div>
	);
}
