import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import Home from "pages/MobilePage/Home";
import ProductDetailsPage from "pages/MobilePage/ProductDetailsPage";
import CreateOrder from "pages/MobilePage/CreateOrder";
import Map from "pages/MobilePage/Map";
import CongratulationPage from "pages/MobilePage/CongratulationPage";
import SelectProductPage from "pages/MobilePage/SelectProductPage";
import OrderList from "pages/MobilePage/OrderList";
import OrderDetailsPage from "pages/MobilePage/OrderDetailsPage";
import BrandDetailsPage from "pages/MobilePage/BrandDetailsPage";
import SuppliersDetailsPage from "pages/MobilePage/SuppliersDetailsPage";
import BusinessLocationPage from "pages/MobilePage/BusinessLocationPage";
import SelectBusiness from "pages/MobilePage/SelectBusiness";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ContactPage from "pages/MobilePage/ContactPage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import SeeMoreProductPage from "pages/MobilePage/SeeMoreProductPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import LocationPage from "pages/MobilePage/LocationPage";
import SearchPage from "pages/MobilePage/SearchPage";
import MySuppliersDetailsPage from "pages/MobilePage/MySuppliersDetailsPage";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import Error from "pages/MobilePage/Error";
import MySupplierPage from "pages/MobilePage/MySupplierPage";
import BusinessProfilePage from "pages/MobilePage/BusinessProfilePage";
import EditBusinessPage from "pages/MobilePage/EditBusinessPage";
import Invoice from "pages/MobilePage/Invoice";
import VBTradLicensePage from "pages/MobilePage/VBTradLicensePage";
import VBNidPage from "pages/MobilePage/VBNid";
import VBPropertyPage from "pages/MobilePage/VBPropertyPage";
import VBRentalPage from "pages/MobilePage/VBRentalPage";
import ReceiptPage from "pages/MobilePage/ReceiptPage";
import BusinessLocationPickerPage from "pages/MobilePage/BusinessLocationPicker";
import CustomerListPage from "pages/MobilePage/CustomerListPage";
import MyProductsPage from "pages/MobilePage/MyProductsPage";
import MyCustomerPage from "pages/MobilePage/MyCustomerPage";
import CreateNewCustomerPage from "pages/MobilePage/CreateNewCustomerPage";
import PickLocationPage from "pages/MobilePage/PickLocation";
import OrderSystemPage from "pages/MobilePage/OrderSystemPage";
import SellerListPage from "pages/MobilePage/SellerListPage";
import SelectSupplierPage from "pages/MobilePage/SelectSupplierPage";
import AutoLogoutPage from "pages/MobilePage/AutoLogoutPage";
import BlackListedPage from "pages/MobilePage/BlackListedPage";
import PhoneNumberCheck from "pages/LandingPageV2/PhoneNumberCheck";
import PasswordV2 from "pages/LandingPageV2/PasswordV2";

function Mobile() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <h6 className="font-bold fixed bottom-0 left-0 py-1.5 w-full text-center text-primary bg-E6E6E6 z-[1000]">
          No Internet Connected
        </h6>
      )}
      <Routes>
        <Route path="/*" element={<PrivateRoutes />}>
          <Route path="home" element={<Home />} />
          <Route path="order-list" element={<OrderList />} />
          <Route path="product-details/:id" element={<ProductDetailsPage />} />
          <Route path="product-list" element={<MyProductsPage />} />
          <Route path="my-customer" element={<MyCustomerPage />} />
          <Route
            path="product"
            element={
              {
                /* <Home /> */
              }
            }
          />
          <Route path="create-order" element={<CreateOrder />} />
          <Route
            path="create-customer/:to"
            element={<CreateNewCustomerPage />}
          />
          <Route path="location" element={<Map />} />
          <Route path="success-order/:id" element={<CongratulationPage />} />
          <Route path="select-product" element={<SelectProductPage />} />
          <Route path="order-details/:id" element={<OrderDetailsPage />} />
          <Route path="brand-details/:id" element={<BrandDetailsPage />} />
          <Route
            path="supplier-details/:id"
            element={<SuppliersDetailsPage />}
          />
          <Route
            path="mysupplier-details/:id"
            element={<MySuppliersDetailsPage />}
          />
          {/* <Route path='business-name' element={<BusinessNamePage />} />
				<Route path='business-type' element={<BusinessTypePage />} /> */}
          <Route path="select-business" element={<SelectBusiness />} />
          <Route path="business-location" element={<BusinessLocationPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />
          <Route
            path="changed-password-success"
            element={<ChangePasswordSuccessPage />}
          />
          <Route path="products/:category" element={<SeeMoreProductPage />} />
          <Route path="otp-send" element={<ProfileVarifyPage />} />
          <Route path="otp-submit" element={<ProfileVarifyOtpPage />} />
          <Route path="change-language" element={<ChangeLanguagePage />} />
          <Route path="location-enabled" element={<LocationPage />} />
          <Route path="search-page/:tarm" element={<SearchPage />} />
          <Route path="mysupplier" element={<MySupplierPage />} />
          <Route
            path="business-profile-settings"
            element={<BusinessProfilePage />}
          />
          <Route path="business-edit" element={<EditBusinessPage />} />
          <Route path="vbtrade-license" element={<VBTradLicensePage />} />
          <Route path="vbnid" element={<VBNidPage />} />
          <Route path="vbproperty" element={<VBPropertyPage />} />
          <Route path="vbrental-document" element={<VBRentalPage />} />
          <Route path="invoice/:id" element={<Invoice />} />
          <Route path="receipt/:id" element={<ReceiptPage />} />
          <Route
            path="search-location"
            element={<BusinessLocationPickerPage />}
          />
          <Route path="pick-location/:to" element={<PickLocationPage />} />
          <Route path="customers-list" element={<CustomerListPage />} />
          <Route path="order-system" element={<OrderSystemPage />} />
          <Route path="supplier-list" element={<SellerListPage />} />
          <Route path="select-supplier" element={<SelectSupplierPage />} />

          <Route path="*" element={<Error />} />
        </Route>

        <Route path="/" element={<PhoneNumberCheck />} />
        <Route path="/password" element={<PasswordV2 />} />
        <Route path="/auto-log-out" element={<AutoLogoutPage />} />
        <Route path="/black-listed" element={<BlackListedPage />} />
      </Routes>
    </>
  );
}

export default Mobile;
