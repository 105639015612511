import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
	name: "customer",
	initialState: {
		data: false,
	},
	reducers: {
		setCustomer: (state, action) => {
			state.data = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = customerSlice;

export const setCustomer = (values) => async (dispatch, getState) => {
	dispatch(actions.setCustomer(values));
};

// Export the reducer, either as a default or named export
export default reducer;
