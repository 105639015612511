const english = {
	login: {
		contryLabel: "COUNTRY",
		contryPlaceHolder: "",
		phoneLable: "PHONE NUMBER",
		phonePlaceHolder: "PHONE NUMBER",
		validationLabel:
			"Phone number not valid, Please write phone no in english",
		btnLabel: "Continue",
		tokenSuccessLabel: "Your token has been sent to your phone",
		tokenSuccessFailed: "Your token not create yet please try again",
		langChange: "Change language to",
		notFound:
			"Contact your business administrator to create an account for you.",
	},
	password: {
		passwordLabel: "PASSWORD",
		passwordPlaceHolder: "Enter your password",
		forgetPass: "Forgot Password",
		btnLabel: "Login",
		otpSendLabel: "Code has been sent to your mobile",
		backWord: "Go Back",
		passwordNotMatch: "Password not matched"
	},
	changePass: {
		passwordLabel: "NEW PASSWORD",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Password does not match",
		btnLabel: "Continue",
		successLabel: "Your password Changed successfully",
		errorLabel: "Your password Changed failed",
		lengthErrorLabel: "Password must be at least 4 characters long",
		notMatchLabel: "Your password and confirm password not match.",
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Enter your token",
		tokenResend: "Resend token",
		tokenSuccessLabel: "Code has been sent to your mobile, type to continue",
		tokenErrorLabel: "Token send failed",
		timerLabel: "Please wait",
		second: "second",
		btnLabel: "Continue",
		tokenNotMatch: "Token not match",
		tokenVarified: "Your token has been verified",
		tokenNotWrite: "Please enter your token",
	},
	registration: {
		nameLabel: "FULL NAME",
		namePlaceholder: "Enter your full name",
		emailLabel: "EMAIL",
		emailPlaceholder: "Enter your email",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		validationLabel: "Input field must be at least 4 characters long",
		passNotMatch: "Your password and confirm password not match.",
		btnLabel: "Continue",
		successMessage: "Profile Created Successfully",
		errorMessage: "Please check valid input",
		passLengthValidLabel: "Password must be at least 4 characters long",
	},
	letNav: {
		homeMenuLabel: "Home",
		orderMenuLabel: "My Order",
		mySupplierMenuLabel: "My Suppliers",
		supplierMenuLabel: "Suppliers",
		businessProfileMenuLabel: "Business Profile Settings",
		phoneCall: "Call To Order",
		myProducts: "My Products",
		myCustomer: "My Customer",
	},
	home: {
		showMoreLabel: "Show More",
		brandLabel: "Brand",
		offerLabel: "New Offer",
		mySuppliersLabel: "My Suppliers",
		nearSuppliersLabel: "Nearby Suppliers",
		recommendentLabel: "Just For You",
		searchLabel: "Search products",
		todayPurchaseLabel: "Today’s Purchase",
		totalOrderLabel: "Products from",
		totalOrder: "Orders",
		skusLabel: "SKUs",
		tillNow: "Till Now",
		orderDue: "Orders Due",
		brandProductPageTitle: "Brand Products",
		offerProductPageTitle: "Offer Products",
		offerProductNotFound: "Sorry, No offers were found for you.",
		mySupplierNotFound:
			"You dont have any suppliers. Please buy products to add your suppliers here.",
		brandSeller: "Suppliers",
	},
	profile: {
		pageTitle: "Profile & Settings",
		contactMenuLable: "User Information",
		passwordMenuLable: "Change Password",
		logoutMenuLable: "Signout",
		changeLanguageMenuLable: "Change Language",
	},
	changeLanguages: {
		pageTitle: "Select Language",
		btnLabel: "Save",
	},
	contact: {
		pageTitle: "User Information",
		nameLabel: "Name",
        namePlaceholder: "Enter your name",
		emailLabel: "Email",
		emailPlaceholder: "Enter your email",
		nidLabel: "NID",
		nidPlaceholder: "Enter NID number",
		btnLabel: "Save",
		successLabel: "Your profile Updated. successfully",
		currectEmail: "please enter current email address",
	},
	otpsend: {
		pageTitle: "Verify Number",
		btnLabel: "Send",
		message: "Tap to send authentication code to your mobile via SMS.",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
	},
	otpsubmit: {
		pageTitle: "Verify Number",
		btnLabel: "Submit",
		title: "Verification Code",
		paragraph: "We have sent the verification code to Your Mobile Number",
		otpLabel: "OTP",
		otpPlaceholder: "Enter your OTP code",
		receiveYetLabel: "Didn’t recieve code?",
		againRequestLabel: "Request again",
		timerLabel: "Please wait",
		second: "second",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
		tokenVarifiedMessage: "Your token has been verified",
		validationLabel: "Please submit your OTP",
	},
	changePassword: {
		pageTitle: "Change Password",
		btnLabel: "Submit",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		successLabel: "Your password Updated.",
		errorLabel: "Your password Updated Failed",
		passNotMatchLabel: "Your password and confirm password not match.",
		changePassSuccessPara: "Your password has been Changed Successfully.",
		changePassContinueBtn: "Continue",
	},
	brandDetails: {
		skusLabel: "SKUs",
		descriptionLabel: "Description",
		productTitleLabel: "This Brand Product",
	},
	mysuppliers: {
		skusLabel: "SKUs",
		productTitleLabel: "This Suppliers Product",
	},
	productDetails: {
		offerStart: "Offer Start Date",
		offerEnd: "Offer End Date",
		moqLabel: "MOQ",
		consumerLabel: "Consumer Price (MRP)",
		profitLabel: "Profit",
		productDetailTitle: "Product Details",
		expireData: "Expire Date",
		manufactureData: "Manufacture Date",
		productWeight: "Product Weight",
		packingType: "Packing Type",
		importedBy: "Imported By",
		manufacturedBy: "Manufactured By",
		country: "Country",
		descriptionLabel: "Description",
		btnLabel: "Order Now",
		inStock: "In stock",
		outStock: "Out stock",
		skuId: "SKU Id",
	},
	createOrder: {
		pageTitle: "Create Order",
		buyerDetailsTitle: "Buyer Details",
		sellerDetailsTitle: "Seller Details",
		deliveryDetailsTitle: "Delivery",
		productItemTitle: "Product Items",
		costSummaryTitle: "Cost Summary",
		paymentDetailsTitle: "Payment Details",
		paymentTermsTitle: "Payment Terms",
		paymentMethodeTitle: "Payment Method",
		moqLabel: "Minimum Order Quantity",
		pcsLabel: "Pcs",
		subtotal: "Subtotal",
		tax: "Tax / Vat",
		discount: "Discount",
		deliveryFee: "Delivery Fee",
		creditFee: "Service Fee",
		total: "Total",
		immediatePayment: "Immediate Payment",
		pickAndPay: "Pick And Pay",
		today: "At Night of Delivery Day",
		threeDays: "Pay in 3 days",
		sevenDays: "Pay in 7 days",
		immediatePaymentText: "No Service Fee applicable for this order.",
		todayPaymentText1: "For today payment, additional Service Fee of ",
		todayPaymentText2: "will be added to this order.",
		threePaymentText1: "For 3 days payment, additional Service Fee of ",
		threePaymentText2: "will be added to this order.",
		sevenPaymentText1: "For 7 days payment, additional Service Fee of ",
		sevenPaymentText2: " will be added to this order.",
		cashOnDelivery: "Cash On Delivery",
		cashOnDeliveryText1: "Pay Cash to",
		cashOnDeliveryText2: "when goods is delivered.",
		cash: "Cash",
		bkash: "Bkash",
		nagad: "Nagad",
		cashText: "Pay Cash to",
		bkashText: "Pay to Bkash",
		nagadText: "Pay to Nagad",
		bankTransfer: " Bank Transfer",
		bankTransferText1: "Bank Transfer - Pay to",
		bankTransferText2: "bank account with 'Order Number' as reference",
		bankTransferText3: "Bank Name",
		bankTransferText4: "A/C Name",
		bankTransferText5: "A/C Number",
		bankTransferText6: "Route Number",
		bankTransferText7: "Branch",
		btnLabel: "Confirm",
		addMoreProductLabel: "Add More Product",
		addMoreProduct: "Add Product",
		selectProductLabel: "Submit",
		cosgressTitle: "Congratulations !",
		cosgressPara: "Your order has been completed.",
		congressCancelText:
			"If there is any problem with the order, cancel it before at 12 pm. You cannot cancel the order after at 12 pm.",
		congressProductLabel: "Continue",
		orderNow: "Order Now",
		alert: "Alert",
		importentMessage: "Important message",
		deliveryDateMessage: "Please Select Delivery Date",
		paymentTermsMessage: "Please Select Payment Term",
		paymentMethodMessage: "Please Select Payment Method",
		backButtonMessage:
			"Your cart have some items, if you leave this page your cart will be empty.",
		okBtnLabel: "OK",
		loader: "Please wait...",
		placeConformationMessage: "Are you sure you want to place this order?",
		lessThenMoq:
			"Items Quantity selected is less then minimum or more then maximum order quantity.",
		all: "All",
		number: "Number",
		numberWarning:
			"If money is sent to any number other than this number, it will be considered as cancelled.",
		islamiBank: "Islami Bank",
		bankAsia: "Bank Asia",
		payTo: "Pay To",
		merchantNumber: "Merchant Number",
		outOffStock: "The product is out of stock",
		wrongProductCount: "Product selection number is wrong.",
	},
	selectProduct: {
		pageTitle: "Select Product",
		totalLabel: "Total",
		btnLabel: "Submit",
	},
	orderList: {
		pageTitle: "My Order List",
		tabMenuRecent: "Recent",
		tabMenuPlaced: "Placed",
		tabMenuAccepted: "Accepted",
		tabMenuScDelivered: "Scheduled For Delivery",
		tabMenuPartialDelivered: "Partial Delivered",
		tabMenuDelivered: "Delivered",
		tabMenuReturn: "Return Product",
		tabMenuPartialPaid: "Partial Paid",
		tabMenuPaid: "Paid",
		tabMenuCancel: "Cancel",
		tabMenuDeliveryFailed: "Delivery Failed",
		orderItem: "Order Item",
		seller: "Seller",
		buyer: "Buyer",
		loader: "Please wait...",
		createdBy: "Created By",
		to: "To",
		for: "For",
		notFound: "Not Found",
		assistantOrder: "Create assistant order",
	},
	orderDetails: {
		createdBy: "Created by",
		placedLabel: "Placed",
		deliveredLabel: "Delivered",
		paidLabel: "Paid",
		orderItemLabel: "Order Item",
		castItemLabel: "Cost Details",
		timelineLabel: "TimeLine",
		subtotal: "Subtotal",
		tax: "Tax / Vat",
		discount: "Discount",
		deliveryFee: "Delivery Fee",
		creditFee: "Service Fee",
		total: "Total",
		paymentSettlementLabel: "Payment Settlement",
		date: "Date",
		amount: "Amount",
		dueDate: "Due Date",
		remark: "Remark",
		collectedBy: "Collected by ",
		paymentDetailsLabel: "Payment Details",
		paymentMethod: "Payment Method",
		paymentTerms: "Payment Terms",
		paymentDueDate: "Payment Due Date",
		totalDue: "Total Due",
		totalPaid: "Total Paid",
		remaining: "Remaining",
		deliveryDetailsLabel: "Delivery Details",
		BuyerName: "Buyer’s Name",
		address: "Address",
		phoneNumber: "Phone Number",
		email: "Email",
		deliveryDate: "Delivery Date",
		downloadInvoices: "Download Invoice",
		receiptDownloads: "Print Receipt",
		cancelOrder: "Cancel Order",
		cancelConformationAsk: "Are you sure you want to Cancel this Order ?",
		cancelConformationParagraph: `You can not cancel this order, you can cancel your order only same day.`,
		yesBtn: "Yes",
		noBtn: "No",
		withoutSettelment: "Payments completed information will be shown here.",
		bankInfoLabel: "Bank Account Information for Payment Transfer",
		requestDeliveryDate: "Request Delivery Date",
		scheduleDeliveryDate: "Schedule Delivery Date",
		scheduleDeliveryTime: "Schedule Delivery Time",
		deliveryFailMessage: "Delivery Failed Message",
		assignedDriver: "Assigned Driver",
	},
	businessCreateName: {
		pageTitle: "Business Name",
		businessName: "Business Name",
		businessNamePlaceholder: "Enter Business Name",
		paragraph:
			"We are 3 steps away to create your business. Lets start with your business name.",
		btnLabel: "Next",
		submit: "Submit",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Enter business name!",
	},
	businessType: {
		pageTitle: "Business Type",
		paragraph: "Lets choose your Business type.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Super Mart",
		pharmacy: "Pharmacy",
		cart: "Cart",
		bazaarStore: "Bazaar Store",
		hawkerCenter: "Hawker Center",
		restaurant: "Restaurant",
		hotel: "Hotel",
		cosmeticsStore: "Cosmetics Store",
		nonFmcg: "NON FMCG",
		office: "Office",
		NGO: "NGO",
		entertainmentPlace: "Entertainment Place",
		religiousOrganization: "Religious Organization",
		spa: "Spa",
		salon: "Salon",
		gym: "Gym",
		electronicsStore: "Electronics Store",
		shoeStore: "Shoe Store",
		fashionStore: "Fashion Store",
		laundryStore: "Laundry Store",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Next",
		validationLabel: "Please Select a business type",
	},
	businessLocation: {
		pageTitle: "Business Location",
		paragraph: "Enter your business contact details.",
		address: "Address",
		addressPlaceholder: "Enter your address",
		city: "City",
		cityPlaceholder: "Enter city Name",
		postalCode: "Postal Code",
		postalCodePlaceholder: "Enter postal code",
		email: "Email",
		emailPlaceholder: "Enter your email",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "DONE",
		successLabel: "Congratulations business created",
		errorLabel: "Business creation failed",
		validationLabel: "Please fill up the address to move.",
		loader: "Please wait...",
		validationCityLabel: "Please fill up the city.",
		validationPostCodeLabel: "Please fill up postal code.",
		locationValidation: "Please pick location from map.",
	},
	locationPicker: {
		pageTitle: "Search Store Location",
		btnLabel: "CONFIRM",
		paragraph: `Please, Confirm your store address. If you have any
								trouble finding your store address, please find your
								store address by searching.`,
	},
	location: {
		title: "Enable your Location",
		paragraph:
			"This app requires that location services are turned on your device and for this app. You must enabel them is Setting before using this app.",
		btnLabel: "Allow only while using the app",
	},
	businessSelect: {
		pageTitle: "Select Business",
	},
	pwaDownload: {
		header: "Download EkkBaz App",
		paragraph:
			"Download the app and order bulk from your supplier any time from now on.",
		cancelBtn: "Cancel",
		downloadBtn: "Download",
	},
	businessProfile: {
		pageTitle: "Business Profile Settings",
		businessDetailsLabel: "Details",
		registrationNo: "Register Number",
		taxNo: "Tex/Vat Number",
		mobileNo: "Mobile Number",
		officeNo: "Office Number",
		email: "Email",
		website: "Website",
		branchLabel: "Branch List",
		paymentDetailsLabel: "Payment Details",
		bankInfoLabel: "Bank Information",
		bankName: "Bank Name",
		branchName: "Bank Branch",
		branchAddress: "Bank Address",
		swiftCode: "Bank Swift Code",
		acName: "Bank A/C Name",
		acNumber: "Bank A/C Number",
		bkashInfoLabel: "Bkash Information",
		bkashAcName: "Bkash A/C Name",
		bkashAcNo: "Bkash A/C Number",
		nagadInfoLabel: "Nagad Information",
		nagadAcName: "Nagad A/C Name",
		nagadAcNo: "Nagad A/C Number",
		upayInfoLabel: "Upay Information",
		upayAcName: "Upay A/C Name",
		upayAcNo: "Upay A/C Number",
		verified: "Verified",
		unVerified: "Unverified",
		editBusinessMenu: "Edit Business",
		addPayMenu: "Add Payment Option ",
		addMenu: "Add Branch",
		verifyBusinessMenu: "Verify Business",
		bankInfoNotFound: "Bank information not found.",
	},
	businessEdit: {
		pageTitle: "Edit Business ",
		uploadPhoto: "Upload Photo",
		email: "Email",
		emailPlaceholder: "Enter your email",
		address: "Address",
		addressPlaceholder: "Enter your address",
		city: "City",
		cityPlaceholder: "Enter city Name",
		postalCode: "Postal Code",
		postalCodePlaceholder: "Enter postal code",
		resigter: "Register Number",
		resigterPlaceholder: "Enter your Register Number",
		tex: "Tex/Vat Number",
		texPlaceholder: "Enter your Tex/Vat Number",
		mobile: "Mobile Number",
		mobilePlaceholder: "Enter your Mobile Number",
		office: "Office Number",
		officePlaceholder: "Enter your Office Number",
		website: "Website",
		websitePlaceholder: "Enter your website",
		btnLabel: "Save",
		successLabel: "Your profile Updated. successfully",
		currectEmail: "Please enter current email address",
	},
	bankDetails: {
		referenceNumber: "Reference Number",
		bankName: "Bank Name",
		accountName: "A/C Name",
		accountNumber: "A/C Number",
		routeNumber: "Route Number",
		branch: "Branch",
	},
	invoice: {
		title: "INVOICE",
		receipt: "Receipt",
		buyerDetails: "Buyer Details",
		sellerDetails: "Seller Details",
		description: "DESCRIPTION",
		qty: "QTY",
		price: "PRICE",
		subtotal: "SUBTOTAL",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "Placed",
		name: "Name",
		qty: "Qty",
		price: "Price",
		subtotal: "Subtotal",
		tax: "Tax",
		discount: "Discount",
		creditFee: "Service Fee",
		deliveryFee: "Delivery Fee",
		total: "Total",
		paid: "Paid",
		totalDue: "Total Due",
		paymentTerms: "Payment Terms",
		days: "Days",
		paymentMethod: "Payment Method",
		referenceNumber: "Bank Reference Number",
		deliveryDate: "Delivery Date",
		dueDate: "Payment Due Date",
		printTime: "Print Time",
		conditionsCreditFee: "Payment Conditions & Service Fee",
		immediatePayment: "Immediate Payment",
		cashOnDelivery: "Cash On Delivery",
		atNight: "At Night of Delivery Day",
		payInthreeDay: "Pay in 3 Days",
		payInSevenDay: "Pay in 7 Days",
		afterSeven: "After 7 Days (additional for everyday extra)",
	},
	landing: {
		header1: "Apply for EkkHero",
		header2: "Start Working",
		header3: "Earn Money! ",
		login: "Log in",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia:"Malay",
		retail: "Retail",
		manufacture: "Supplier",
		hotline: "Hot line",
		phoneNumber: "Phone Number",
		phoneNumberPlaceHolder: "Enter Phone Number",
		fAbout: "Wholesale market at your fingertip. Buy Now, Pay Later!",
		location: "Location",
		singapore: "Singapore Office",
		bangladesh: "Bangladesh Office",
		bOfficeAddress: "13th Floor, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Other info",
		partners: "Our Partners",
		news: "News",
		support: "Support",
		corporate: "Corporate",
		refundPolicy: "Refund Policy",
		termsCondition: "Terms Conditions",
		privacyPolicy: "Privacy Policy",
		contactUs: "Contact Us",
		messageUs: "Message Us",
		facebook: "Facebook",
		sales: "Sales",
		media: "Media",
		downloadBtn: "Download App",
		backers: "Our Backers",
		benefits: "Your Benefits",
		discover: "Latest EkkBaz News",
		benefitsOne: "24 hours on-time delivery",
		benefitsDisOne: "",
		benefitsTwo: "Best market prices",
		benefitsDisOneTwo: "",
		benefitsThree: "Largest SKUs assortments",
		benefitsDisOneThree: "",
		benefitsFore: "Financing supports for purchases",
		benefitsDisOneFore: "",
		seeMore: "See More",
	},
	customerList: {
		pageTitle: "Customer List",
		myCustomer: "My Customer",
		allCustomer: "All Customer",
		searchPlaceHolder: "Search Your Customer",
	},
	myCustomer: {
		pageTitle: "My Customer List",
		searchLabel: "Search Customer",
		createCustomer: "Create New Customer",
	},
	myProducts: {
		pageTitle: "My Products List",
	},
	newCustomer: {
		pageTitle: "Create New Customer",
		mobileNo: "Mobile Number",
		customerNoHolder: "customer mobile number",
		customerName: "Customer Name",
		customerNameHolder: "enter customer full name",
		businessPhoto: "Business Photo",
		uploadPhoto: "Upload Business/Store Photo",
		uploadPhotoTitle: "Upload Photo",
		uploadFromGallery: "Upload From Gallery",
		takePhoto: "Take Photo",
		businessName: "Business Name",
		businessNameHolder: "enter business/store name",
		address: "Address",
		addressHolder: "enter business/store address",
		city: "City",
		cityHolder: "enter city",
		postalCode: "Postal Code",
		postalCodeHolder: "enter postal code",
		selectBusiness: "Select Business Type",
		fmcg: "FMCG",
		nonFmcg: "NON-FMCG",
		nextBtn: "Next",
		completeBtn: "Complete",
		customerFindText: `Customer is already found here, if you want you can add it to “My Customer”. And if you don't want, you can create a new customer by clicking on the green button.`,
		addBusiness: "Add Business",
		addBusinessInfo: "Do you want to add this business as My Customer ?",
		noBtn: "No",
	},
	error: {
		warning: `You are not associated with any Business. 
Please contact your associated business administrator to add you as an employee to their business account.`,
		notFound: "No Content found",
		btn: "Back To Home page",
		logout: "Log Out",
	},
	supplierSelect: {
		pageTitle: "Order System",
		multiple: "Create order for multiple suppliers",
		specific: "Create order for specific suppliers",
		next: "Next",
		chooseText: "Choose any one below, the way you want to order",
	},
	supplierList: {
		pageTitle: "Supplier List",
		searchPlaceHolder: "Search Supplier",
		sellerChangeWarning:
			"If you change supplier all your previously selected products will be removed !",
		no: "No",
		yes: "Yes",
	},
	autoLogOut: {
		pageTitle: "Automatic Logout",
		warningMsg: "Auto logout since this number was used in another Login",
		instrMsg: "Click the green button below to login again",
		btnLabel: "Log in",
	  },
	  blackListed: {
		pageTitle: "Blacklisted",
		warningMsg: "Your Business is Blacklisted",
		instrMsg: "Please request your business owners to contact EkkBaz for necessary actions to re-enable your business.",
		btnLabel: "Log out",
	},
};
export default english;
