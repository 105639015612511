import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import { shoppingListItem } from "helpers/shoppingCart";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function AddMoreProduct() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const sellerInfo = useSelector((state) => state.seller?.data);
	const multiSupplier = useSelector((state) => state.seller?.multiSupplier);

	return (
		<>
			{shoppingListItem().length !== 0 && (
				<div className='px-[6px]'>
					<IonGrid>
						<IonRow>
							<IonCol size='5' className='p-0'></IonCol>
							<IonCol size='7' className='p-0'>
								<div>
									{multiSupplier ? (
										<Link
											to={
												Object.keys(sellerInfo).length
													? "/select-product"
													: "#"
											}
											className='custom-btn text-right block'>
											<IonButton className='h-10 w-full bg-white border border-primary rounded-[10px] font-bold text-12 text-primary'>
												{
													ekkLanguage.createOrder
														.addMoreProductLabel
												}
												<span className='pl-2'>
													<Add />
												</span>
											</IonButton>
										</Link>
									) : (
										<Link
											to='/select-product'
											className='custom-btn text-right block'>
											<IonButton className='h-10 w-full bg-white border border-primary rounded-[10px] font-bold text-12 text-primary'>
												{
													ekkLanguage.createOrder
														.addMoreProductLabel
												}
												<span className='pl-2'>
													<Add />
												</span>
											</IonButton>
										</Link>
									)}
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			)}
		</>
	);
}
