import {
	checkGetLocalUser,
	getLocalUser,
	setUserBusiness,
	setUser,
	setCreateUserBusiness,
	initBusiness,
} from "config/user";
import { updateToken } from "../config/SuperFetch";

import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Auth from "services/Auth";
import { useSelector } from "react-redux";
export default function CheckBusiness() {
	// check user business local user

	const [business, setBusiness] = useState(getLocalUser());

	if (checkGetLocalUser()) {
		if (business?.associated_businesses.length === 0) {
			setCreateUserBusiness(initBusiness);
			return <Navigate to='/business-name' />;
		} else if (business?.associated_businesses.length > 1) {
			return <Navigate to='/select-business' />;
		} else if (business?.associated_businesses.length === 1) {
			return <SingleBusiness />;
		}
	}
}

function SingleBusiness() {
	const navigate = useNavigate();
	const orderType = useSelector((state) => state.seller);
	async function businessSelectEvent(data) {
		try {
			if (data?.business_status !== "Black Listed") {
			const response = await Auth.businessSelect(data);
			setUserBusiness(response?.business_info);
			setUser(response?.user);
			localStorage.removeItem("setUser");
			//   setLocalUser({});
			updateToken(response);
			if (!orderType?.multiSupplier) {
				window.location.href = "/home";
			}
		}else{
			window.location.href = "/black-listed";
		}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		businessSelectEvent(getLocalUser().associated_businesses[0]);
	}, []);
	return <></>;
}
