import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Supplier = {
  getSupplierList: (page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/sellers/?page=${page}&limit=${limit}`
    );
  },
  singleSupplierDetails: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/sellers/${id}`);
  },
  supplierProducts: (id, page = 1, limit = 500) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/sellers/${id}/products?page=${page}&limit=${limit}`
    );
  },

  getMySupplierList: (page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.business}/v4/business-relations/suppliers/list?page=${page}&limit=${limit}`
    );
  },
  singleMySupplierProductDetails: (id, page = 1, limit = 500) => {
    return SuperFetch(
      `${baseUrl.product}/v4/products/buyer/${id}?page=${page}&limit=${limit}`
    );
  },
  singleMySupplierDetails: (id) => {
    return SuperFetch(`${baseUrl.business}/v4/businesses/${id}`);
  },
  getSelectedSupplierList: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/brands/${id}/sellers`);
  },
};
export default Supplier;
