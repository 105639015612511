import Auth from "services/Auth";
import { getLanguage } from "config/language";
import { getUser } from "config/user";

// route active checking
export function hasActive(path = "", f) {
	if (path.slice(0) == "*" && path.slice(-1) == "*")
		return (
			f.search(new RegExp("\\b" + path.substring(0, path.length - 1))) &&
			"active"
		);
	else if (path.slice(0) == "*")
		return (
			f.search(new RegExp(path.substring(0, path.length - 1) + "\\b")) ===
				-1 && "active"
		);
	else if (path.slice(-1) == "*")
		return (
			f.search(new RegExp(path.substring(0, path.length - 1) + "\\b")) ===
				0 && "active"
		);
	else return f === path && "active";
}

export async function authOtpSend(phoneNumber, country, countryCode) {
	let req_body = {
		phone_number: (countryCode + phoneNumber).trim(),
		country,
	};
	try {
		return await Auth.resendOptToken(req_body);
		// if (response.status === 200)
		//   //   toast.info(response.message);
		//   response.message;
		//  else
		//   {
		//     message: "Your phone number not verify yet please resend token",
		//     success: false,
		//   }
	} catch (e) {
		console.log(e);
	}
}

export function addStatusClass(status) {
	switch (status) {
		case "Placed":
			return "placed";
		case "Accepted":
			return "accepted";
		case "Delivered":
			return "delivered";
		case "Paid":
			return "paid";
		case "Cancel":
			return "cancel";
		case "Partially_Paid":
			return "partial-payment";
		case "Partially_Delivered":
			return "partial-delivery";
		case "Returned_Items":
			return "return";
		case "Scheduled For Delivery":
			return "scDelivery";
		case "Delivery Failed":
			return "delivery-failed";
		default:
			return "FFC227-1000";
		// break;
	}
}

var finalEnglishToBangleNumber = {
	0: "০",
	1: "১",
	2: "২",
	3: "৩",
	4: "৪",
	5: "৫",
	6: "৬",
	7: "৭",
	8: "৮",
	9: "৯",
};

String.prototype.getBangleDigit = function () {
	var retStr = this;
	for (var x in finalEnglishToBangleNumber) {
		retStr = retStr.replace(
			new RegExp(x, "g"),
			finalEnglishToBangleNumber[x]
		);
	}
	return retStr;
};

export const digitChanger = function (value) {
	if (getLanguage() === "bn") {
		return String(value).getBangleDigit();
	} else {
		return String(value);
	}
};

var englishToBangleMonth = {
	Jan: "জানুয়ারী",
	Feb: "ফেব্রুয়ারি",
	Mar: "মার্চ",
	Apr: "এপ্রিল",
	May: "মে",
	Jun: "জুন",
	Jul: "জুলাই",
	Aug: "আগস্ট",
	Sep: "সেপ্টেম্বর",
	Oct: "অক্টোবর",
	Nov: "নভেম্বর",
	Dec: "ডিসেম্বর",
};
String.prototype.getBangleMonth = function () {
	var retStr = this;
	for (var x in englishToBangleMonth) {
		retStr = retStr.replace(new RegExp(x, "g"), englishToBangleMonth[x]);
	}
	return retStr;
};

export const monthChanger = function (value) {
	if (getLanguage() === "bn") {
		return String(value).getBangleMonth();
	} else {
		return String(value);
	}
};

var englishToBangle = {
	BDT: "৳",
	SGD: "$",
};
String.prototype.getBangle = function () {
	var retStr = this;
	for (var x in englishToBangle) {
		retStr = retStr.replace(new RegExp(x, "g"), englishToBangle[x]);
	}
	return retStr;
};

export const translator = function (value) {
	if (getUser().Country === "Bangladesh") {
		return String(value).getBangle();
	} else if (getUser().Country === "Singapore") {
		return String(value).getBangle();
	} else {
		return String(value);
	}
};
