import React from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateCustomerBS } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import CreateCustomer from "assets/images/icon/svg/CreateCustomer";
import { useNavigate } from "react-router-dom";
import { emptyBusiness } from "redux/container/businessSettingsSlice";

export default function BottomSheet({ toRedirect }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.createCustomerBS
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[120, 120, 100, 0]}
				onClose={() => dispatch(setCreateCustomerBS(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								navigate(`/create-customer/${toRedirect}`);
								dispatch(emptyBusiness());
							}}
							lines='none'>
							<span className='py-[5px]'>
								<CreateCustomer />
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								{ekkLanguage.myCustomer.createCustomer}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setCreateCustomerBS(false))}
				/>
			</Sheet>
		</div>
	);
}
