import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Card from "../Common/Card";
import Minus from "assets/images/icon/svg/Minus";
import Plus from "assets/images/icon/svg/Plus";
import { useSelector, useDispatch } from "react-redux";
import {
	getCartList,
	subtotalAmount,
	totalAmount,
	setTotalTax,
} from "redux/container/shoppingCartSlice";
import {
	cartMinus,
	cartPlus,
	cartTotalAmount,
	removeCartProduct,
	setQuantity,
	shoppingCart,
	shoppingListItem,
} from "helpers/shoppingCart";
import Cross from "assets/images/icon/svg/Cross";
import { creditFee } from "redux/container/paymentMethodSlice";
import { digitChanger } from "helpers/helper";
import ProductQuantityInput from "./ProductQuantityInput";
import SingleProductForCard from "./SingleProductForCard";
import { useCallback } from "react";
import Add from "assets/images/icon/svg/Add";
import { Link } from "react-router-dom";
export default function ProductCard() {
	const [qty, setQty] = useState(1);
	const dispatch = useDispatch();
	const productCart = useSelector((state) => state.cart);
	const [productList, setProductList] = useState(shoppingListItem());
	const paymentTermsDay = useSelector((state) => state.payment.paymentTerm);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const sellerInfo = useSelector((state) => state.seller?.data);
	const multiSupplier = useSelector((state) => state.seller?.multiSupplier);

	useEffect(() => {
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(totalAmount(cartTotalAmount()));
	}, []);

	useEffect(() => {
		dispatch(getCartList());
	});

	let creditPercent;
	if (paymentTermsDay == 0) {
		creditPercent = 0;
	} else if (paymentTermsDay == 1) {
		creditPercent = 0.001;
	} else if (paymentTermsDay == 3) {
		creditPercent = 0.005;
	} else if (paymentTermsDay == 7) {
		creditPercent = 0.01;
	}

	// let totalWithCreitFee = cartTotalAmount() + creditFee;
	let cart = shoppingListItem();
	let tax = 0;

	const minusCartItem = (item) => {
		if (item.minimum_order_quantity < item.quantity) {
			cartMinus(item);
			setProductList(shoppingListItem());
			dispatch(
				totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
			);
			dispatch(subtotalAmount(cartTotalAmount()));
			dispatch(creditFee(cartTotalAmount() * creditPercent));

			for (let i = 0; i < cart.length; i++) {
				let taxAmount =
					((cart[i].base_price * cart[i].quantity) / 100) * cart[i].tax;
				tax += taxAmount;
			}
		}
	};

	const plusCartItem = (item) => {
		cartPlus(item);
		setProductList(shoppingListItem());
		dispatch(
			totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
		);
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(creditFee(cartTotalAmount() * creditPercent));

		for (let i = 0; i < cart.length; i++) {
			let taxAmount =
				((cart[i].base_price * cart[i].quantity) / 100) * cart[i].tax;
			tax += taxAmount;
		}
	};
	for (let i = 0; i < cart.length; i++) {
		let taxAmount =
			((cart[i].base_price * cart[i].quantity) / 100) * cart[i].tax;
		tax += taxAmount;
	}

	useEffect(() => {
		dispatch(setTotalTax(tax));
	}, [tax]);

	return (
		<div className='px-2 pt-2 pb-5'>
			<Card title={ekkLanguage.createOrder.productItemTitle}>
				<div>
					<IonRow>
						{productList.length ? (
							productList.map((item) => {
								let image;
								if (item?.images[0]) {
									image = item?.images[0];
								} else {
									if (item.type === "variant") {
										image = "/assets/images/varient_default.png";
									} else if (item.type === "pack") {
										image = "/assets/images/pack_default.png";
									} else if (item.type === "offer") {
										image = "/assets/images/offer_default.png";
									}
								}
								return (
									<SingleProductForCard
										image={image}
										item={item}
										minusCartItem={minusCartItem}
										plusCartItem={plusCartItem}
										setProductList={setProductList}
									/>
								);
							})
						) : (
							<div className='pt-4 w-full'>
								{multiSupplier ? (
									<Link
										style={
											Object.keys(sellerInfo).length
												? { opacity: 1 }
												: { opacity: 0.3 }
										}
										to={
											Object.keys(sellerInfo).length
												? "/select-product"
												: "#"
										}
										className='custom-btn  block'>
										<IonButton
											expand='full'
											className='h-10 w-full border border-primary rounded-[10px] font-bold text-12 text-primary'
											style={{ background: "#F3F3F3" }}>
											<div className='flex justify-between items-center w-full'>
												<span className='flex items-center'>
													<img
														src='/assets/images/package-box.png'
														alt='package'
														className='pr-[10px]'
													/>
													{ekkLanguage.createOrder.addMoreProduct}
												</span>

												<span className='pl-2 block'>
													<Add />
												</span>
											</div>
										</IonButton>
									</Link>
								) : (
									<Link
										to='/select-product'
										className='custom-btn  block'>
										<IonButton
											expand='full'
											className='h-10 w-full border border-primary rounded-[10px] font-bold text-12 text-primary'
											style={{ background: "#F3F3F3" }}>
											<div className='flex justify-between items-center w-full'>
												<span className='flex items-center'>
													<img
														src='/assets/images/package-box.png'
														alt='package'
														className='pr-[10px]'
													/>
													{ekkLanguage.createOrder.addMoreProduct}
												</span>

												<span className='pl-2 block'>
													<Add />
												</span>
											</div>
										</IonButton>
									</Link>
								)}
							</div>
						)}
					</IonRow>
				</div>
			</Card>
		</div>
	);
}
