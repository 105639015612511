import Location from "assets/images/icon/svg/Location";
import { getUserBusiness } from "config/user";
import { useSelector } from "react-redux";
import Card from "../Common/Card";
import Add from "assets/images/icon/svg/Add";
import { useNavigate } from "react-router-dom";
import Supplier from "assets/images/icon/svg/Supplier";

export default function SupplierDetailsCard() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const sellerInfo = useSelector((state) => state.seller?.data);
	const multiSupplier = useSelector((state) => state.seller?.multiSupplier);
	const navigate = useNavigate();
	const getSeller = getUserBusiness();

	return (
		<div className='px-2 pt-4 pb-5'>
			<Card
				title={ekkLanguage.createOrder.sellerDetailsTitle}
				addSeller={multiSupplier}
				to='/select-supplier'>
				<div className='pt-4 px-2'>
					{multiSupplier ? (
						<>
							{Object.keys(sellerInfo).length > 0 ? (
								<>
									<h4 className='text-success text-14 font-bold pb-3'>
										{sellerInfo?.business_name}
									</h4>
									<div className='flex'>
										<span>
											<Location />
										</span>
										<p className='text-12 text-000080 ml-2'>{`${sellerInfo?.address_line} ${sellerInfo?.country} ${sellerInfo?.city} ${sellerInfo?.postal_code}`}</p>
									</div>
								</>
							) : (
								<div
									className={`bg-F3F3F3 p-3 block rounded-[10px] border example-custom-input border-primary`}
									onClick={() => navigate("/select-supplier")}
									style={{
										boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
									}}>
									<div className='flex justify-between items-center'>
										<div className='flex items-center'>
											<span>
												<Supplier />
											</span>
											<p className={`text-12 text-primary ml-2`}>
												Select a Supplier
											</p>
										</div>
										<div>
											<Add />
										</div>
									</div>
								</div>
							)}
						</>
					) : (
						<>
							<h4 className='text-success text-14 font-bold pb-3'>
								{getSeller?.business_name}
							</h4>
							<div className='flex'>
								<span>
									<Location />
								</span>
								<p className='text-12 text-000080 ml-2'>{`${getSeller?.address_line} ${getSeller?.country} ${getSeller?.city} ${getSeller?.postal_code}`}</p>
							</div>
						</>
					)}
				</div>
			</Card>
		</div>
	);
}
