import { baseUrl } from "config/apiUrl";
import AuthFetch from "../config/authFetch";
import SuperFetch from "../config/SuperFetch";

const Auth = {
	countryAndUserFilter: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkhero/users/phone`, {
			method: "POST",
			data: query,
		});
	},
	login: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkhero/users/login`, {
			method: "POST",
			data: query,
		});
	},
	resendOptToken: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkhero/users/resend-otp`, {
			method: "POST",
			data: query,
		});
	},
	resetPassword: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkhero/users/reset-password`, {
			method: "POST",
			data: query,
		});
	},

	userVerifyOtp: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkhero/users/verify/otp`, {
			method: "POST",
			data: query,
		});
	},
	userCaptcha: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkhero/users/verify-otp`, {
			method: "POST",
			data: query,
		});
	},
	// create new user
	createNewUser: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkhero/users/set-profile`, {
			method: "POST",
			data: query,
		});
	},
	// Business select
	businessSelect: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkhero/users/refresh/auth-token`, {
			method: "POST",
			data: query,
		});
	},
	// Business select
	createUserBusiness: (query) => {
		return SuperFetch(
			`${baseUrl.business}/ekkhero/businesses/user/business`,
			{
				method: "POST",
				data: query,
			}
		);
	},
	resetPasswordWhenLogdIn: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkhero/users/reset-password`, {
			method: "POST",
			data: query,
		});
	},
	refreshToken: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkhero/users/refresh/auth-token`, {
			method: "POST",
			data: query,
		});
	},
};

export default Auth;
