import { baseUrl } from "config/apiUrl";
import { getUser } from "config/user";
import SuperFetch from "../config/SuperFetch";
let user = getUser();

const Product = {
  // productList: (page = 1, limit = 12) => {
  // 	return SuperFetch(
  // 		`${baseUrl.product}/v4/catalogs/buyer/discover?page=${page}&limit=${limit}&sort=price.desc`
  // 	);
  // },

  // productList: (page = 1, limit = 12) => {
  // 	if (user?.country === "Bangladesh") {
  // 		return SuperFetch(
  // 			`${baseUrl.product}/v4/products/buyer/ekk-trading-available-products?page=${page}&limit=${limit}`
  // 		);
  // 	} else {
  // 		return SuperFetch(
  // 			`${baseUrl.product}/v4/catalogs/buyer/discover?page=${page}&limit=${limit}&sort=price.desc`
  // 		);
  // 	}
  // },
  productList: (page = 1, limit = 12) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/products/?page=${page}&limit=${limit}`
    );
  },
  singleProductDetails: (id, type) => {
    return SuperFetch(`${baseUrl.BizNpdt}/${type}/${id}`);
  },
  buyerSellerProductList: (seller, page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller_products/?seller_id=${seller}&page=${page}&limit=${limit}`
    );
  },
  productSearch: (tarm, page = 1, limit = 500) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/products/search?term=${tarm}&page=${page}&limit=${limit}`
    );
  },
  // productSearch: (tarm, page = 1, limit = 500) => {
  // 	return SuperFetch(
  // 		`${baseUrl.BizNpdt}/v4/products/search/?search=${tarm}`
  // 	);
  // },

  offerProduct: (page = 1, limit = 500) => {
    return SuperFetch(`${baseUrl.BizNpdt}/offers/?page=${page}&limit=${limit}`);
  },
  offerProductDetails: (id) => {
    return SuperFetch(`${baseUrl.product}/v4/offers/${id}`);
  },
  productBrands: (id, page = 1, limit = 500) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller_brands/?page=${page}&limit=${limit}&seller_id=${id}`
    );
  },
  singleBrandProducts: (sellerId, productId, page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller_brand_products/${productId}/?page=${page}&limit=${limit}&seller_id=${sellerId}`
    );
  },
  // singleProductSearch: (sellerId, query, brandId) => {
  // 	return SuperFetch(
  // 		`${baseUrl.BizNpdt}/businesses/${sellerId}/products/?q=${query}&brand_id=${brandId}`
  // 	);
  // },
  singleProductSearch: (brandId, sellerId, query, page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller_brand_products/${brandId}/search/?page=${page}&limit=${limit}&seller_id=${sellerId}`
    );
  },
  sellerProductSearch: (sellerId, query, page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller_products/search/?seller_id=${sellerId}&page=${page}&limit=${limit}&term=${query}`
    );
  },
  brandProductSearch: (brandId, query) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/brands/${brandId}/search/?term=${query}`
    );
  },
};
export default Product;
