import {
  IonApp,
  IonButton,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getLocalUser, setUser } from "config/user";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMultiSupplier, setSellerType } from "redux/container/sellerSlice";

export default function OrderSystemPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const localUser = getLocalUser();
  const orderType = useSelector((state) => state.seller.sellerType);
  const [stateUpdate, setStateUpdate] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderSystemHandler = function (e) {
    setStateUpdate(!stateUpdate);
    dispatch(setSellerType(e.detail.value));
    dispatch(setMultiSupplier(e.detail.value === "multiple" ? true : false));
    if (e.detail.value === "multiple") {
      setUser(localUser?.user);
    }
  };

  return (
    <IonApp style={{ "--ion-background-color": "#fff" }}>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.supplierSelect.pageTitle} />
          <section className="pt-14 px-4">
            <div className="mt-[30px] text-center text-black-1000">
              {" "}
              <img
                src="assets/images/tracking.png"
                alt="icon"
                className="m-auto mb-5"
              />
              <p
                className="text-16 font-bold w-[250px] m-auto"
                style={{ color: "#E73F46" }}
              >
                {ekkLanguage.supplierSelect.chooseText}
              </p>
              <div className="pt-10">
                <IonRadioGroup
                  value={orderType ?? ""}
                  onIonChange={(e) => orderSystemHandler(e)}
                >
                  <IonItem
                    lines="none"
                    className="bg-white border rounded-[10px] mb-5"
                  >
                    <IonLabel className=" border-0 font-sans text-14 text-black-500 font-medium">
                      <div className="flex">
                        <span span className="ml-2">
                          {ekkLanguage.supplierSelect.multiple}
                        </span>
                      </div>
                    </IonLabel>
                    <IonRadio slot="start" value="multiple" color="success" />
                  </IonItem>
                  <IonItem
                    lines="none"
                    className="bg-white border rounded-[10px] mb-5"
                  >
                    <IonLabel className=" border-0 font-sans text-14 text-black-500 font-medium">
                      <div className="flex">
                        <span span className="ml-2">
                          {ekkLanguage.supplierSelect.specific}
                        </span>
                      </div>
                    </IonLabel>
                    <IonRadio slot="start" value="specific" color="success" />
                  </IonItem>
                </IonRadioGroup>
              </div>
              <div>
                <IonButton
                  color="primary"
                  className="font-bold h-10 pt-[60px]"
                  onClick={() => {
                    navigate(
                      orderType === "specific" ? "/home" : "/order-list"
                    );
                  }}
                >
                  {ekkLanguage.supplierSelect.next}
                  <span className="pl-2">
                    <ArrayRight />
                  </span>
                </IonButton>
              </div>
            </div>
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
