import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import BottomSheet from "components/mobile-components/MyCustomer/BottomSheet";
import Customer from "components/mobile-components/MyCustomer/Customer";
import CustomerSearch from "components/mobile-components/MyCustomer/CustomerSearch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Business from "services/Business";

import withLocation from "components/HOC/withLocation";
import { getUserBusiness } from "config/user";

function MyCustomerPage() {
	const orderType = useSelector((state) => state.seller);
	const sellerId = orderType?.multiSupplier
		? orderType.data.id ?? getUserBusiness()?.id
		: getUserBusiness()?.id;
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [customerList, setCustomerList] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedCustomer, setSelectedCustomer] = useState("");
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [tempSearchParams, setTempSearchParams] = useState("");
	const location = useSelector((state) => state.user.locationData);

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	async function fetchCustomerList(id) {
		setLoading(true);

		try {
			const response = await Business.getNearbyCustomers(
				sellerId,
				location.coordinates[1],
				location.coordinates[0]
			);
			if (response.status === 200) {
				setLoading(false);
				setCustomerList(response.results);
			} else {
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function fetchSingleCustomer() {
		setTempSearchParams(searchQ);
		setLoading(true);
		setPageCount(2);
		try {
			const response = await Business.getNearbyCustomer(
				sellerId,
				location.coordinates[1],
				location.coordinates[0],
				searchQ
			);
			if (response.status === 200) {
				setLoading(false);
				setCustomerList(response.results);
			} else {
				setCustomerList([]);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (searchQ === "") {
			fetchCustomerList("");
			setTempSearchParams("");
		}
	}, [searchQ]);

	let lodeMoreData = async () => {
		if (searchQ) {
			const response = await Business.getNearbyCustomer(
				sellerId,
				location.coordinates[1],
				location.coordinates[0],
				searchQ,
				pageCount
			);
			if (response.status === 200) {
				setPageCount((prev) => prev + 1);
				setCustomerList([...customerList, ...response.results]);
			} else {
				setLoading(false);
			}
		} else {
			const response = await Business.getNearbyCustomers(
				sellerId,
				location.coordinates[1],
				location.coordinates[0],
				pageCount
			);
			if (response.status === 200) {
				setPageCount((prev) => prev + 1);
				setCustomerList([...customerList, ...response.results]);
			} else {
				setLoading(false);
			}
		}
	};

	// let getRelationList = async () => {
	// 	let response = await Business.getRelationList();
	// 	if (response.status === 200) {
	// 		let id = response.results.find(
	// 			(el) => el.relation_name === "Business Customer"
	// 		).id;
	// 		fetchCustomerList(id);
	// 		setRelationList(id);
	// 	}
	// };

	// useEffect(() => {
	// 	getRelationList();
	// }, []);

	// async function fetchCustomerList(id) {
	// 	setLoading(true);

	// 	try {
	// 		const response = await Business.getCustomers(id);
	// 		if (response.status === 200) {
	// 			setLoading(false);
	// 			setCustomerList(response.results);
	// 		} else {
	// 			setLoading(false);
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }

	// async function fetchSingleCustomer() {
	// 	setTempSearchParams(searchQ);
	// 	setLoading(true);
	// 	setPageCount(2);
	// 	try {
	// 		const response = await Business.getCustomer(relationList, searchQ);
	// 		if (response.status === 200) {
	// 			setLoading(false);
	// 			setCustomerList(response.results);
	// 		} else {
	// 			setCustomerList([]);
	// 			setLoading(false);
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }

	// useEffect(() => {
	// 	if (searchQ === "") {
	// 		fetchCustomerList(relationList);
	// 		setTempSearchParams("");
	// 	}
	// }, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.myCustomer.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						from='myCustomer'
					/>
					<section className='pt-14'>
						<CustomerSearch
							setSearchQ={setSearchQ}
							searchQ={searchQ}
							fetchCustomer={fetchSingleCustomer}
							placeHolder={ekkLanguage.customerList.searchPlaceHolder}
						/>
						<p className='px-4 pt-2'>
							{" "}
							{tempSearchParams && (
								<span>
									search:{" "}
									<span className='font-bold'>
										{" "}
										{tempSearchParams}
									</span>
								</span>
							)}
						</p>
						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.businessLocation.loader}
							/>
						) : (
							<Customer
								customerList={customerList}
								selectedCustomer={selectedCustomer}
								setSelectedCustomer={setSelectedCustomer}
							/>
						)}
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							lodeMoreData();
							setTimeout(() => ev.target.complete(), 500);
						}}>
						<IonInfiniteScrollContent></IonInfiniteScrollContent>
					</IonInfiniteScroll>
					<BottomSheet toRedirect='my-customer' />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default withLocation(MyCustomerPage);
