import {
  IonApp,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import MyProduct from "components/mobile-components/MyProducts";
import ProductSearch from "components/mobile-components/SelectProduct/ProductSearch";
import ProductFilter from "components/mobile-components/SelectProduct/ProductFilter";
import { getUserBusiness } from "config/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Product from "services/Product";

function MyProductsPage() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [products, setProduct] = useState([]);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [loading, setLoading] = useState(false);
  const userBusiness = getUserBusiness();
  const [searchQ, setSearchQ] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [productBrands, setProductBrands] = useState([]);
  const [pageCount, setPageCount] = useState(2);
  const [productCount, setProductCount] = useState();
  const [metaCount, setMetaCount] = useState(30);
  const [tempSearchParams, setTempSearchParams] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  async function getProductBrands() {
    setReachedEnd(false);
    try {
      const response = await Product.productBrands(userBusiness.id);
      if (response.status === 200) {
        setProductBrands(response?.results);
        setMetaCount(response.count);
        if (response?.results?.length > 0) {
          setHasMoreData(true);
          setReachedEnd(false);
        } else {
          setHasMoreData(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getProductsFromBrands(id) {
    setLoading(true);
    setPageCount(2);
    setSelectedBrand(id);
    setMetaCount(1);
    setProduct([]);
    setReachedEnd(false);
    setHasMoreData(true);
    try {
      if (searchQ) {
        let response = await Product.singleProductSearch(
          id,
          userBusiness.id,
          searchQ
        );
        if (response.status === 200) {
          setProduct(response?.results);
          setMetaCount(response.count);
          if (response?.results?.length > 0) {
            setHasMoreData(true);
            setReachedEnd(false);
          } else {
            setHasMoreData(false);
          }
        }
      } else {
        const response = await Product.singleBrandProducts(id);
        if (response.status === 200) {
          setProduct(response?.results);
          setMetaCount(response.count);
          if (response?.results?.length > 0) {
            setHasMoreData(true);
            setReachedEnd(false);
          } else {
            setHasMoreData(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  //get product data
  async function fetchProduct() {
    setLoading(true);
    setReachedEnd(false);
    setHasMoreData(true);
    try {
      if (searchQ) {
        let response = await Product.sellerProductSearch(
          userBusiness.id,
          searchQ
        );
        if (response.status === 200) {
          setLoading(false);
          setProduct(response?.results);
          setMetaCount(response.count);
          if (response?.results?.length > 0) {
            setHasMoreData(true);
            setReachedEnd(false);
          } else {
            setHasMoreData(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        if (selectedBrand) {
          const response = await Product.singleBrandProducts(selectedBrand);
          if (response.status === 200) {
            setProduct(response?.results);
            setMetaCount(response.count);
            setLoading(false);
            if (response?.results?.length > 0) {
              setHasMoreData(true);
              setReachedEnd(false);
            } else {
              setHasMoreData(false);
            }
          } else {
            setLoading(false);
          }
        } else {
          const response = await Product.buyerSellerProductList(
            userBusiness.id
          );
          if (response.status === 200) {
            setLoading(false);
            setProduct(response?.results);
            setMetaCount(response.count);
            if (response?.results?.length > 0) {
              setHasMoreData(true);
              setReachedEnd(false);
            } else {
              setHasMoreData(false);
            }
          } else {
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProductBrands();
    fetchProduct();
  }, []);

  let settings = {
    nameShow: false,
    titleShow: true,
    skuShow: false,
    priceShow: true,
    oldPriceShow: false,
    distShow: false,
    profitShow: true,
    sellPriceShow: false,
    supplierNameShow: false,
    link: "product-details",
  };

  let getAllProduct = async () => {
    setLoading(true);
    setReachedEnd(false);
    setHasMoreData(true);
    try {
      if (searchQ) {
        let response = await Product.sellerProductSearch(
          userBusiness.id,
          searchQ
        );
        if (response.status === 200) {
          setLoading(false);
          setProduct(response?.results);
          setMetaCount(response.count);
          if (response?.results?.length > 0) {
            setHasMoreData(true);
            setReachedEnd(false);
          } else {
            setHasMoreData(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        const response = await Product.buyerSellerProductList(userBusiness.id);
        if (response.status === 200) {
          setLoading(false);
          setProduct(response?.results);
          setMetaCount(response.count);
          if (response?.results?.length > 0) {
            setHasMoreData(true);
            setReachedEnd(false);
          } else {
            setHasMoreData(false);
          }
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function singleProductSearch() {
    setTempSearchParams(searchQ);
    try {
      let response;
      if (selectedBrand) {
        response = await Product.singleProductSearch(
          selectedBrand,
          userBusiness.id,
          searchQ
        );
      } else {
        response = await Product.sellerProductSearch(userBusiness.id, searchQ);
      }

      if (response.status === 200) {
        setProduct(response?.results);
        setMetaCount(response.count);
        if (response?.results?.length > 0) {
          setHasMoreData(true);
          setReachedEnd(false);
        } else {
          setHasMoreData(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (searchQ === "") {
      fetchProduct();
      setTempSearchParams("");
    }
  }, [searchQ]);

  let lodeMoreData = async () => {
    if (searchQ) {
      if (selectedBrand) {
        let response = await Product.singleProductSearch(
          selectedBrand,
          userBusiness.id,
          searchQ,
          pageCount
        );
        if (response.status === 200) {
          if (response.results.length > 0) {
            setPageCount((prev) => prev + 1);
            setProduct([...products, ...response?.results]);
            setMetaCount(response.count);
            if (response?.results?.length > 0) {
              setHasMoreData(true);
            } else {
              setHasMoreData(false);
            }
          }
        }else{
			setHasMoreData(false);
		}
      } else {
        let response = await Product.sellerProductSearch(
          userBusiness.id,
          searchQ,
          pageCount
        );
        if (response.status === 200) {
          if (response.results.length > 0) {
            setPageCount((prev) => prev + 1);
            setProduct([...products, ...response?.results]);
            setMetaCount(response.count);
            if (response?.results?.length > 0) {
              setHasMoreData(true);
            } else {
              setHasMoreData(false);
            }
          }
        }else{
			setHasMoreData(false);
		}
      }
    } else {
      if (selectedBrand) {
        const response = await Product.singleBrandProducts(
          selectedBrand,
          pageCount
        );
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          setProduct([...products, ...response?.results]);
          setMetaCount(response.count);
          if (response?.results?.length > 0) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
        }else{
			setHasMoreData(false);
		}
      } else {
        let response = await Product.buyerSellerProductList(
          userBusiness.id,
          pageCount
        );
        if (response.status === 200) {
          if (response.results.length > 0) {
            setPageCount((prev) => prev + 1);
            setProduct([...products, ...response?.results]);
            setMetaCount(response.count);
            if (response?.results?.length > 0) {
              setHasMoreData(true);
              setReachedEnd(false);
            } else {
              setHasMoreData(false);
            }
          }
        }else{
			setHasMoreData(false);
		}
      }
    }
  };

  async function searchNext(event) {
    let timeout = setTimeout(() => {
      lodeMoreData();
      // event.target.complete();
      clearTimeout(timeout);
    }, 1000);
  }
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.myProducts.pageTitle}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <section className="px-2 pt-14">
            <ProductSearch
              setSearchQ={setSearchQ}
              searchQ={searchQ}
              // stickySearch={true}
              fetchProduct={fetchProduct}
              singleProductSearch={singleProductSearch}
              setSelectedBrand={setSelectedBrand}
              setReachedEnd={setReachedEnd}
              setHasMoreData={setHasMoreData}
            />
            <p className="px-4 pb-4">
              {tempSearchParams && (
                <span>
                  search: <span className="font-bold">{tempSearchParams}</span>
                </span>
              )}
            </p>
            <ProductFilter
              productBrands={productBrands}
              getProductsFromBrands={getProductsFromBrands}
              getProductBrands={getProductBrands}
              fetchProduct={fetchProduct}
              setSearchQ={setSearchQ}
              setSelectedBrand={setSelectedBrand}
              getAllProduct={getAllProduct}
              setReachedEnd={setReachedEnd}
              setHasMoreData={setHasMoreData}
            />
            {loading ? (
              <IonLoading
                isOpen={loading}
                message={ekkLanguage.orderList.loader}
              />
            ) : (
              <MyProduct
                loading={loading}
                recommendData={products}
                title={ekkLanguage.letNav.myProducts}
                settinges={settings}
                setReachedEnd={setReachedEnd}
                setHasMoreData={setHasMoreData}
              />
            )}
          </section>
          <div>
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                if (reachedEnd) {
                  ev.target.complete();
                  return;
                }
                lodeMoreData();
                setTimeout(() => {
                  ev.target.complete();
                  if (!hasMoreData) {
                    setReachedEnd(true);
                  }
                }, 500);
              }}
            >
              <IonInfiniteScrollContent
                loadingText={ekkLanguage.orderList.loader}
                loadingSpinner={hasMoreData ? "bubbles" : null}
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
// export default withLocation(Home);
export default MyProductsPage;
