import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const User = {
	getBasicInfoUpdate: () => {
		return SuperFetch(`${baseUrl.pass}/v4/users/custom/user-profile`);
	},
	basicInfoUpdate: (id, query) => {
		return SuperFetch(`${baseUrl.pass}/v4/users/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	// toBucketImage: (query) => {
	// 	return SuperFetch(`${baseUrl.bucket}/users/blob`, {
	// 		method: "POST",
	// 		data: query,
	// 	});
	// },

	toBucketImage: (query) => {
		return SuperFetch(`${baseUrl.bucket}/files/upload/`, {
			method: "POST",
			data: query,
		});
	},
};

export default User;
