import React from "react";
import Banner from "./Banner";
import BkashDetails from "./BkashDetails";
import BranchList from "./BranchList";
import BusinessBottomSheet from "./BusinessBottomSheet";
import BusinessDetails from "./BusinessDetails";
import NagadDetails from "./NagadDetails";
import PaymentDetails from "./PaymentDetals";
import UpayDetails from "./UpayDetails";

export default function BusinessProfile({ businessData }) {
	return (
		<div className='pt-14'>
			<Banner businessData={businessData} />
			<div className='px-4 py-4'>
				<BusinessDetails businessData={businessData} />
				{/* <BranchList /> */}
				<PaymentDetails />
				{/* <BkashDetails />
				<NagadDetails />
				<UpayDetails /> */}
				<BusinessBottomSheet />
			</div>
		</div>
	);
}
