import {
	IonButton,
	IonCol,
	IonGrid,
	IonItem,
	IonLabel,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonRow,
} from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import CardIcon from "assets/images/icon/svg/Card";
import Payment from "assets/images/icon/svg/Payment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	paymentTerm,
	paymentMethod,
	creditFee,
	setPaymentName,
	setPaymentMethodText,
} from "redux/container/paymentMethodSlice";
import { subtotalAmount, totalAmount } from "redux/container/shoppingCartSlice";

import Card from "../Common/Card";
import { cartTotalAmount, shoppingListItem } from "helpers/shoppingCart";
import { digitChanger } from "helpers/helper";
import moment from "moment";

export default function PaymentCard() {
	let payTerms = useSelector((state) => state.payment.paymentName);
	let payMethods = useSelector((state) => state.payment.paymentMethodText);
	const cartTotal = useSelector((state) => state.cart.total);

	const dispatch = useDispatch();
	const [selected, setSelected] = useState("biff");
	const [payTrim, setPayTrim] = useState("Select a Payment Terms");
	const [payMethod, setPayMethod] = useState("Select a Payment Method");

	useEffect(() => {
		setPayTrim(payTerms ? payTerms : "Select a Payment Terms");
	}, [payTerms]);
	useEffect(() => {
		setPayMethod(payMethods ? payMethods : "Select a Payment Method");
	}, [payMethods]);

	const [paymentTermText, setpaymentTermText] = useState("");
	const [paymentMethodText, setpaymentMethodText] = useState("");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const selectedDalieveryDate = useSelector(
		(state) => state.payment.deliveryDate
	);

	const [isOpenPaymentTerms, setIsOpenPaymentTerms] = useState(false);
	const [isOpenPaymentMethod, setIsOpenPaymentMethod] = useState(false);

	const paymentTermsRef = useRef(null);
	const paymentMethodeRef = useRef(null);

	const paymentTermsModalClose = () => {
		// paymentTermsRef.current?.dismiss();
		setIsOpenPaymentTerms(false);
	};
	const paymentMethodModalClose = () => {
		setIsOpenPaymentMethod(false);
		// paymentMethodeRef.current?.dismiss();
	};

	const delivery = require("assets/images/delivery.png");
	const bank = require("assets/images/bank.png");
	const cash = require("assets/images/Cash.png");
	const bkash = require("assets/images/bkash.png");
	const nogod = require("assets/images/nogod.png");
	const sellerName = () => {
		if (shoppingListItem().length !== 0) {
			return shoppingListItem()[0].seller_business_name;
		} else {
			return "";
		}
	};

	return (
		<div className='px-2 pt-4'>
			<Card title={ekkLanguage.createOrder.paymentDetailsTitle}>
				<IonGrid>
					<IonRow>
						<IonCol size='12'>
							<div className='pt-1'>
								<h4 className='text-success text-14 font-bold pb-3'>
									{ekkLanguage.createOrder.paymentTermsTitle}
								</h4>
								<div
									className={`bg-F3F3F3 p-2 block rounded-[10px] border ${
										payTrim === "Select a Payment Terms"
											? "border-primary"
											: "border-transparent"
									} ${cartTotal > 0 ? "opacity-100" : "opacity-30"}`}
									// id={cartTotal > 0 ? "open-payment-modal" : false}
									onClick={() =>
										setIsOpenPaymentTerms(cartTotal > 0 && true)
									}>
									<div className='flex justify-between items-center'>
										<div className='flex'>
											<span>
												<Payment
													color={
														payTrim === "Select a Payment Terms"
															? "#F94B41"
															: "#222222"
													}
												/>
											</span>
											<p
												className={`text-12 ${
													payTrim === "Select a Payment Terms"
														? "text-primary"
														: "text-black-1000"
												} font-medium ml-2`}>
												{/* Select a Payment Terms */}
												{payTrim}
											</p>
										</div>
										<div>
											<Add
												color={
													payTrim === "Select a Payment Terms"
														? "#F94B41"
														: "#222222"
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<IonModal
								className='paymentTrmsModal'
								// trigger='open-payment-modal'
								isOpen={isOpenPaymentTerms}
								showBackdrop={true}
								ref={paymentTermsRef}>
								<div>
									<Card
										title={ekkLanguage.createOrder.paymentTermsTitle}
										onHide={true}
										closeModal={paymentTermsModalClose}>
										<div>
											<div className='pt-5'>
												<IonRadioGroup
													value={payTrim}
													onIonChange={(e) => {
														let selectedDayes;
														let creditfee;
														if (
															e.detail.value ===
															"At Night of Delivery Day"
														) {
															let interVal = setTimeout(() => {
																dispatch(
																	setPaymentName(
																		e.detail.value
																	)
																);
																clearTimeout(interVal);
															}, 10);
															setPayTrim(e.detail.value);
															selectedDayes = 1;
															creditfee =
																cartTotalAmount() * 0.001;
															setpaymentTermText(
																`${
																	ekkLanguage.createOrder
																		.todayPaymentText1
																} ${digitChanger(
																	cartTotalAmount().toFixed(2)
																)} x ${digitChanger(
																	0.001
																)}% = ${digitChanger(
																	creditfee.toFixed(2)
																)} ${
																	ekkLanguage.createOrder
																		.todayPaymentText2
																}`
															);
														} else if (
															e.detail.value === "Pay in 3 days"
														) {
															setPayTrim(e.detail.value);
															let interVal = setTimeout(() => {
																dispatch(
																	setPaymentName(
																		e.detail.value
																	)
																);
																clearTimeout(interVal);
															}, 10);
															selectedDayes = 3;
															creditfee =
																cartTotalAmount() * 0.005;
															setpaymentTermText(
																`${
																	ekkLanguage.createOrder
																		.threePaymentText1
																} ${digitChanger(
																	cartTotalAmount().toFixed(2)
																)} x ${digitChanger(
																	0.5
																)}% = ${digitChanger(
																	creditfee.toFixed(2)
																)} ${
																	ekkLanguage.createOrder
																		.threePaymentText2
																}`
															);
														} else if (
															e.detail.value === "Pay in 7 days"
														) {
															setPayTrim(e.detail.value);
															let interVal = setTimeout(() => {
																dispatch(
																	setPaymentName(
																		e.detail.value
																	)
																);
																clearTimeout(interVal);
															}, 10);
															selectedDayes = 7;
															creditfee =
																cartTotalAmount() * 0.01;
															setpaymentTermText(
																`${
																	ekkLanguage.createOrder
																		.sevenPaymentText1
																} ${digitChanger(
																	cartTotalAmount().toFixed(2)
																)} x ${digitChanger(
																	1
																)}% = ${digitChanger(
																	creditfee.toFixed(2)
																)} ${
																	ekkLanguage.createOrder
																		.sevenPaymentText2
																}`
															);
														} else {
															setPayTrim(e.detail.value);
															setTimeout(() => {
																let interVal = dispatch(
																	setPaymentName(
																		e.detail.value
																	)
																);
																clearTimeout(interVal);
															}, 10);
															selectedDayes = 0;
															creditfee = 0;
															setpaymentTermText(
																ekkLanguage.createOrder
																	.immediatePaymentText
															);
														}

														dispatch(creditFee(creditfee));
														dispatch(paymentTerm(selectedDayes));
														setTimeout(() => {
															dispatch(
																totalAmount(
																	cartTotalAmount() + creditfee
																)
															);
															dispatch(
																subtotalAmount(
																	cartTotalAmount()
																)
															);
														}, 10);
													}}>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-4 no-ripple'>
														<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
															{
																ekkLanguage.createOrder
																	.immediatePayment
															}
														</IonLabel>
														<IonRadio
															slot='start'
															value='Immediate Payment'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5 no-ripple'>
														<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															<div className='flex'>
																{
																	ekkLanguage.createOrder
																		.cashOnDelivery
																}
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='Cash on Delivery'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5 no-ripple'>
														<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															<div className='flex'>
																{
																	ekkLanguage.createOrder
																		.pickAndPay
																}
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='Pick And Pay'
															color='success'
														/>
													</IonItem>

													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-4 no-ripple'>
														<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
															{ekkLanguage.createOrder.today} (
															{digitChanger(
																selectedDalieveryDate
																	? moment(
																			selectedDalieveryDate
																	  ).format("DD-MM-YYYY")
																	: moment(new Date())
																			.add(1, "days")
																			.format("DD-MM-YYYY")
															)}
															)
														</IonLabel>
														<IonRadio
															slot='start'
															value='At Night of Delivery Day'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-4 no-ripple'>
														<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
															{ekkLanguage.createOrder.threeDays}
														</IonLabel>
														<IonRadio
															slot='start'
															value='Pay in 3 days'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-4 no-ripple'>
														<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
															{ekkLanguage.createOrder.sevenDays}
														</IonLabel>
														<IonRadio
															slot='start'
															value='Pay in 7 days'
															color='success'
														/>
													</IonItem>
												</IonRadioGroup>
											</div>
											<p className='font-normal text-12 text-black-500 px-2'>
												{paymentTermText}
											</p>
											<div className='text-center pt-0'>
												<IonButton
													color='primary'
													onClick={() =>
														setIsOpenPaymentTerms(false)
													}>
													{ekkLanguage.createOrder.btnLabel}
												</IonButton>
											</div>
										</div>
									</Card>
								</div>
							</IonModal>
						</IonCol>
						<IonCol size='12'>
							<div className='pt-2'>
								<h4 className='text-success text-14 font-bold pb-3'>
									{ekkLanguage.createOrder.paymentMethodeTitle}
								</h4>
								<div
									className={`bg-F3F3F3 p-2 block rounded-[10px] border ${
										payMethod === "Select a Payment Method"
											? "border-primary"
											: "border-transparent"
									} ${cartTotal > 0 ? "opacity-100" : "opacity-30"}`}
									// id={cartTotal > 0 ? "open-method-modal" : ""}
									onClick={() =>
										setIsOpenPaymentMethod(cartTotal > 0 && true)
									}>
									<div className='flex justify-between items-center'>
										<div className='flex'>
											<span>
												<CardIcon
													color={
														payMethod ===
														"Select a Payment Method"
															? "#F94B41"
															: "#222222"
													}
												/>
											</span>
											<p
												className={`text-12 ${
													payMethod === "Select a Payment Method"
														? "text-primary"
														: "text-black-1000"
												} font-medium ml-2 `}>
												{/* Select a Payment Method */}
												{payMethod}
											</p>
										</div>
										<div>
											<Add
												color={
													payMethod === "Select a Payment Method"
														? "#F94B41"
														: "#222222"
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<IonModal
								className='paymentMethodeModal'
								// trigger='open-method-modal'
								isOpen={isOpenPaymentMethod}
								showBackdrop={true}
								ref={paymentMethodeRef}>
								<div>
									<Card
										title={
											ekkLanguage.createOrder.paymentMethodeTitle
										}
										onHide={true}
										closeModal={paymentMethodModalClose}>
										<div>
											<div className='pt-5'>
												<IonRadioGroup
													value={payMethod}
													onIonChange={(e) => {
														if (e.detail.value === "Cash") {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.cashText
																} ${sellerName()}\n`
															);
														} else if (
															e.detail.value === "BKash"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.bkashText
																} ${sellerName()}\n${
																	ekkLanguage.createOrder
																		.number
																}: 01701898144 \n${
																	ekkLanguage.createOrder
																		.numberWarning
																}`
															);
														} else if (
															e.detail.value === "Nagad"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.nagadText
																} ${sellerName()}\n${
																	ekkLanguage.createOrder
																		.number
																}: 01701898144\n${
																	ekkLanguage.createOrder
																		.numberWarning
																}`
															);
														} else if (
															e.detail.value === "Islami Bank"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.bankTransferText1
																} ${sellerName()} ${
																	ekkLanguage.createOrder
																		.bankTransferText2
																}:\n${
																	ekkLanguage.createOrder
																		.bankTransferText3
																}: Islami Bank limited\n${
																	ekkLanguage.createOrder
																		.bankTransferText4
																} EkkBaz Bangladesh Pvt. Ltd.\n${
																	ekkLanguage.createOrder
																		.bankTransferText5
																}: 20503110100185612\n${
																	ekkLanguage.createOrder
																		.bankTransferText6
																}: 125274245\n${
																	ekkLanguage.createOrder
																		.bankTransferText7
																}: Islami Bank Motijheel Branch, Dhaka`
															);
														} else if (
															e.detail.value === "Bank Asia"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.bankTransferText1
																} ${sellerName()} ${
																	ekkLanguage.createOrder
																		.bankTransferText2
																}:\n${
																	ekkLanguage.createOrder
																		.bankTransferText3
																}: Bank Asia\n${
																	ekkLanguage.createOrder
																		.bankTransferText4
																} EkkBaz Bangladesh Pvt. Ltd.\n${
																	ekkLanguage.createOrder
																		.bankTransferText5
																}: 04433001328\n${
																	ekkLanguage.createOrder
																		.bankTransferText6
																}: 070274187\n${
																	ekkLanguage.createOrder
																		.bankTransferText7
																}: Moghbazar Branch`
															);
														}
														setPayMethod(e.detail.value);

														let interVal = setTimeout(() => {
															dispatch(
																paymentMethod(e.detail.value)
															);
															dispatch(
																setPaymentMethodText(
																	e.detail.value
																)
															);
															clearTimeout(interVal);
														}, 10);
													}}>
													{/* <IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5'>
														<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															<div className='flex'>
																<img
																	src={delivery}
																	alt='logo'
																/>{" "}
																<span span className='ml-2'>
																	{
																		ekkLanguage.createOrder
																			.cashOnDelivery
																	}
																</span>
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='Cash on Delivery'
															color='success'
														/>
													</IonItem> */}
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5 no-ripple'>
														<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															<div className='flex'>
																<img
																	src={cash}
																	alt='logo'
																	style={{
																		height: 22,
																		width: 35,
																	}}
																/>{" "}
																<span span className='ml-2'>
																	{
																		ekkLanguage.createOrder
																			.cash
																	}
																</span>
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='Cash'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5 no-ripple'>
														<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															<div className='flex'>
																<img src={bkash} alt='logo' />
																<span span className='ml-2'>
																	{" "}
																	{
																		ekkLanguage.createOrder
																			.bkash
																	}
																</span>
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='BKash'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5 no-ripple'>
														<IonLabel className='flex border-0 font-sans text-14 text-black-500 font-medium '>
															<div className='flex'>
																<img src={nogod} alt='logo' />
																<span className='ml-2'>
																	{" "}
																	{
																		ekkLanguage.createOrder
																			.nagad
																	}
																</span>
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='Nagad'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5 no-ripple'>
														<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															<div className='flex'>
																<img
																	src='/assets/images/islami-bank.png'
																	alt='logo'
																	style={{
																		height: 22,
																		width: 26,
																	}}
																/>{" "}
																<span span className='ml-2'>
																	{
																		ekkLanguage.createOrder
																			.islamiBank
																	}
																</span>
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='Islami Bank'
															color='success'
														/>
													</IonItem>
													<IonItem
														lines='none'
														className='bg-white border rounded-[10px] mb-5 no-ripple'>
														<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															<div className='flex'>
																<img
																	src='/assets/images/bank-asia.png'
																	alt='logo'
																	style={{
																		height: 22,
																		width: 26,
																	}}
																/>{" "}
																<span span className='ml-2'>
																	{
																		ekkLanguage.createOrder
																			.bankAsia
																	}
																</span>
															</div>
														</IonLabel>
														<IonRadio
															slot='start'
															value='Bank Asia'
															color='success'
														/>
													</IonItem>
												</IonRadioGroup>
											</div>
										</div>
										<p className='font-normal text-12 text-black-500 px-2'>
											{String(paymentMethodText)
												.split("\n")
												.map((el, i) => {
													return <p key={i}>{el}</p>;
												})}
										</p>
										<div className='text-center pt-4'>
											<IonButton
												color='primary'
												onClick={() =>
													setIsOpenPaymentMethod(false)
												}>
												{ekkLanguage.createOrder.btnLabel}
											</IonButton>
										</div>
									</Card>
								</div>
							</IonModal>
						</IonCol>
					</IonRow>
				</IonGrid>
			</Card>
		</div>
	);
}
