import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonApp,
  IonLoading,
} from "@ionic/react";
import { getLocalUser, setUser, setUserBusiness } from "config/user";
import { useNavigate } from "react-router-dom";
import Auth from "services/Auth";
import { updateToken } from "../../config/SuperFetch";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { BUSINESS_TYPE } from "data/businessType";
import { useDispatch, useSelector } from "react-redux";
import { setSeller } from "redux/container/sellerSlice";

export default function SelectBusiness() {
  const orderType = useSelector((state) => state.seller);
  const [business, setBusiness] = useState(getLocalUser());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);

  async function businessSelectEvent(data) {
    try {
      if (data?.business_status !== "Black Listed") {
        setLoading(true);
        const response = await Auth.businessSelect(data);
        setUserBusiness(response?.business_info);
        dispatch(setSeller(response?.business_info));
        setUser(response?.user);
        localStorage.removeItem("setUser");
        updateToken(response);
        setLoading(false);
        navigate("/home", { replace: true });
      } else {
        window.location.href = "/black-listed";
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (orderType?.multiSupplier) {
      localStorage.removeItem("setUser");
      setPageLoading(false);
      window.location.href = "/order-list";
    } else {
      setPageLoading(false);
    }
  }, []);

  return (
    <IonApp>
      <IonPage className="business-name pt-14">
        {pageLoading ? (
          <IonLoading
            style={{ "--backdrop-opacity": 1 }}
            isOpen={true}
            message={ekkLanguage.orderList?.loader}
          ></IonLoading>
        ) : (
          <IonContent>
            <BackButtonTitle title={ekkLanguage.businessSelect.pageTitle} />
            <div className="pb-4 pt-4">
              {business?.associated_businesses.map((select, i) => {
                BUSINESS_TYPE.forEach((el) => {
                  if (el.type === select.business_type) {
                    select.img = el.image;
                  }
                });
                return (
                  <IonItem
                    key={i}
                    button
                    onClick={() => {
                      businessSelectEvent(select);
                    }}
                    lines="none"
                    className="mb-4"
                  >
                    <div className="flex py-[5px] px-4">
                      <div className="w-12 h-12 p-2 flex justify-center items-center bg-F3F3F3 rounded-[10px] mr-5">
                        <img
                          src={select.img}
                          className="w-7 h-7"
                          alt="busnissLogo"
                        />
                      </div>
                      <IonLabel>
                        <h3 className="text-16 text-black-1000 font-normal">
                          {select?.business_name}
                        </h3>
                        <p className="text-12 text-black-500 font-normal">
                          {select?.business_group} - {select?.business_type} -{" "}
                          {select.role}
                        </p>
                      </IonLabel>
                    </div>
                  </IonItem>
                );
              })}
            </div>
          </IonContent>
        )}
      </IonPage>
    </IonApp>
  );
}
