import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Bug from "assets/images/icon/svg/Bug";
import Delivery from "assets/images/icon/svg/Delivery";
import Walet from "assets/images/icon/svg/Walet";
import { addStatusClass, digitChanger, translator } from "helpers/helper";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function OrderOnwerState({ order }) {
	const [place, setPlace] = useState("808080");
	const [delivered, setDelivered] = useState("808080");
	const [paid, setPaid] = useState("808080");
	const [cancel, setCancel] = useState("808080");
	let status = addStatusClass(order?.order_status);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='mt-9 mb-4'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='text-center mb-7'>
							<h2 className='text-black-1000 text-20 font-bold mb-3'>
								{order?.created_by_business?.business_name}
							</h2>
							<h3 className='text-primary text-20 font-bold mb-3'>
								{order?.country?.currency_symbol_native}
								{digitChanger(order?.total?.toFixed(2))} |{" "}
								{ekkLanguage.orderDetails.remaining} {":"}{" "}
								{order?.country?.currency_symbol_native}
								{digitChanger(
									(order?.total - order?.total_paid).toFixed(2)
								)}
							</h3>
							<p className='text-black-500 text-16 font-bold'>
								{order?.order_origin && "Origin: EkkBaz |"}{" "}
								{ekkLanguage.orderDetails.createdBy}{" "}
								{order?.created_by_user?.full_name}
							</p>
						</div>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size='12'>
						<div className='delievery-state'>
							<ul className='flex items-center justify-between relative'>
								<li
									className={`w-full relative ${
										status === "paid" ? "active" : ""
									} ${status === "delivered" ? "active" : ""}`}>
									<div
										className={`text-center border-2 rounded-lg ${place} flex justify-center items-center bg-white border-primary`}
										style={{ width: 70, height: 70 }}>
										<div>
											<div>
												<Bug />
											</div>
											<p
												className={`text-10 text-primary pt-2 font-bold`}>
												{ekkLanguage.orderDetails.placedLabel}
											</p>
										</div>
									</div>
								</li>
								<li
									className={`w-full relative  ${
										status === "delivered" ? "active" : ""
									} ${status === "paid" ? "active" : ""} 
									 `}>
									<div
										className={`text-center border-2 rounded-lg ${delivered} flex justify-center items-center ${
											status === "delivered" || status === "paid"
												? "bg-white"
												: "bg-F5F5F5"
										} ${
											(status === "delivered" ||
												status === "paid") &&
											"border-primary"
										}`}
										style={{ width: 70, height: 70 }}>
										<div>
											<div>
												<Delivery
													color={`${
														status === "delivered" ||
														status === "paid"
															? "#F94B41"
															: "#00000033"
													}`}
												/>
											</div>
											{/* <p className="text-10 text-primary pt-2 font-bold"> */}
											<p
												className={`text-10 pt-2 font-bold ${
													status === "delivered" ||
													status === "paid"
														? "text-primary"
														: "text-808080"
												}`}>
												{ekkLanguage.orderDetails.deliveredLabel}
											</p>
										</div>
									</div>
								</li>
								<li
									className={`relative  ${
										status === "paid" ? "active" : ""
									}`}>
									<div
										className={`relative text-center border-2 rounded-lg ${paid} flex justify-center items-center ${
											status === "delivered" || status === "paid"
												? "bg-white"
												: "bg-F5F5F5"
										} ${status === "paid" && "border-primary"}`}
										style={{ width: 70, height: 70 }}>
										<div>
											<div>
												<Walet
													color={`${
														status === "paid"
															? "#F94B41"
															: "#00000033 "
													}`}
												/>
											</div>
											{/* <p className="text-10 text-primary pt-2 font-bold"> */}
											<p
												className={`text-10 pt-2 font-bold ${
													status === "paid"
														? "text-primary"
														: "text-808080"
												}`}>
												{ekkLanguage.orderDetails.paidLabel}
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
