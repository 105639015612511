import React from "react";

export default function CreateCustomer() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'>
			<path
				d='M29.3333 27.4666C27.9286 27.4661 26.549 27.0955 25.3333 26.392C24.1175 27.0955 22.7379 27.4661 21.3333 27.4666H18.6666C17.26 27.4649 15.8794 27.0884 14.6666 26.376C13.4539 27.0885 12.0731 27.465 10.6666 27.4666H9.33325C7.92742 27.4662 6.54696 27.0921 5.33325 26.3826V32.8C5.33325 33.8608 5.75468 34.8783 6.50482 35.6284C7.25497 36.3786 8.27239 36.8 9.33325 36.8H30.6666C31.7275 36.8 32.7449 36.3786 33.495 35.6284C34.2452 34.8783 34.6666 33.8608 34.6666 32.8V26.384C33.4525 27.0924 32.0722 27.466 30.6666 27.4666H29.3333Z'
				fill='#86C983'
			/>
			<path
				d='M26.6667 4.80005V12.8H24V4.80005H16V12.8H13.3333V4.80005H6.93333L4.032 17.844L4 19.4667C4 20.8812 4.5619 22.2378 5.5621 23.238C6.56229 24.2381 7.91885 24.8 9.33333 24.8H10.6667C11.4265 24.7976 12.177 24.6318 12.8671 24.3139C13.5573 23.9959 14.171 23.5333 14.6667 22.9574C15.1623 23.5333 15.7761 23.9959 16.4662 24.3139C17.1564 24.6318 17.9068 24.7976 18.6667 24.8H21.3333C22.0904 24.8004 22.8389 24.6392 23.5287 24.3272C24.2185 24.0152 24.8337 23.5596 25.3333 22.9907C25.8329 23.5596 26.4482 24.0152 27.138 24.3272C27.8278 24.6392 28.5762 24.8004 29.3333 24.8H30.6667C32.0812 24.8 33.4377 24.2381 34.4379 23.238C35.4381 22.2378 36 20.8812 36 19.4667V18.1334L33.0667 4.80005H26.6667Z'
				fill='#86C983'
			/>
		</svg>
	);
}
