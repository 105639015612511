import React from "react";

export default function Supplier() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<rect width='18' height='18' fill='#434343' />
			<g id='create new order from' clip-path='url(#clip0_1050_39)'>
				<rect
					width='360'
					height='1049'
					transform='translate(-41 -176)'
					fill='#F2F3F7'
				/>
				<rect
					id='Rectangle 1844'
					x='-25'
					y='-66'
					width='328'
					height='110'
					rx='10'
					fill='white'
				/>
				<g id='Rectangle 2044' filter='url(#filter0_d_1050_39)'>
					<rect
						x='-15'
						y='-11'
						width='308'
						height='40'
						rx='10'
						fill='#F3F3F3'
					/>
					<rect
						x='-14.75'
						y='-10.75'
						width='307.5'
						height='39.5'
						rx='9.75'
						stroke='#FF564C'
						strokeWidth='0.5'
					/>
				</g>
				<g id='delivery solid 4'>
					<g id='Group'>
						<path
							id='Vector'
							d='M3.86254 12H1.59754L1.50754 12.5229C1.47754 12.7114 1.52254 12.9086 1.62754 13.0543C1.73254 13.2 1.89754 13.2857 2.06254 13.2857H3.78004C3.72103 12.8556 3.74926 12.4157 3.86254 12ZM11.8725 3.24003C11.8211 3.16443 11.7551 3.10343 11.6796 3.0618C11.6042 3.02017 11.5213 2.99903 11.4375 3.00003H3.56254C3.43154 3.00103 3.30485 3.05356 3.20377 3.14879C3.10268 3.24401 3.03339 3.37612 3.00754 3.52289L2.95504 3.85717H4.68754C5.40754 3.85717 6.00004 4.53431 6.00004 5.35717C6.00004 5.90574 5.73754 6.37717 5.35504 6.64288C5.73754 6.90859 6.00004 7.38002 6.00004 7.92859C6.00004 8.47716 5.73754 8.94859 5.35504 9.2143C5.58754 9.37716 5.77504 9.61716 5.88754 9.90858C6.26684 9.82727 6.65715 9.84243 7.03075 9.95298C7.40434 10.0635 7.75205 10.2667 8.04916 10.5482C8.34627 10.8297 8.5855 11.1825 8.74984 11.5815C8.91418 11.9806 8.9996 12.4161 9.00004 12.8571C9.00004 13.0029 8.99254 13.1486 8.97004 13.2857H9.75004C10.0125 13.2857 10.245 13.0714 10.2975 12.78L11.985 3.78003C12.0225 3.59146 11.985 3.39432 11.8725 3.24003ZM14.4375 5.57145H12.8325C12.7036 5.5716 12.5786 5.62168 12.4778 5.71353C12.377 5.80538 12.3064 5.93357 12.2775 6.07717L11.0775 12.5057C11.04 12.6943 11.0775 12.8914 11.19 13.0457C11.295 13.2 11.4525 13.2857 11.625 13.2857H12.03C12.0075 13.1486 12 13.0029 12 12.8571C12 11.2029 13.1775 9.85715 14.625 9.85715C16.0725 9.85715 17.25 11.2029 17.25 12.8571C17.25 12.9514 17.25 13.0371 17.235 13.1314C17.3325 13.0457 17.4 12.9257 17.4225 12.78L17.985 9.78001C17.9925 9.73715 18 9.68573 18 9.64287C18 7.39716 16.4025 5.57145 14.4375 5.57145Z'
							fill='#FF564C'
						/>
						<path
							id='Vector_2'
							d='M4.6875 6H2.0625C1.91332 6 1.77024 5.93228 1.66475 5.81172C1.55926 5.69116 1.5 5.52765 1.5 5.35715C1.5 5.18665 1.55926 5.02314 1.66475 4.90258C1.77024 4.78202 1.91332 4.71429 2.0625 4.71429H4.6875C4.83668 4.71429 4.97976 4.78202 5.08525 4.90258C5.19074 5.02314 5.25 5.18665 5.25 5.35715C5.25 5.52765 5.19074 5.69116 5.08525 5.81172C4.97976 5.93228 4.83668 6 4.6875 6ZM4.6875 8.57142H1.3125C1.16332 8.57142 1.02024 8.5037 0.914752 8.38314C0.809263 8.26258 0.75 8.09907 0.75 7.92857C0.75 7.75807 0.809263 7.59456 0.914752 7.474C1.02024 7.35344 1.16332 7.28571 1.3125 7.28571H4.6875C4.83668 7.28571 4.97976 7.35344 5.08525 7.474C5.19074 7.59456 5.25 7.75807 5.25 7.92857C5.25 8.09907 5.19074 8.26258 5.08525 8.38314C4.97976 8.5037 4.83668 8.57142 4.6875 8.57142ZM4.6875 11.1428H0.5625C0.413316 11.1428 0.270242 11.0751 0.164752 10.9546C0.0592632 10.834 0 10.6705 0 10.5C0 10.3295 0.0592632 10.166 0.164752 10.0454C0.270242 9.92486 0.413316 9.85713 0.5625 9.85713H4.6875C4.83668 9.85713 4.97976 9.92486 5.08525 10.0454C5.19074 10.166 5.25 10.3295 5.25 10.5C5.25 10.6705 5.19074 10.834 5.08525 10.9546C4.97976 11.0751 4.83668 11.1428 4.6875 11.1428ZM14.625 15C13.5915 15 12.75 14.0391 12.75 12.8571C12.75 11.6751 13.5915 10.7143 14.625 10.7143C15.6585 10.7143 16.5 11.6751 16.5 12.8571C16.5 14.0391 15.6585 15 14.625 15ZM6.375 15C5.3415 15 4.5 14.0391 4.5 12.8571C4.5 11.6751 5.3415 10.7143 6.375 10.7143C7.4085 10.7143 8.25 11.6751 8.25 12.8571C8.25 14.0391 7.4085 15 6.375 15Z'
							fill='#FF564C'
						/>
					</g>
				</g>
			</g>
			<defs>
				<filter
					id='filter0_d_1050_39'
					x='-25'
					y='-18'
					width='328'
					height='60'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='5' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_1050_39'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_1050_39'
						result='shape'
					/>
				</filter>
				<clipPath id='clip0_1050_39'>
					<rect
						width='360'
						height='1049'
						fill='white'
						transform='translate(-41 -176)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
