import {
	IonApp,
	IonContent,
	IonHeader,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Customer from "components/mobile-components/CustomerList/Customer";
import CustomerSearch from "components/mobile-components/CustomerList/CustomerSearch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Business from "services/Business";
import BottomSheet from "components/mobile-components/MyCustomer/BottomSheet";
import { getUserBusiness } from "config/user";

export default function CustomerListPage() {
	const orderType = useSelector((state) => state.seller);
	const sellerId = orderType?.multiSupplier
		? orderType.data.id
		: getUserBusiness().id;
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [customerList, setCustomerList] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedCustomer, setSelectedCustomer] = useState("");
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [tempSearchParams, setTempSearchParams] = useState("");
	const location = useSelector((state) => state.user.locationData);

	async function allCustomerList() {
		setLoading(true);
		setPageCount(2);
		if (searchQ) {
			const response = await Business.getNearbyCustomer(
				sellerId,
				location.coordinates[1],
				location.coordinates[0],
				searchQ
			);
			if (response.status === 200) {
				setLoading(false);
				setCustomerList(response.results);
			} else {
				setLoading(false);
			}
		} else {
			try {
				const response = await Business.getNearbyCustomers(
					sellerId,
					location.coordinates[1],
					location.coordinates[0]
				);
				if (response.status === 200) {
					setLoading(false);
					setCustomerList(response.results);
				} else {
					setLoading(false);
				}
			} catch (error) {
				console.log(error);
			}
		}
	}

	async function fetchCustomer(s) {
		setTempSearchParams(s);
		try {
			const response = await Business.getNearbyCustomer(
				sellerId,
				location.coordinates[1],
				location.coordinates[0],
				s
			);
			if (response.status === 200) {
				setLoading(false);
				setCustomerList(response.results);
			} else {
				setLoading(false);
				setCustomerList([]);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// useEffect(() => {
	// 	// allCustomerList()
	// 	myCustomerList();
	// }, []);

	useEffect(() => {
		if (searchQ === "") {
			setTempSearchParams("");
			allCustomerList();
		}
	}, [searchQ]);

	let lodeMoreData = async () => {
		if (searchQ) {
			const response = await Business.getNearbyCustomer(
				sellerId,
				location.coordinates[1],
				location.coordinates[0],
				searchQ,
				pageCount
			);
			if (response.status === 200) {
				setPageCount((prev) => prev + 1);
				setCustomerList([...customerList, ...response.results]);
			} else {
				setLoading(false);
			}
		} else {
			const response = await Business.getNearbyCustomers(
				sellerId,
				location.coordinates[1],
				location.coordinates[0],
				pageCount
			);
			if (response.status === 200) {
				setPageCount((prev) => prev + 1);
				setCustomerList([...customerList, ...response.results]);
			} else {
				setLoading(false);
			}
		}
	};
	useEffect(() => {
		allCustomerList();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonHeader>
					<BackButtonTitle
						title={ekkLanguage.customerList.pageTitle}
						from='myCustomer'
					/>
				</IonHeader>
				<IonContent>
					<section className='pt-14'>
						<CustomerSearch
							setSearchQ={setSearchQ}
							searchQ={searchQ}
							fetchCustomer={fetchCustomer}
							placeholder={ekkLanguage.customerList.searchPlaceHolder}
						/>
						<p className='px-4 pt-2'>
							{" "}
							{tempSearchParams && (
								<span>
									search:{" "}
									<span className='font-bold'>
										{" "}
										{tempSearchParams}
									</span>
								</span>
							)}
						</p>

						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.businessLocation.loader}
							/>
						) : (
							<>
								<Customer
									customerList={customerList}
									selectedCustomer={selectedCustomer}
									setSelectedCustomer={setSelectedCustomer}
								/>
							</>
						)}
					</section>
					<BottomSheet toRedirect='customers-list' />
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							lodeMoreData();
							setTimeout(() => ev.target.complete(), 500);
						}}>
						<IonInfiniteScrollContent></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
