import { Button } from "components/global-components/common/button";
import { Input } from "components/global-components/common/Input";
import React, { useState } from "react";
import Box from "./Box";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { loginFromSignUp } from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";

export default function Registration({
	loading,
	phoneNo,
	country,
	countryCode,
}) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState();
	const [error, setError] = useState("");
	const [nameError, setNameError] = useState("");
	const [lengthError, setLengthError] = useState("");
	const history = useNavigate();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let profileCreateHandler = async () => {
		if (name.length > 3) {
			if (password.length > 3 && confirmPassword.length > 3) {
				if (!(password && confirmPassword !== password)) {
					let req_body = {
						phone_number: (countryCode + phoneNo).trim(),
						full_name: name,
						email: email,
						password: password,
						country: country,
						user_type: "Business User",
					};
					try {
						const response = await Auth.createNewUser(req_body);
						if (response.status === 200) {
							updateToken(response);
							setLocalUser(response?.data);
							dispatch(loginFromSignUp(response));
							history("/");
							toast.success(ekkLanguage.registration.successMessage);
							localStorage.setItem("auth", true);
						} else {
						}
					} catch (error) {
						console.log(error);
						toast.error(ekkLanguage.registration.errorMessage);
					}
				} else {
					setError(ekkLanguage.registration.passNotMatch);
				}
			} else {
				setLengthError(ekkLanguage.registration.passLengthValidLabel);
			}
		} else {
			setNameError(ekkLanguage.registration.validationLabel);
		}
	};

	return (
		<Box>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					profileCreateHandler();
				}}>
				<Input
					onChange={(event) => setName(event.target.value)}
					type='text'
					label={ekkLanguage.registration.nameLabel}
					placeholder={ekkLanguage.registration.namePlaceholder}
					required
				/>
				{nameError && (
					<span className='text-primary mt-1'>{nameError}</span>
				)}
				<Input
					onChange={(event) => setEmail(event.target.value)}
					type='email'
					label={ekkLanguage.registration.emailLabel}
					placeholder={ekkLanguage.registration.emailPlaceholder}
				/>
				<Input
					onChange={(event) => setPassword(event.target.value)}
					type='password'
					label={ekkLanguage.registration.passwordLabel}
					placeholder={ekkLanguage.registration.passwordPlaceholder}
					required
				/>
				{lengthError && (
					<span className='text-primary mt-1'>{lengthError}</span>
				)}
				<Input
					onChange={(event) => setConfirmPassword(event.target.value)}
					type='password'
					label={ekkLanguage.registration.confirmPassLabel}
					placeholder={ekkLanguage.registration.confirmPassPlaceholder}
					required
				/>
				{error && <span className='text-primary mt-1'>{error}</span>}

				<div className='flex justify-center py-4'>
					<Button
						title={ekkLanguage.registration.btnLabel}
						loading={loading}
						// onClick={profileCreateHandler}
						propsRef={(input) => input}
						type='submit'
					/>
				</div>
			</form>
		</Box>
	);
}
