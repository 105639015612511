import { IonInput } from "@ionic/react";
import React, { memo } from "react";

function OTPInput({ otp, ekkLanguage, setOtp }) {
	return (
		<input
			type='number'
			value={otp}
			placeholder={ekkLanguage.otpsubmit.otpPlaceholder}
			onChange={(e) => setOtp(e.target.value)}
			clearInput></input>
	);
}
export default OTPInput;
