export function shoppingListItem() {
	return JSON.parse(localStorage.getItem("cart")) || [];
}
export function saveCart(cart) {
	// this.toralAmount();
	localStorage.setItem("cart", JSON.stringify(cart));
}
export function cartPlus(cartProduct) {
	const cart = shoppingListItem();
	cart.forEach((product) => {
		if (product.id === cartProduct.id) {
			product.quantity = ++cartProduct.quantity;
			//console.log(++product.qyt);
			saveCart(cart);
			//
		}
	});
}
export function setQuantity(cartProduct, quantity) {
	const cart = shoppingListItem();
	cart.forEach((product) => {
		if (product.id === cartProduct.id) {
			product.quantity = quantity;
			//console.log(++product.qyt);
			saveCart(cart);
			//
		}
	});
}
export function cartMinus(cartProduct) {
	const cart = shoppingListItem();
	cart.forEach((product) => {
		if (product.id === cartProduct.id) {
			product.quantity = --cartProduct.quantity;
			//console.log(++product.qyt);
			saveCart(cart);
			//
		}
	});
}
function checkItem(item) {
	return shoppingListItem().filter((element) => element.id === item.id);
}

export function productAddToCart(product, quantity, selectedQty) {
	if (checkItem(product).length === 0) {
		let itemFormate = addCartFormate(product, selectedQty);
		const cart = shoppingListItem();
		cart.push(itemFormate);
		saveCart(cart);
		return true;
	} else {
		return false;
	}
}

export function removeCartProduct(item) {
	const product = shoppingListItem().filter(
		(element) => element.id !== item.id
	);
	saveCart(product);
}

export function cartTotalAmount() {
	let amount = 0;
	shoppingListItem().forEach((product) => {
		amount =
			amount + parseFloat(product.base_price) * parseFloat(product.quantity);
	});
	return amount;
}

export function cartTotalTax() {
	let amount = 0;
	shoppingListItem().forEach((product) => {
		amount = amount + product.tax;
	});
	return amount;
}

function addCartFormate(item, quantity = 1) {
	return {
		id: item.id,
		title: item.title,
		type: item.type,
		base_price: item.base_price,
		consumer_price: item.consumer_price,
		seller_business_id: item.seller_business_id,
		quantity: quantity,
		country: item.country,
		name: item.name,
		description: item.description,
		images: item.images,
		weight: item.weight,
		weight_unit: item.weight_unit,
		barcode: item.barcode,
		sku: item.sku,
		dimension_unit: item.dimension_unit,
		height: item.height,
		width: item.width,
		length: item.length,
		pack_type: item.pack_type,
		manufacturing_country: item.manufacturing_country,
		manufactured_by_business_name: item.manufactured_by_business_name,
		distributed_by_business_name: item.distributed_by_business_name,
		imported_by_business_name: item.imported_by_business_name,
		currency: item.currency,
		tax: item.tax,
		minimum_order_quantity: item.minimum_order_quantity,
		calculated_price: item.calculated_price,
		out_of_stock: item.out_of_stock,
		discount_id: item.discount_id,
		seller_business_name: item.seller_business_name,
		seller_business_address: item.seller_business_address,
		seller_business_phone_number: item.seller_business_phone_number,
		inventory_quantity: item.inventory_quantity,
	};
}
