import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Business = {
  getBusiness: (id) => {
    return SuperFetch(`${baseUrl.business}/v4/businesses/${id}`);
  },
  // getBranchList: (page = 1, limit = "50") => {
  // 	return SuperFetch(
  // 		`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
  // 	);
  // },
  getBranchList: (page = 1, limit = "50") => {
    return SuperFetch(
      `${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
    );
  },
  getPaymentData: (id) => {
    return SuperFetch(
      `${baseUrl.business}/v4/businesses/bank-info/details/${id}`
    );
  },
  updateUserBusiness: (id, query) => {
    return SuperFetch(`${baseUrl.business}/v4/businesses/${id}`, {
      method: "PUT",
      data: query,
    });
  },
  getCustomers: (relationId, page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/business_relations/?relation_type=${relationId}&page=${page}&limit=${limit}`
    );
  },
  getCustomer: (relationId, query = "", page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/business_relations/search/?relation_type=${relationId}&page=${page}&limit=${limit}&q=${query}`
    );
  },
  getAllCustomers: (latitude, longitude, page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/businesses?latitude=${latitude}&longitude=${longitude}&page=${page}&limit=${limit}`
    );
  },
  customersSearch: (latitude, longitude, query, page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/businesses/search/?latitude=${latitude}&longitude=${longitude}&page=${page}&term=${query}&limit=${limit}`
    );
  },
  customerCheck: (phoneNo, sellerId) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/businesses/customer_check/${phoneNo}/?seller_id=${sellerId}`
    );
  },
  createNewBusiness: (query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/business_relations/create/`, {
      method: "POST",
      data: query,
    });
  },
  getRelationList: () => {
    return SuperFetch(`${baseUrl.BizNpdt}/business_relation_types/`);
  },
  getBusinessType: () => {
    return SuperFetch(
      `${baseUrl.ekkbazBizNpdt}/business_types/?page=1&limit=100`
    );
  },
  getNearbyCustomers: (sellerId, long, lati, page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/nearby_customer/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&seller_id=${sellerId}`
    );
  },
  getNearbyCustomer: (sellerId, long, lati, query, page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/nearby_customer/search/?longitude=${long}&latitude=${lati}&q=${query}&page=${page}&limit=${limit}&seller_id=${sellerId}`
    );
  },

  getNearbySuppliers: (long, lati, page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller_list/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}`
    );
  },
  getNearbySupplier: (long, lati, query, page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller_list/search/?longitude=${long}&latitude=${lati}&tearm=${query}&page=${page}&limit=${limit}`
    );
  },
};
export default Business;
