import { createSlice } from "@reduxjs/toolkit";

const sellerSlice = createSlice({
	name: "seller",
	initialState: {
		data: {},
		sellerType: "",
		multiSupplier: false,
	},
	reducers: {
		setSellerType: (state, action) => {
			state.sellerType = action.payload;
		},
		setSeller: (state, action) => {
			state.data = action.payload;
		},
		setMultiSupplier: (state, action) => {
			state.multiSupplier = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = sellerSlice;

export const setSellerType = (values) => async (dispatch) => {
	dispatch(actions.setSellerType(values));
};
export const setSeller = (values) => async (dispatch) => {
	dispatch(actions.setSeller(values));
};
export const setMultiSupplier = (values) => async (dispatch) => {
	dispatch(actions.setMultiSupplier(values));
};
// Export the reducer, either as a default or named export
export default reducer;
