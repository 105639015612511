export const DEVELOPMENT = {
	pass: "https://dpassapi.azurewebsites.net",
	business: "https://dbusiness.ekkbaz.com",
	product: "https://dproduct.ekkbaz.com",
	order: "https://dorderapi.azurewebsites.net",
	bucket: "https://dfileapi.azurewebsites.net",
	BizNpdt: "https://dbiznpdtapi.azurewebsites.net/ekkhero",
	ekkbazBizNpdt: "https://dbiznpdtapi.azurewebsites.net/ekkbaz",
	social: "https://dsocialapi.azurewebsites.net",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	ekkhero: "https://eh2t.azurewebsites.net",
};

// export var baseUrl = DEVELOPMENT;

export const PRODUCTION = {
	pass: "https://ppassapi.azurewebsites.net",
	business: "https://pbusiness.ekkbaz.com",
	product: "https://pproduct.ekkbaz.com",
	order: "https://porderapi.azurewebsites.net",
	bucket: "https://pfileapi.azurewebsites.net",
	BizNpdt: "https://pbiznpdtapi.azurewebsites.net/ekkhero",
	ekkbazBizNpdt: "https://pbiznpdtapi.azurewebsites.net/ekkbaz",
	social: "https://psocialapi.azurewebsites.net",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	ekkhero: "https://ekkhero.ekkbaz.com",
};
export var baseUrl = PRODUCTION;
