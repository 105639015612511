import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setPaymentMethodText } from "redux/container/paymentMethodSlice";

export default function CongratulationPage() {
	let { id } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const scinario = useSelector((state) => state.seller);
	let dispatch = useDispatch();
	const paymentMethod = useSelector(
		(state) => state.payment.paymentMethodText
	);
	const orderNumber = useSelector(
		(state) => state.order?.singleOrder?.order_id
	);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congratulateion'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.createOrder.cosgressTitle}
									</h2>
									<p className='text-20 text-black-500 font-semibold mb-5'>
										{ekkLanguage.createOrder.cosgressPara}
									</p>
									<p
										className='text-14 text-black-500 font-semibold mb-12 px-6'
										style={{ color: "#A6A6A6" }}>
										{ekkLanguage.createOrder.congressCancelText}
									</p>
									{paymentMethod === "Bank Asia" && (
										<div className='text-left px-4 pb-7'>
											<p className='text-14 text-black-500 font-semibold mb-2'>
												Bank Transfer - Pay to “EkkBaz Bangladesh”
												with this Order Number “{id}” as reference.
											</p>
											<ul>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.bankName}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														Bank Asia
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.accountName}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														EkkBaz Bangladesh Pvt. Ltd.
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{
															ekkLanguage.bankDetails
																.accountNumber
														}
														:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														044 3300 1328
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.routeNumber}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														070 274 187
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.branch}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														Mogbazar Branch
													</p>
												</li>
											</ul>
										</div>
									)}
									{paymentMethod === "Islami Bank" && (
										<div className='text-left px-4 pb-7'>
											<p className='text-14 text-black-500 font-semibold mb-2'>
												Bank Transfer - Pay to “EkkBaz Bangladesh”
												with this Order Number “{id}” as reference.
											</p>
											<ul>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.bankName}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														Islami Bank limited
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.accountName}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														EkkBaz Bangladesh Pvt. Ltd.
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{
															ekkLanguage.bankDetails
																.accountNumber
														}
														:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														20503110100185612
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.routeNumber}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														125274245
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.branch}:
													</p>
													<p className='text-14 text-black-500 font-semibold'>
														Islami Bank Motijheel Branch, Dhaka
													</p>
												</li>
											</ul>
										</div>
									)}
									{paymentMethod === "BKash" && (
										<div className='text-left px-4 pb-7'>
											<ul>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.createOrder.payTo}:
													</p>
													<p className='text-14 text-black-500 font-bold'>
														Bkash{" "}
														<span className='font-semibold'>
															{
																ekkLanguage.createOrder
																	.merchantNumber
															}
														</span>
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.number}:
													</p>
													<p className='text-14 text-black-500 font-bold'>
														01701898144
													</p>
												</li>
											</ul>
											<p className='text-14 text-black-500 font-semibold'>
												{ekkLanguage.createOrder.numberWarning}
											</p>
										</div>
									)}
									{paymentMethod === "Nagad" && (
										<div className='text-left px-4 pb-7'>
											<ul>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.createOrder.payTo}:
													</p>
													<p className='text-14 text-black-500 font-bold'>
														Nagad{" "}
														<span className='font-semibold'>
															{
																ekkLanguage.createOrder
																	.merchantNumber
															}
														</span>
													</p>
												</li>
												<li className='flex mb-1'>
													<p className='text-14 text-black-500 font-semibold w-1/3'>
														{ekkLanguage.bankDetails.number}:
													</p>
													<p className='text-14 text-black-500 font-bold'>
														01701898144
													</p>
												</li>
											</ul>
											<p className='text-14 text-black-500 font-semibold'>
												{ekkLanguage.createOrder.numberWarning}
											</p>
										</div>
									)}
									<Link
										to={`${scinario.sellerType === "multiple" &&
										scinario.multiSupplier ? "/order-list": "/home"}`}
										className='w-full'
										onClick={() =>
											dispatch(setPaymentMethodText(""))
										}>
										<IonButton
											className='rounded-[10px] h-12 w-full text-white font-bold bg-primary'
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.2)",
											}}>
											{ekkLanguage.createOrder.congressProductLabel}
										</IonButton>
									</Link>
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
