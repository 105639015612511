import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Orders from "services/Orders";
export const STATUSES = Object.freeze({
	IDLE: "idle",
	ERROR: "error",
	LOADING: "loading",
});
export const orderSlice = createSlice({
	name: "order",
	initialState: {
		data: [],
		singleOrder: {},
		status: STATUSES.IDLE,
		page: 1,
		limit: 20,
		selectedStatus: "",
	},
	reducers: {
		initOrder(state, action) {
			state.data = action.payload;
			state.page = 2;
		},
		setOrder(state, action) {
			state.data = action.payload;
			state.page = state.page + 1;
		},
		singleOrder(state, action) {
			state.singleOrder = action.payload;
		},
		setStatus(state, action) {
			state.status = action.payload;
		},
		setSelectedStatus(state, action) {
			state.selectedStatus = action.payload;
		},
		singleOrderUpdate(state, action) {
			state.singleOrder = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setOrder, setStatus, initOrder, setSelectedStatus } =
	orderSlice.actions;
export const { actions, reducer } = orderSlice;
export default reducer;

//data fetch function
export function fetchOrder(value, page) {
	return async function fetchProductThunk(dispatch, getState) {
		dispatch(setSelectedStatus(value));
		dispatch(setStatus(STATUSES.LOADING));
		let orderType = getState();

		try {
			const res = await Orders.getOrderByUser(
				page ?? 1,
				getState().order.limit,
				value
			);
			page
				? dispatch(setOrder([...getState().order.data, ...res.data]))
				: dispatch(initOrder(res.data));

			dispatch(setStatus(STATUSES.IDLE));

			return { ...res };
		} catch (err) {
			console.log(err);
			dispatch(setStatus(STATUSES.ERROR));
		}
	};
}
//data fetch function
export function fetchOrderListBySorted(page, limit) {
	return async function fetchProductThunk(dispatch, getState) {
		dispatch(setStatus(STATUSES.LOADING));
		try {
			const res = await Orders.orderListBySorted(page, limit);
			//   const data = await res.json();
			dispatch(setOrder(res.data));
			dispatch(setStatus(STATUSES.IDLE));
			return { ...res };
		} catch (err) {
			console.log(err);
			dispatch(setStatus(STATUSES.ERROR));
		}
	};
}
//data fetch function
export function fetchOrderSingle(orderId) {
	return async function fetchProductThunk(dispatch, getState) {
		dispatch(setStatus(STATUSES.LOADING));
		try {
			const res = await Orders.singleOrder(orderId);
			if (res.status === 200) {
				dispatch(actions.singleOrder(res.data));
				dispatch(setStatus(STATUSES.IDLE));
			}
		} catch (err) {
			console.log(err);
			dispatch(setStatus(STATUSES.ERROR));
		}
	};
}
export const searchOrder = (id, limit, page) => async (dispatch, getState) => {
	try {
		const response = await Orders.searchOrder(id, limit, page);
		if (response?.status === 200) {
			dispatch(setOrder(response.data));
		}
		return response;
	} catch (error) {
		return error.errorData;
	}
};

export const fetchOderCollectionAmount = (id) => async (dispatch, getState) => {
	try {
		let response = await Orders.orderAmountCollection(id);

		if (response?.status === 200) {
			dispatch(
				actions.singleOrderUpdate({
					...getState().order?.singleOrder,
					collection: response.data,
				})
			);
			// dispatch(setOrder(response.data));
		}
		return response;
	} catch (error) {
		return error.errorData;
	}
};
