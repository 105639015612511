import { IonCol, IonGrid, IonRow, IonCheckbox, IonLoading } from "@ionic/react";
import { getProductSeller, getUserBusiness } from "config/user";
import {
	cartTotalAmount,
	cartTotalTax,
	productAddToCart,
	removeCartProduct,
	shoppingListItem,
} from "helpers/shoppingCart";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { subtotalAmount, totalAmount } from "redux/container/shoppingCartSlice";
import { digitChanger } from "helpers/helper";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { useNavigate } from "react-router-dom";

export default function ProductList({
	productList,
	setProductList,
	productCartList,
	setProductCartList,
	loading,
}) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const creditFee = useSelector((state) => state.payment.creditFee);
	const [open, isOpen] = useState(false);
	const onOpen = () => isOpen(true);
	const onClose = () => isOpen(false);
	const navigate = useNavigate();

	// function add product
	function addProductOnCart(product) {
		// product.seller_business_id = getProductSeller().seller_business_id;
		if (productCartList.find((element) => element.id === product.id)) {
			removeCartProduct(product);
			setProductCartList(shoppingListItem);
			dispatch(totalAmount(cartTotalAmount()));
			dispatch(subtotalAmount(cartTotalAmount()));
		} else {
			productAddToCart(
				product,
				product.quantity,
				product.minimum_order_quantity
			);
			setProductCartList(shoppingListItem);
		}
	}
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	console.log(creditFee);
	return (
		<div className='px-4' style={{ paddingBottom: 130 }}>
			<IonGrid className='bg-white rounded-md'>
				{loading ? (
					<IonLoading
						isOpen={loading}
						message={ekkLanguage.orderList.loader}
					/>
				) : (
					<IonRow>
						{productList?.length ? (
							productList?.map((el, index) => {
								const imageChecker = () => {
									if (el.images[0]) {
										return `${el.images[0]}`;
									} else {
										if (el.type === "variant") {
											return "/assets/images/varient_default.png";
										} else if (el.type === "pack") {
											return "/assets/images/pack_default.png";
										} else if (el.type === "offer") {
											return "/assets/images/offer_default.png";
										}
									}
								};
								return (
									<IonCol
										size='6'
										sizeSm='4'
										className='mb-6'
										key={index}>
										<div className='relative overflow-hidden'>
											<div
												className='text-center flex items-center '
												onClick={() => {
													if (el.inventory_quantity >= 1) {
														addProductOnCart(
															el,
															el.minimum_order_quantity
														);
														dispatch(
															totalAmount(cartTotalAmount())
														);
														dispatch(
															subtotalAmount(cartTotalAmount())
														);
													} else {
														onOpen();
													}
												}}>
												<div className='product-image-sizer relative rounded-[10px] flex pb-[5px] m-auto'>
													<span
														className='absolute'
														style={{ top: -1, left: -10 }}>
														{productCartList.find(
															(element) => element.id === el.id
														) && (
															<IonCheckbox
																style={{ "--size": "24px" }}
																size='small'
																color='success'
																className='rounded select-product'
																checked={true}
															/>
														)}
													</span>
													{Number(el?.profit) > 0 && (
														<div className='flex absolute w-auto h-[24px] border-2 border-white bg-success items-center justify-center rounded-[10px]  top-0 right-0'>
															<span className='ml-[5px] font-bold text-12 text-white px-1'>
																{el?.country &&
																	el?.country
																		?.currency_symbol_native}
																{digitChanger(
																	Number(el?.profit).toFixed(2)
																)}
															</span>
														</div>
													)}

													<img
														className='w-full h-full object-contain flex m-auto mb-[5px]'
														src={imageChecker()}
														alt='icon'
													/>
												</div>
											</div>
											<h5 className='font-bold text-14 text-primary mb-[5px]'>
												{el?.country &&
													el?.country?.currency_symbol_native}
												{digitChanger(
													Number(el?.base_price).toFixed(2)
												)}
												{/* <span className='text-10 font-normal text-808080 ml-2 line-through'>
													{el?.country &&
														el?.country?.currency_symbol_native}
													{digitChanger(
														Number(el?.base_price).toFixed(2)
													)}
												</span> */}
											</h5>
											<h6 className='font-semibold text-14 text-262626-1000 mb-[5px] min-h-[36px]'>
												{el?.name}
											</h6>
											<div className='flex justify-between items-center mr-4'>
												<div className='flex justify-between items-center w-full'>
													<h6 className='font-semibold text-14 text-black-500'>
														{el?.weight}{" "}
														<span>{el?.weight_unit}</span>
													</h6>
													<div className='flex items-center'>
														<img
															src='assets/images/warehouse.png'
															alt='icon'
														/>
														<spa className='text-14 font-bold text-primary ml-[10px]'>
															{el?.inventory_quantity}
														</spa>
													</div>
												</div>
											</div>
										</div>
									</IonCol>
								);
							})
						) : (
							<>
								<IonCol size='2'></IonCol>
								<IonCol size='8'>
									<div
										className='text-center flex items-center justify-center'
										style={{ height: "calc(100vh - 420px)" }}>
										<div>
											<img
												className='m-auto mb-[10px] mt-5'
												src='/assets/images/not-found-file.png'
												alt='emptyCard'
											/>
											<p className='text-16 font-normal text-222222 mb-[35px]'>
												{ekkLanguage.orderList.notFound}
											</p>
										</div>
									</div>
								</IonCol>
							</>
						)}
					</IonRow>
				)}
			</IonGrid>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={open}
				onRequestClose={onClose}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.createOrder.importentMessage}
					</h6>
					<h6 className='text-16 font-semibold pb-[20px]'>
						{ekkLanguage.createOrder.outOffStock}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onClose}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
