import { IonCheckbox, IonCol, IonGrid, IonRow } from "@ionic/react";
import { getProductSeller, setProductSeller } from "config/user";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSeller } from "redux/container/sellerSlice";
import { Modal } from "react-responsive-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { shoppingListItem } from "helpers/shoppingCart";
import {
	creditFee,
	paymentMethod,
	paymentTerm,
	setPaymentMethodText,
	setPaymentName,
	deliveryDate,
} from "redux/container/paymentMethodSlice";

export default function SupplierList({ supplierList, setSelectedSupplier }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const sellerInfo = useSelector((state) => state.seller);
	let navigate = useNavigate();
	let { pathname } = useLocation();
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const productList = shoppingListItem();
	const onOpenModal = () => {
		setIsOpen(true);
	};
	const [sellerSelect, setSellerSelect] = useState({});

	const onCloseModal = () => {
		setIsOpen(false);
	};

	return (
		<>
			<IonGrid>
				<IonRow>
					{supplierList.length ? (
						supplierList.map((el, i) => {
							return (
								<IonCol
									size='6'
									key={i}
									onClick={() => {
										setSellerSelect(el);
										if (productList.length) {
											onOpenModal();
										} else {
											navigate("/create-order");
											dispatch(setSeller(el));
										}
									}}>
									<div
										className='bg-white rounded-[10px] px-[10px] py-[10px]'
										style={{
											boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)",
										}}>
										{/* {pathname === "/select-supplier" && ( */}
										<span
											className='absolute'
											style={{ top: 5, right: 5 }}>
											{el?.id === sellerInfo.data?.id && (
												<IonCheckbox
													color='success'
													className='rounded select-product'
													checked={true}
												/>
											)}
										</span>
										{/* )} */}

										<h5 className='font-bold text-14 text-black-1000 pb-[5px] h-12'>
											{el?.business_name}
										</h5>
										<p className='font-normal text-12 text-black-500 h-12'>
											{String(el?.address_line).length > 65
												? String(el?.address_line).slice(0, 60) +
												  "..."
												: String(el?.address_line)}
										</p>
										<p className='flex '>
											<img src='assets/images/pin.png' alt='pin' />
											<span
												className='text-10 font-bold ml-1'
												style={{ color: "#FF261A" }}>
												{el?.distance} km
											</span>
										</p>
									</div>
								</IonCol>
							);
						})
					) : (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</IonRow>
			</IonGrid>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.supplierList.sellerChangeWarning}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.supplierList.no}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={() => {
								dispatch(deliveryDate(""));
								dispatch(creditFee(0));
								dispatch(paymentTerm(0));
								dispatch(paymentMethod(""));
								dispatch(setPaymentMethodText(""));
								dispatch(setPaymentName(""));
								localStorage.removeItem("cart");
								setProductSeller(null);
								dispatch(setSeller(sellerSelect));
								navigate("/create-order");
							}}>
							{ekkLanguage.supplierList.yes}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
