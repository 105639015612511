import { IonCheckbox } from "@ionic/react";
import { digitChanger } from "helpers/helper";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";

export default function SingleRecommendProduct({
	id,
	img,
	name,
	supplierName,
	title,
	qty,
	tag,
	offer,
	offerAmount,
	nearby,
	price,
	oldPrice,
	newPrice,
	sellPrice,
	profit,
	nameShow,
	skuShow,
	priceShow,
	oldPriceShow,
	distShow,
	profitShow,
	sellPriceShow,
	supplierNameShow,
	newPriceShow,
	titleShow,
	select,
	link,
	type,
	defaultImage,
	currency,
	offerPriceShow,
	offerPrice,
	productType,
}) {
	let navigate = useNavigate();
	const [checked, setChecked] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let defaultImageMokeUp = () => {
		if (defaultImage) {
			return defaultImage;
		} else {
			if (type === "variant") {
				return "/assets/images/varient_default.png";
			} else if (type === "pack") {
				return "/assets/images/pack_default.png";
			} else if (type === "offer") {
				return "/assets/images/offer_default.png";
			}
		}
	};
	let isImageLength = () => {
		if (img?.length) {
			if (img[0] != "") {
				return `${img[0]}`;
			} else {
				if (type === "variant") {
					return "/assets/images/varient_default.png";
				} else if (type === "pack") {
					return "/assets/images/pack_default.png";
				} else if (type === "offer") {
					return "/assets/images/offer_default.png";
				}
			}
		}
	};

	return (
		<div className='rounded-md'>
			<div className='rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-[5px] relative'>
				{select && (
					<span className='absolute left-0 top-0'>
						<IonCheckbox
							color='success'
							className='rounede'
							checked={checked}
							onIonChange={(e) => setChecked(e.detail.checked)}
						/>
					</span>
				)}

				{img?.length ? (
					<img
						onClick={() =>
							navigate(`/${link}/${id}?&type=${productType}`)
						}
						src={isImageLength()}
						alt='productImage'
						className='w-full image-sizer'
					/>
				) : (
					<img
						onClick={() =>
							navigate(`/${link}/${id}?&type=${productType}`)
						}
						src={defaultImageMokeUp()}
						alt='productImage'
						className='w-full image-sizer'
					/>
				)}
			</div>

			<div>
				{nameShow && (
					<h5 className='font-semibold text-12'>{digitChanger(name)}</h5>
				)}
				{supplierNameShow && (
					<h5 className='font-semibold text-12'>{supplierName}</h5>
				)}
				{titleShow && (
					<h5 className='font-semibold text-12'>{digitChanger(title)}</h5>
				)}

				{/* {priceShow && (
					<span className='font-bold text-12 text-primary'>৳ {price}</span>
				)} */}
				{skuShow && (
					<span className='font-normal text-10'>
						{digitChanger(qty)} {ekkLanguage.home.skusLabel}
					</span>
				)}

				<div className='flex justify-between'>
					{offerPriceShow && (
						<span className='font-bold text-10 text-primary colors-222222 self-start'>
							{currency && currency?.currency_symbol_native}
							{digitChanger(Number(offerPrice).toFixed(2))}
						</span>
					)}
					{priceShow && (
						<span className='font-bold text-10 text-primary colors-222222 self-start'>
							{currency && currency?.currency_symbol_native}{" "}
							{digitChanger(Number(price).toFixed(2))}
						</span>
					)}
					{oldPriceShow && (
						<span className='font-small text-10 colors-808080 line-through'>
							{currency && currency?.currency_symbol_native}{" "}
							{digitChanger(oldPrice)}
						</span>
					)}
					{profitShow && (
						<span className='font-medium text-10 text-success'>
							{currency && currency?.currency_symbol_native}{" "}
							{digitChanger(Number(profit).toFixed(2))}
						</span>
					)}
				</div>
				{/* <div className='flex justify-between'>
					{priceShow && (
						<span className='flex items-center'>
							<span className='font-bold text-12 text-primary '>
								{currency} {digitChanger(Number(price).toFixed(2))}
							</span>
						</span>
					)}
					{profitShow && (
						<span className=' flex items-center'>
							<span className='font-bold text-12 text-success'>
								{currency} {digitChanger(Number(profit).toFixed(2))}
							</span>
						</span>
					)}
				</div> */}
			</div>
		</div>
	);
}
