import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import { getUser, getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import Business from "services/Business";
import User from "services/User";

export default function ProfileEdit({
	businessData,
	setBusinessData,
	name = "name",
}) {
	const [files, setFiles] = useState([]);
	const [value, setValue] = useState(null);
	const user = getUser();
	let dispatch = useDispatch();
	let business = getUserBusiness();

	useEffect(() => {
		return async () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	}, [name, files]);
	let formData = new FormData();
	useEffect(() => {
		formData.append("key", files[0]);

		if (files.length !== 0) {
			let response = User.toBucketImage(formData);
			response.then((res) => {
				if (res.status === 201) {
					var reqbody = {
						images: res.data[0],
					};
					let resp = Business.updateUserBusiness(business?._id, reqbody);
					resp.then((r) => {
						if (r.status === 200) {
							setUserBusiness(r?.data);
							setBusinessData(r?.data);
						}
					});
				}
				setValue(name, res.data[0]);
			});
		}
	}, [files]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});
	console.log(businessData);
	return (
		<>
			{businessData?.images?.length ? (
				<div
					style={{ width: "100%", height: 220 }}
					className='flex justify-between items-center bg-white relative overflow-hidden'>
					<div style={{ width: "100%" }} className='text-center bg-white'>
						<img
							src={businessData?.images[0]}
							alt='addImage'
							className='m-auto mb-2'
						/>
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<div className='absolute z-10 top-1 right-1'>
								<EditBusinessProfile />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div
					style={{ width: "100%", height: 220 }}
					className='flex justify-between items-center bg-white'>
					<div style={{ width: "100%" }} className='text-center bg-white'>
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<img
								src='assets/images/add-image.png'
								alt='addImage'
								className='m-auto mb-2'
							/>
							<p className='text-16 font-normal text-black-500'>
								Upload Image
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
