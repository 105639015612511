import {
	cartTotalAmount,
	setQuantity,
	shoppingListItem,
} from "helpers/shoppingCart";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditFee } from "redux/container/paymentMethodSlice";
import { subtotalAmount, totalAmount } from "redux/container/shoppingCartSlice";

export default function ProductQuantityInput({ value, product }) {
	const [qty, setQty] = useState(product.quantity);
	const [productList, setProductList] = useState(shoppingListItem());
	const paymentTermsDay = useSelector((state) => state.payment.paymentTerm);

	const dispatch = useDispatch();
	let creditPercent;
	if (paymentTermsDay == 0) {
		creditPercent = 0;
	} else if (paymentTermsDay == 1) {
		creditPercent = 0.001;
	} else if (paymentTermsDay == 3) {
		creditPercent = 0.005;
	} else if (paymentTermsDay == 7) {
		creditPercent = 0.01;
	}
	let editProductQuantity = (item, quantity) => {
		setQuantity(item, quantity);
		setProductList(shoppingListItem());
		dispatch(
			totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
		);
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(creditFee(cartTotalAmount() * creditPercent));
	};
	useEffect(() => {
		let qtyNumber = qty ? qty : product.minimum_order_quantity;
		let quantity = qty;
		if (quantity) {
			quantity = qty;
			if (quantity > product.minimum_order_quantity) {
				quantity = qty;
			} else {
				quantity = product.minimum_order_quantity;
			}
		} else {
			quantity = product.minimum_order_quantity;
		}
		console.log(quantity);
		editProductQuantity(product, qtyNumber);
	}, [qty]);

	useEffect(() => {
		setQty(product.quantity);
	}, [product.quantity]);

	return (
		<input
			className={`appearance-none w-full rounded-md h-9 leading-6 text-center ${
				qty >= product.minimum_order_quantity
					? "bg-F3F3F3"
					: "bg-F94B41-200"
			}  `}
			type='number'
			value={qty}
			// onBlur={() =>
			// 	qty < product.minimum_order_quantity &&
			// 	setQty(product.minimum_order_quantity)
			// }
			onChange={(e) => {
				if (e.target.value > -1) {
					setQty(e.target.value);
				} else {
					setQty(product.minimum_order_quantity);
				}
			}}></input>
	);
}
