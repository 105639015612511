import {
	IonCol,
	IonGrid,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import { memo } from "react";
import { useSelector } from "react-redux";

function ProductFilter({
	productBrands = [],
	getProductsFromBrands,
	fetchProduct,
	setSearchQ,
	setSelectedBrand,
	getAllProduct,
	setHasMoreData,
	setReachedEnd
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<IonSegment scrollable className='orderBtnWrapper' value=''>
						<IonSegmentButton
							className='relative max-w-[200px]'
							onClick={() => {
								setSelectedBrand("");
								setReachedEnd(false);
								setHasMoreData(true);
								getAllProduct();
							}}>
							<button className=' font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.createOrder.all}
							</button>
						</IonSegmentButton>
						{productBrands.map((el) => {
							return (
								<IonSegmentButton
									value={el.id}
									className='relative'
									onClick={() => {
										getProductsFromBrands(el.id);
									}}>
									<button className=' font-sans p-0 font-normal shadow-none text-14'>
										{el?.brand_name}
									</button>
								</IonSegmentButton>
							);
						})}
					</IonSegment>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}

export default memo(ProductFilter);
