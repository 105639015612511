import { IonCol } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import Minus from "assets/images/icon/svg/Minus";
import Plus from "assets/images/icon/svg/Plus";
import { digitChanger } from "helpers/helper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	cartMinus,
	cartPlus,
	cartTotalAmount,
	removeCartProduct,
	setQuantity,
	shoppingCart,
	shoppingListItem,
} from "helpers/shoppingCart";
import {
	getCartList,
	subtotalAmount,
	totalAmount,
} from "redux/container/shoppingCartSlice";
import {
	creditFee,
	paymentMethod,
	paymentTerm,
	setPaymentMethodText,
	setPaymentName,
} from "redux/container/paymentMethodSlice";

export default function SingleProductForCard({
	image,
	item,
	minusCartItem,
	plusCartItem,
	setProductList,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [qty, setQty] = useState(item.quantity);
	const dispatch = useDispatch();
	const paymentTermsDay = useSelector((state) => state.payment.paymentTerm);

	let creditPercent;
	if (paymentTermsDay == 0) {
		creditPercent = 0;
	} else if (paymentTermsDay == 1) {
		creditPercent = 0.001;
	} else if (paymentTermsDay == 3) {
		creditPercent = 0.005;
	} else if (paymentTermsDay == 7) {
		creditPercent = 0.01;
	}
	let editProductQuantity = (item, quantity) => {
		setQuantity(item, quantity);
		setProductList(shoppingListItem());
		dispatch(
			totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
		);
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(creditFee(cartTotalAmount() * creditPercent));
	};
	useEffect(() => {
		let qtyNumber = qty ? qty : 0;
		editProductQuantity(item, qtyNumber);
	}, [qty]);

	return (
		<IonCol size='12' className='p-0' key={item.id}>
			<div className='border rounded-[10px] p-2 mt-5 border-black-100 overflow-hidden relative'>
				<span
					className=' absolute right-2 top-2 font-semibold cursor-pointer'
					onClick={() => {
						removeCartProduct(item);
						setProductList(shoppingListItem());
						dispatch(totalAmount(cartTotalAmount()));
						dispatch(subtotalAmount(cartTotalAmount()));
						dispatch(creditFee(cartTotalAmount() * creditPercent));
						dispatch(creditFee(0));
						dispatch(paymentTerm(0));
						dispatch(paymentMethod(""));
						dispatch(setPaymentMethodText(""));
						dispatch(setPaymentName(""));
					}}>
					<Cross />
				</span>
				<div className='flex pb-4'>
					<div
						style={{ width: 100, height: 100 }}
						className=' bg-F3F3F3 rounded-[10px] text-center flex items-center justify-center overflow-hidden'>
						<img
							src={image}
							alt='porductImage'
							style={{ objectFit: "contain" }}
						/>
					</div>
					<div className='ml-3 flex-1 pr-4'>
						<h2 className='text-14 font-semibold mb-3 text-black'>
							{item.title}
						</h2>
						<h3 className='text-14 font-bold mb-2'>
							{" "}
							{item?.country?.currency_symbol_native}{" "}
							{digitChanger(item.base_price)}{" "}
							<span className='text-black-1000 text-12 font-normal'>
								{" "}
								* {digitChanger(item.quantity)} ={" "}
								<span className='text-primary text-14 font-bold'>
									{item?.country?.currency_symbol_native}{" "}
									{digitChanger(
										Number(item.base_price * item.quantity).toFixed(2)
									)}
								</span>
							</span>
						</h3>

						<div className='flex justify-between'>
							<div className='flex items-center'>
								<img src='assets/images/warehouse.png' alt='icon' />
								<spa className='text-10 font-bold text-primary ml-[10px] '>
									{digitChanger(Number(item?.inventory_quantity))}
								</spa>
							</div>
							<div>
								<span
									className={`text-10 font-medium ${
										qty >= item.minimum_order_quantity
											? "text-000080"
											: "text-primary"
									} `}>
									{ekkLanguage.createOrder.moqLabel}:{" "}
									{digitChanger(item.minimum_order_quantity)}
									{ekkLanguage.createOrder.pcsLabel}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='flex'>
					<div>
						<span
							className='bg-primary w-8 h-9 flex justify-center items-center rounded-md leading-6'
							style={{
								boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
							}}
							onClick={() => {
								minusCartItem(item);
								setQty(item.quantity);
							}}>
							<Minus />
						</span>
					</div>

					<div className='w-full px-3'>
						<input
							className={`appearance-none w-full rounded-md h-9 leading-6 text-center ${
								qty >= item.minimum_order_quantity
									? "bg-F3F3F3"
									: "bg-F94B41-200"
							}  `}
							type='number'
							value={qty}
							onChange={(e) => {
								if (e.target.value > -1) {
									setQty(e.target.value);
								} else {
									setQty(0);
								}
							}}></input>
						{!(qty >= item.minimum_order_quantity) && (
							<div className='text-right text-10 font-bold text-primary pt-2'>
								{ekkLanguage.createOrder.lessThenMoq}
							</div>
						)}

						{/* <ProductQuantityInput value={item.quantity} product={item} /> */}
					</div>
					<div>
						<span
							className='bg-success w-8 h-9 flex justify-center items-center rounded-md leading-6'
							style={{
								boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
							}}
							onClick={() => {
								plusCartItem(item);
								setQty(item.quantity);
							}}>
							<Plus />
						</span>
					</div>
				</div>
			</div>
		</IonCol>
	);
}
