import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButton from "components/mobile-components/Common/BackButton";
import Banner from "components/mobile-components/ProductDetails/Banner";
import SuppliersDetails from "components/mobile-components/SuppliersDetails/SuppliersDetails";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Product from "services/Product";
import Supplier from "services/Supplier";

export default function SuppliersDetailsPage() {
	let { id } = useParams();
	const [product, setProduct] = useState([]);
	const [supplierInfo, setSupplierInfo] = useState([]);
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("");
	const [productCount, setProductCount] = useState("");
	const [productBrands, setProductBrands] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedBrand, setSelectedBrand] = useState("");
	const [sellerProductLoader, setSellerProductLoader] = useState(false);

	//get brand data
	let supplierData = async () => {
		let response = await Supplier.singleSupplierDetails(id);
		if (response.status === 200) {
			setSupplierInfo(response.data);
		} else {
		}
	};
	useEffect(() => {
		supplierData();
	}, []);

	//get suppliers data
	let productData = async () => {
		setSellerProductLoader(true);
		let response = await Supplier.supplierProducts(id);
		if (response.status === 200) {
			setProduct(response.data);
			setProductCount(response?.meta);
			setSellerProductLoader(false);
		} else {
			setSellerProductLoader(false);
		}
	};
	useEffect(() => {
		productData();
	}, []);

	const contentRef = useRef();
	let settinges = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: false,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};
	let slideData = supplierInfo?.images?.length
		? [...supplierInfo.images]
		: ["/assets/images/supplier_big_default.png"];

	async function getProductBrands() {
		try {
			const response = await Product.productBrands(id);
			if (response.status === 200) {
				setProductBrands(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	}
	async function getProductsFromBrands(Bid) {
		setSellerProductLoader(true);
		setSelectedBrand(Bid);
		try {
			const response = await Product.singleBrandProducts(Bid);
			if (response.status === 200) {
				setProduct(response?.data);
				setSellerProductLoader(false);
			} else {
				setSellerProductLoader(false);
			}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		getProductBrands();
	}, []);

	async function singleProductSearch() {
		try {
			let response;
			if (selectedBrand) {
				response = await Product.singleProductSearch(
					selectedBrand,
					id,
					searchQ
				);
			} else {
				response = await Product.sellerProductSearch(id, searchQ);
			}

			if (response.status === 200) {
				setProduct(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// const delayedQuery = useCallback(debounce(singleProductSearch, 600), [
	// 	searchQ,
	// ]);

	useEffect(() => {
		if (searchQ === "") {
			productData();
		}
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("");
						}
					}}>
					<BackButton sticky={initStickyClass} />
					<Banner slideData={slideData} />
					<SuppliersDetails
						supplierInfo={supplierInfo}
						product={product}
						settinges={settinges}
						productCount={productCount}
						productBrands={productBrands}
						getProductBrands={productData}
						getProductsFromBrands={getProductsFromBrands}
						setSearchQ={setSearchQ}
						searchQ={searchQ}
						singleProductSearch={singleProductSearch}
						setSelectedBrand={setSelectedBrand}
						sellerProductLoader={sellerProductLoader}
					/>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
