const malaysia = {
	login: {
		contryLabel: "NEGARA",
		contryPlaceHolder: "",
		phoneLable: "NOMBOR TELEFON",
		phonePlaceHolder: "NOMBOR TELEFON",
		validationLabel: "Nombor telefon tidak sah, Sila tulis nombor telefon dalam Bahasa Inggeris",
		btnLabel: "Teruskan",
		tokenSuccessLabel: "Token anda telah dihantar ke telefon anda",
		tokenSuccessFailed: "Token anda belum dibuat lagi, sila cuba lagi",
		langChange: "Tukar bahasa ke",
		notFound: "Hubungi pentadbir perniagaan anda untuk membuat akaun untuk anda."
	},
	password: {
		passwordLabel: "KATA LALUAN",
		passwordPlaceHolder: "Masukkan kata laluan anda",
		forgetPass: "Lupa Kata Laluan",
		btnLabel: "Log Masuk",
		otpSendLabel: "Kod telah dihantar ke telefon bimbit anda",
		backWord: "Kembali",
		passwordNotMatch: "Kata laluan tidak sepadan"
	},
	changePass: {
		passwordLabel: "KATA LALUAN BARU",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Kata laluan tidak sepadan",
		btnLabel: "Teruskan",
		successLabel: "Kata laluan anda telah berjaya ditukar",
		errorLabel: "Pertukaran kata laluan anda gagal",
		lengthErrorLabel: "Kata laluan mestilah sekurang-kurangnya 4 aksara panjang",
		notMatchLabel: "Kata laluan anda dan sahkan kata laluan tidak sepadan."
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Masukkan token anda",
		tokenResend: "Hantar semula token",
		tokenSuccessLabel: "Kod telah dihantar ke telefon bimbit anda, taip untuk meneruskan",
		tokenErrorLabel: "Penghantaran token gagal",
		timerLabel: "Sila tunggu",
		second: "saat",
		btnLabel: "Teruskan",
		tokenNotMatch: "Token tidak sepadan",
		tokenVarified: "Token anda telah disahkan",
		tokenNotWrite: "Sila masukkan token anda"
	},
	registration: {
		nameLabel: "NAMA PENUH",
		namePlaceholder: "Masukkan nama penuh anda",
		emailLabel: "EMAIL",
		emailPlaceholder: "Masukkan email anda",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan sahkan kata laluan anda",
		validationLabel: "Medan input mesti sekurang-kurangnya 4 aksara panjang",
		passNotMatch: "Kata laluan anda dan sahkan kata laluan tidak sepadan.",
		btnLabel: "Teruskan",
		successMessage: "Profil Berjaya Dicipta",
		errorMessage: "Sila semak input yang sah",
		passLengthValidLabel: "Kata laluan mestilah sekurang-kurangnya 4 aksara panjang"
	},
	letNav: {
		homeMenuLabel: "Rumah",
		orderMenuLabel: "Pesanan Saya",
		mySupplierMenuLabel: "Pembekal Saya",
		supplierMenuLabel: "Pembekal",
		businessProfileMenuLabel: "Tetapan Profil Perniagaan",
		phoneCall: "Panggilan Untuk Memesan",
		myProducts: "Produk Saya",
		myCustomer: "Pelanggan Saya"
	},
	home: {
		showMoreLabel: "Tunjukkan Lagi",
		brandLabel: "Jenama",
		offerLabel: "Tawaran Baru",
		mySuppliersLabel: "Pembekal Saya",
		nearSuppliersLabel: "Pembekal Berdekatan",
		recommendentLabel: "Hanya Untuk Anda",
		searchLabel: "Cari produk",
		todayPurchaseLabel: "Pembelian Hari Ini",
		totalOrderLabel: "Produk dari",
		totalOrder: "Pesanan",
		skusLabel: "SKU",
		tillNow: "Sehingga Kini",
		orderDue: "Pesanan Tertunggak",
		brandProductPageTitle: "Produk Jenama",
		offerProductPageTitle: "Produk Tawaran",
		offerProductNotFound: "Maaf, Tiada tawaran ditemui untuk anda.",
		mySupplierNotFound: "Anda tidak mempunyai pembekal. Sila beli produk untuk menambah pembekal anda di sini.",
		brandSeller: "Pembekal"
	},
	profile: {
		pageTitle: "Profil & Tetapan",
		contactMenuLable: "Maklumat Pengguna",
		passwordMenuLable: "Tukar Kata Laluan",
		logoutMenuLable: "Log Keluar",
		changeLanguageMenuLable: "Tukar Bahasa"
	},
	changeLanguages: {
		pageTitle: "Pilih Bahasa",
		btnLabel: "Simpan"
	},
	contact: {
		pageTitle: "Maklumat Pengguna",
		nameLabel: "Nama",
		namePlaceholder: "Masukkan nama anda",
		emailLabel: "Email",
		emailPlaceholder: "Masukkan email anda",
		nidLabel: "NID",
		nidPlaceholder: "Masukkan nombor NID",
		btnLabel: "Simpan",
		successLabel: "Profil anda berjaya dikemas kini.",
		currectEmail: "sila masukkan alamat email semasa"
	},
	otpsend: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		message: "Ketuk untuk menghantar kod pengesahan ke telefon bimbit anda melalui SMS.",
		successLabel: "Token anda telah dihantar ke telefon anda",
		errorLabel: "Token anda belum dibuat lagi, sila cuba lagi"
	},
	otpsubmit: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		title: "Kod Pengesahan",
		paragraph: "Kami telah menghantar kod pengesahan ke Nombor Telefon Bimbit Anda",
		otpLabel: "OTP",
		otpPlaceholder: "Masukkan kod OTP anda",
		receiveYetLabel: "Tidak terima kod?",
		againRequestLabel: "Minta semula",
		timerLabel: "Sila tunggu",
		second: "saat",
		successLabel: "Token anda telah dihantar ke telefon anda",
		errorLabel: "Token anda belum dibuat lagi, sila cuba lagi",
		tokenVarifiedMessage: "Token anda telah disahkan",
		validationLabel: "Sila hantar OTP anda"
	},
	changePassword: {
		pageTitle: "Tukar Kata Laluan",
		btnLabel: "Hantar",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan sahkan kata laluan anda",
		successLabel: "Kata laluan anda berjaya dikemas kini.",
		errorLabel: "Kemas kini kata laluan anda gagal",
		passNotMatchLabel: "Kata laluan anda dan sahkan kata laluan tidak sepadan.",
		changePassSuccessPara: "Kata laluan anda telah berjaya ditukar.",
		changePassContinueBtn: "Teruskan"
	},
	brandDetails: {
		skusLabel: "SKU",
		descriptionLabel: "Penerangan",
		productTitleLabel: "Produk Jenama Ini"
	},
	mysuppliers: {
		skusLabel: "SKU",
		productTitleLabel: "Produk Pembekal Ini"
	},
	productDetails: {
		offerStart: "Tarikh Mula Tawaran",
		offerEnd: "Tarikh Tamat Tawaran",
		moqLabel: "MOQ",
		consumerLabel: "Harga Pengguna (MRP)",
		profitLabel: "Keuntungan",
		productDetailTitle: "Butiran Produk",
		expireData: "Tarikh Luput",
		manufactureData: "Tarikh Pengeluaran",
		productWeight: "Berat Produk",
		packingType: "Jenis Pembungkusan",
		importedBy: "Diimport Oleh",
		manufacturedBy: "Dikilangkan Oleh",
		country: "Negara",
		descriptionLabel: "Penerangan",
		btnLabel: "Pesan Sekarang",
		inStock: "Dalam stok",
		outStock: "Kehabisan stok",
		skuId: "ID SKU"
	},
	createOrder: {
		pageTitle: "Buat Pesanan",
		buyerDetailsTitle: "Butiran Pembeli",
		sellerDetailsTitle: "Butiran Penjual",
		deliveryDetailsTitle: "Penghantaran",
		productItemTitle: "Item Produk",
		costSummaryTitle: "Ringkasan Kos",
		paymentDetailsTitle: "Butiran Pembayaran",
		paymentTermsTitle: "Terma Pembayaran",
		paymentMethodeTitle: "Kaedah Pembayaran",
		moqLabel: "Kuantiti Pesanan Minimum",
		pcsLabel: "Kepingan",
		subtotal: "Jumlah Kecil",
		tax: "Cukai / VAT",
		discount: "Diskaun",
		deliveryFee: "Yuran Penghantaran",
		creditFee: "Yuran Perkhidmatan",
		total: "Jumlah",
		immediatePayment: "Bayaran Segera",
		pickAndPay: "Ambil dan Bayar",
		today: "Pada Malam Hari Penghantaran",
		threeDays: "Bayar dalam 3 hari",
		sevenDays: "Bayar dalam 7 hari",
		immediatePaymentText: "Tiada Yuran Perkhidmatan dikenakan untuk pesanan ini.",
		todayPaymentText1: "Untuk bayaran hari ini, Yuran Perkhidmatan tambahan sebanyak ",
		todayPaymentText2: "akan ditambah kepada pesanan ini.",
		threePaymentText1: "Untuk bayaran 3 hari, Yuran Perkhidmatan tambahan sebanyak ",
		threePaymentText2: "akan ditambah kepada pesanan ini.",
		sevenPaymentText1: "Untuk bayaran 7 hari, Yuran Perkhidmatan tambahan sebanyak ",
		sevenPaymentText2: " akan ditambah kepada pesanan ini.",
		cashOnDelivery: "Tunai Semasa Penghantaran",
		cashOnDeliveryText1: "Bayar Tunai kepada",
		cashOnDeliveryText2: "apabila barangan dihantar.",
		cash: "Tunai",
		bkash: "Bkash",
		nagad: "Nagad",
		cashText: "Bayar Tunai kepada",
		bkashText: "Bayar kepada Bkash",
		nagadText: "Bayar kepada Nagad",
		bankTransfer: "Pindahan Bank",
		bankTransferText1: "Pindahan Bank - Bayar kepada",
		bankTransferText2: "akaun bank dengan 'Nombor Pesanan' sebagai rujukan",
		bankTransferText3: "Nama Bank",
		bankTransferText4: "Nama A/C",
		bankTransferText5: "Nombor A/C",
		bankTransferText6: "Nombor Laluan",
		bankTransferText7: "Cawangan",
		btnLabel: "Sahkan",
		addMoreProductLabel: "Tambah Produk Lagi",
		addMoreProduct: "Tambah Produk",
		selectProductLabel: "Hantar",
		cosgressTitle: "Tahniah !",
		cosgressPara: "Pesanan anda telah selesai.",
		congressCancelText: "Jika ada masalah dengan pesanan, batalkan sebelum jam 12 tengah hari. Anda tidak boleh membatalkan pesanan selepas jam 12 tengah hari.",
		congressProductLabel: "Teruskan",
		orderNow: "Pesan Sekarang",
		alert: "Amaran",
		importentMessage: "Mesej penting",
		deliveryDateMessage: "Sila Pilih Tarikh Penghantaran",
		paymentTermsMessage: "Sila Pilih Terma Pembayaran",
		paymentMethodMessage: "Sila Pilih Kaedah Pembayaran",
		backButtonMessage: "Troli anda mempunyai beberapa item, jika anda meninggalkan halaman ini troli anda akan kosong.",
		okBtnLabel: "OK",
		loader: "Sila tunggu...",
		placeConformationMessage: "Adakah anda pasti mahu membuat pesanan ini?",
		lessThenMoq: "Kuantiti Item yang dipilih adalah kurang daripada minimum atau lebih daripada kuantiti pesanan maksimum.",
		all: "Semua",
		number: "Nombor",
		numberWarning: "Jika wang dihantar ke nombor selain nombor ini, ia akan dianggap sebagai dibatalkan.",
		islamiBank: "Islami Bank",
		bankAsia: "Bank Asia",
		payTo: "Bayar Kepada",
		merchantNumber: "Nombor Saudagar",
		outOffStock: "Produk kehabisan stok",
		wrongProductCount: "Nombor pemilihan produk salah."
	},
	selectProduct: {
		pageTitle: "Pilih Produk",
		totalLabel: "Jumlah",
		btnLabel: "Hantar"
	},
	orderList: {
		pageTitle: "Senarai Pesanan Saya",
		tabMenuRecent: "Terkini",
		tabMenuPlaced: "Ditempatkan",
		tabMenuAccepted: "Diterima",
		tabMenuScDelivered: "Dijadualkan Untuk Penghantaran",
		tabMenuPartialDelivered: "Sebahagian Dihantar",
		tabMenuDelivered: "Dihantar",
		tabMenuReturn: "Kembalikan Produk",
		tabMenuPartialPaid: "Sebahagian Dibayar",
		tabMenuPaid: "Dibayar",
		tabMenuCancel: "Batal",
		tabMenuDeliveryFailed: "Penghantaran Gagal",
		orderItem: "Item Pesanan",
		seller: "Penjual",
		buyer: "Pembeli",
		loader: "Sila tunggu...",
		createdBy: "Dicipta Oleh",
		to: "Kepada",
		for: "Untuk",
		notFound: "Tidak Ditemui",
		assistantOrder: "Buat pesanan pembantu"
	},
	orderDetails: {
		createdBy: "Dicipta oleh",
		placedLabel: "Ditempatkan",
		deliveredLabel: "Dihantar",
		paidLabel: "Dibayar",
		orderItemLabel: "Item Pesanan",
		castItemLabel: "Butiran Kos",
		timelineLabel: "Garis Masa",
		subtotal: "Jumlah Kecil",
		tax: "Cukai / VAT",
		discount: "Diskaun",
		deliveryFee: "Yuran Penghantaran",
		creditFee: "Yuran Perkhidmatan",
		total: "Jumlah",
		paymentSettlementLabel: "Penyelesaian Pembayaran",
		date: "Tarikh",
		amount: "Jumlah",
		dueDate: "Tarikh Akhir",
		remark: "Catatan",
		collectedBy: "Dikumpul oleh",
		paymentDetailsLabel: "Butiran Pembayaran",
		paymentMethod: "Kaedah Pembayaran",
		paymentTerms: "Terma Pembayaran",
		paymentDueDate: "Tarikh Akhir Pembayaran",
		totalDue: "Jumlah Tertunggak",
		totalPaid: "Jumlah Dibayar",
		remaining: "Baki",
		deliveryDetailsLabel: "Butiran Penghantaran",
		BuyerName: "Nama Pembeli",
		address: "Alamat",
		phoneNumber: "Nombor Telefon",
		email: "Email",
		deliveryDate: "Tarikh Penghantaran",
		downloadInvoices: "Muat Turun Invois",
		receiptDownloads: "Cetak Resit",
		cancelOrder: "Batalkan Pesanan",
		cancelConformationAsk: "Adakah anda pasti mahu membatalkan Pesanan ini?",
		cancelConformationParagraph: "Anda tidak boleh membatalkan pesanan ini, anda hanya boleh membatalkan pesanan anda pada hari yang sama.",
		yesBtn: "Ya",
		noBtn: "Tidak",
		withoutSettelment: "Maklumat pembayaran yang selesai akan ditunjukkan di sini.",
		bankInfoLabel: "Maklumat Akaun Bank untuk Pemindahan Pembayaran",
		requestDeliveryDate: "Minta Tarikh Penghantaran",
		scheduleDeliveryDate: "Jadualkan Tarikh Penghantaran",
		scheduleDeliveryTime: "Jadualkan Masa Penghantaran",
		deliveryFailMessage: "Mesej Gagal Penghantaran",
		assignedDriver: "Pemandu Ditugaskan"
	},
	businessCreateName: {
		pageTitle: "Nama Perniagaan",
		businessName: "Nama Perniagaan",
		businessNamePlaceholder: "Masukkan Nama Perniagaan",
		paragraph: "Kami berada 3 langkah lagi untuk mencipta perniagaan anda. Mari mulakan dengan nama perniagaan anda.",
		btnLabel: "Seterusnya",
		submit: "Hantar",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Masukkan nama perniagaan!"
	},
	businessType: {
		pageTitle: "Jenis Perniagaan",
		paragraph: "Mari pilih jenis perniagaan anda.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Super Mart",
		pharmacy: "Farmasi",
		cart: "Troli",
		bazaarStore: "Kedai Bazaar",
		hawkerCenter: "Pusat Penjual Makanan",
		restaurant: "Restoran",
		hotel: "Hotel",
		cosmeticsStore: "Kedai Kosmetik",
		nonFmcg: "Bukan FMCG",
		office: "Pejabat",
		NGO: "NGO",
		entertainmentPlace: "Tempat Hiburan",
		religiousOrganization: "Organisasi Keagamaan",
		spa: "Spa",
		salon: "Salun",
		gym: "Gym",
		electronicsStore: "Kedai Elektronik",
		shoeStore: "Kedai Kasut",
		fashionStore: "Kedai Fesyen",
		laundryStore: "Kedai Dobi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Seterusnya",
		validationLabel: "Sila Pilih jenis perniagaan"
	},
	businessLocation: {
		pageTitle: "Lokasi Perniagaan",
		paragraph: "Masukkan butiran hubungan perniagaan anda.",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat anda",
		city: "Bandar",
		cityPlaceholder: "Masukkan nama bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan poskod",
		email: "Emel",
		emailPlaceholder: "Masukkan emel anda",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "SELESAI",
		successLabel: "Tahniah perniagaan berjaya dicipta",
		errorLabel: "Gagal mencipta perniagaan",
		validationLabel: "Sila isi alamat untuk teruskan.",
		loader: "Sila tunggu...",
		validationCityLabel: "Sila isi bandar.",
		validationPostCodeLabel: "Sila isi poskod.",
		locationValidation: "Sila pilih lokasi dari peta."
	},
	locationPicker: {
		pageTitle: "Cari Lokasi Kedai",
		btnLabel: "SAHKAN",
		paragraph: "Sila, sahkan alamat kedai anda. Jika anda menghadapi masalah mencari alamat kedai anda, sila cari alamat kedai anda dengan mencari."
	},
	location: {
		title: "Aktifkan Lokasi Anda",
		paragraph: "Aplikasi ini memerlukan perkhidmatan lokasi yang dihidupkan pada peranti anda dan untuk aplikasi ini. Anda mesti mengaktifkannya dalam Tetapan sebelum menggunakan aplikasi ini.",
		btnLabel: "Benarkan hanya semasa menggunakan aplikasi"
	},
	businessSelect: {
		pageTitle: "Pilih Perniagaan"
	},
	pwaDownload: {
		header: "Muat Turun Aplikasi EkkBaz",
		paragraph: "Muat turunkan aplikasi dan pesan borong dari pembekal anda pada bila-bila masa dari sekarang.",
		cancelBtn: "Batal",
		downloadBtn: "Muat Turun"
	},
	businessProfile: {
		pageTitle: "Tetapan Profil Perniagaan",
		businessDetailsLabel: "Butiran",
		registrationNo: "Nombor Pendaftaran",
		taxNo: "Nombor Cukai/VAT",
		mobileNo: "Nombor Telefon Bimbit",
		officeNo: "Nombor Telefon Pejabat",
		email: "Emel",
		website: "Laman Web",
		branchLabel: "Senarai Cawangan",
		paymentDetailsLabel: "Butiran Pembayaran",
		bankInfoLabel: "Maklumat Bank",
		bankName: "Nama Bank",
		branchName: "Cawangan Bank",
		branchAddress: "Alamat Bank",
		swiftCode: "Kod SWIFT Bank",
		acName: "Nama Akaun Bank",
		acNumber: "Nombor Akaun Bank",
		bkashInfoLabel: "Maklumat Bkash",
		bkashAcName: "Nama Akaun Bkash",
		bkashAcNo: "Nombor Akaun Bkash",
		nagadInfoLabel: "Maklumat Nagad",
		nagadAcName: "Nama Akaun Nagad",
		nagadAcNo: "Nombor Akaun Nagad",
		upayInfoLabel: "Maklumat Upay",
		upayAcName: "Nama Akaun Upay",
		upayAcNo: "Nombor Akaun Upay",
		verified: "Disahkan",
		unVerified: "Belum Disahkan",
		editBusinessMenu: "Sunting Perniagaan",
		addPayMenu: "Tambah Pilihan Pembayaran",
		addMenu: "Tambah Cawangan",
		verifyBusinessMenu: "Sahkan Perniagaan",
		bankInfoNotFound: "Maklumat bank tidak dijumpai."
	},
    businessEdit: {
		pageTitle: "Edit Perniagaan",
		uploadPhoto: "Muat Naik Foto",
		email: "Emel",
		emailPlaceholder: "Masukkan emel anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat anda",
		city: "Bandar",
		cityPlaceholder: "Masukkan nama bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan poskod",
		resigter: "Nombor Pendaftaran",
		resigterPlaceholder: "Masukkan nombor pendaftaran anda",
		tex: "Nombor Cukai/Pokok",
		texPlaceholder: "Masukkan nombor cukai/pokok anda",
		mobile: "Nombor Telefon Bimbit",
		mobilePlaceholder: "Masukkan nombor telefon bimbit anda",
		office: "Nombor Pejabat",
		officePlaceholder: "Masukkan nombor pejabat anda",
		website: "Website",
		websitePlaceholder: "Masukkan laman web anda",
		btnLabel: "Simpan",
		successLabel: "Profil anda telah dikemaskini. Berjaya",
		currectEmail: "Sila masukkan alamat emel semasa"
	},
	bankDetails: {
		referenceNumber: "Nombor Rujukan",
		bankName: "Nama Bank",
		accountName: "Nama Akaun",
		accountNumber: "Nombor Akaun",
		routeNumber: "Nombor Laluan",
		branch: "Cawangan"
	},
	invoice: {
		title: "INVOIS",
		receipt: "Resit",
		buyerDetails: "Butiran Pembeli",
		sellerDetails: "Butiran Penjual",
		description: "PENERANGAN",
		qty: "JUMLAH",
		price: "HARGA",
		subtotal: "JUMLAH KESELURUHAN"
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬"
	},
	receipt: {
		placed: "Diletakkan",
		name: "Nama",
		qty: "Kuantiti",
		price: "Harga",
		subtotal: "Jumlah",
		tax: "Cukai",
		discount: "Diskaun",
		creditFee: "Bayaran Perkhidmatan",
		deliveryFee: "Bayaran Penghantaran",
		total: "Jumlah Keseluruhan",
		paid: "Dibayar",
		totalDue: "Jumlah Berhutang",
		paymentTerms: "Terma Pembayaran",
		days: "Hari",
		paymentMethod: "Kaedah Pembayaran",
		referenceNumber: "Nombor Rujukan Bank",
		deliveryDate: "Tarikh Penghantaran",
		dueDate: "Tarikh Bayaran Akhir",
		printTime: "Masa Cetakan",
		conditionsCreditFee: "Syarat Pembayaran & Bayaran Perkhidmatan",
		immediatePayment: "Bayaran Segera",
		cashOnDelivery: "Bayaran Tunai Semasa Penghantaran",
		atNight: "Pada Malam Hari Penghantaran",
		payInthreeDay: "Bayar dalam 3 Hari",
		payInSevenDay: "Bayar dalam 7 Hari",
		afterSeven: "Selepas 7 Hari (tambahan setiap hari tambahan)"
	},
	landing: {
		header1: "Mohon EkkHero",
		header2: "Mula Bekerja",
		header3: "Jana Pendapatan!",
		login: "Log masuk",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
        malaysia:"Malay",
		retail: "Runcit",
		manufacture: "Pembekal",
		hotline: "Talian panas",
		phoneNumber: "Nombor Telefon",
		phoneNumberPlaceHolder: "Masukkan Nombor Telefon",
		fAbout: "Pasar borong di hujung jari anda. Beli sekarang, bayar kemudian!",
		location: "Lokasi",
		singapore: "Pejabat Singapura",
		bangladesh: "Pejabat Bangladesh",
		bOfficeAddress: "Tingkat 13, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Maklumat lain",
		partners: "Rakan kami",
		news: "Berita",
		support: "Sokongan",
		corporate: "Korporat",
		refundPolicy: "Dasar Pembatalan",
		termsCondition: "Syarat dan Terma",
		privacyPolicy: "Dasar Privasi",
		contactUs: "Hubungi Kami",
		messageUs: "Hantar Pesanan",
		facebook: "Facebook",
		sales: "Jualan",
		media: "Media",
		downloadBtn: "Muat turun Aplikasi",
		backers: "Pensokong Kami",
		benefits: "Faedah Anda",
		discover: "Berita Terkini EkkBaz",
		benefitsOne: "Penghantaran tepat pada masa 24 jam",
		benefitsDisOne: "",
		benefitsTwo: "Harga pasaran terbaik",
		benefitsDisOneTwo: "",
		benefitsThree: "Assortmen SKU terbesar",
		benefitsDisOneThree: "",
		benefitsFore: "Sokongan kewangan untuk pembelian",
		benefitsDisOneFore: "",
		seeMore: "Lihat Lebih Lanjut"
	},
	customerList: {
		pageTitle: "Senarai Pelanggan",
		myCustomer: "Pelanggan Saya",
		allCustomer: "Semua Pelanggan",
		searchPlaceHolder: "Cari Pelanggan Anda"
	},
	myCustomer: {
        pageTitle: "Senarai Pelanggan Saya",
        searchLabel: "Cari Pelanggan",
        createCustomer: "Cipta Pelanggan Baru",
    },
    myProducts: {
        pageTitle: "Senarai Produk Saya",
    },
    newCustomer: {
        pageTitle: "Cipta Pelanggan Baru",
        mobileNo: "Nombor Telefon Bimbit",
        customerNoHolder: "masukkan nombor telefon pelanggan",
        customerName: "Nama Pelanggan",
        customerNameHolder: "masukkan nama penuh pelanggan",
        businessPhoto: "Foto Perniagaan",
        uploadPhoto: "Muat Naik Foto Perniagaan/Kedai",
        uploadPhotoTitle: "Muat Naik Foto",
        uploadFromGallery: "Muat Naik Dari Galeri",
        takePhoto: "Ambil Gambar",
        businessName: "Nama Perniagaan",
        businessNameHolder: "masukkan nama perniagaan/kedai",
        address: "Alamat",
        addressHolder: "masukkan alamat perniagaan/kedai",
        city: "Bandar",
        cityHolder: "masukkan bandar",
        postalCode: "Poskod",
        postalCodeHolder: "masukkan poskod",
        selectBusiness: "Pilih Jenis Perniagaan",
        fmcg: "FMCG",
        nonFmcg: "Bukan FMCG",
        nextBtn: "Seterusnya",
        completeBtn: "Selesai",
        customerFindText: `Pelanggan sudah wujud di sini, jika anda mahu anda boleh tambahkannya ke "Pelanggan Saya". Dan jika tidak mahu, anda boleh cipta pelanggan baru dengan mengklik butang hijau.`,
        addBusiness: "Tambah Perniagaan",
        addBusinessInfo: "Adakah anda ingin menambah perniagaan ini sebagai Pelanggan Saya?",
        noBtn: "Tidak",
    },
    error: {
        warning: `Anda tidak berkaitan dengan mana-mana Perniagaan. 
    Sila hubungi pentadbir perniagaan yang berkaitan untuk menambah anda sebagai pekerja kepada akaun perniagaan mereka.`,
        notFound: "Tiada Kandungan Ditemui",
        btn: "Kembali ke Halaman Utama",
        logout: "Log Keluar",
    },
    supplierSelect: {
        pageTitle: "Sistem Pesanan",
        multiple: "Cipta pesanan untuk pelbagai pembekal",
        specific: "Cipta pesanan untuk pembekal tertentu",
        next: "Seterusnya",
        chooseText: "Pilih salah satu di bawah, cara anda ingin membuat pesanan",
    },
    supplierList: {
        pageTitle: "Senarai Pembekal",
        searchPlaceHolder: "Cari Pembekal",
        sellerChangeWarning: "Jika anda tukar pembekal, semua produk yang telah dipilih sebelumnya akan dikeluarkan!",
        no: "Tidak",
        yes: "Ya",
    },
    autoLogOut: {
        pageTitle: "Log Keluar Automatik",
        warningMsg: "Log keluar automatik kerana nombor ini telah digunakan dalam Log Masuk lain",
        instrMsg: "Klik butang hijau di bawah untuk log masuk semula",
        btnLabel: "Log masuk",
    },
    blackListed: {
        pageTitle: "Senarai Hitam",
        warningMsg: "Perniagaan Anda Telah Dihitamkan",
        instrMsg: "Sila minta pemilik perniagaan anda menghubungi EkkBaz untuk tindakan yang diperlukan untuk mengaktifkan semula perniagaan anda.",
        btnLabel: "Log keluar",
    }
};
export default malaysia;
